import { useFilterHook } from '../../hooks/FilterHook';
import { useEffect } from 'react';
import { useHomeGroupHook } from '../../hooks/HomeGroupHook';
import { Routes, Route, useLocation } from 'react-router-dom';
import { useValidTeamAppContext } from '../../../v2/contexts/AppContext';
import { Date_Window, Group_Status } from '../../../generated/graphql';
import GroupPage from '../GroupPage';
import HomePage from './HomePage';
import { DateOptions } from '../../sections/Filters/FilterManager';
import moment from 'moment';
interface HomePageRouterProps {
  pageName: string;
}

export const HomePageRouter = ({ pageName }: HomePageRouterProps) => {
  const pageSize = 9;
  const location = useLocation();
  const { curTeamId: teamId, currentTeam, curOrgId: orgId, currentOrg } = useValidTeamAppContext();

  useEffect(() => {
    // if an explicit start and end date are given to the team, use those
    if (currentTeam.defaultValues?.startDate || currentTeam.defaultValues?.endDate) {
      filterHook.setFilters({
        startDate: currentTeam.defaultValues?.startDate,
        endDate: currentTeam.defaultValues?.endDate,
      });
      // otherwise, check if we have a default window set
    } else if (currentTeam.defaultValues?.exploreDefaultWindow) {
      // if we do, use that window
      const dateOption = DateOptions.find((option) => option.date_window === currentTeam.defaultValues!.exploreDefaultWindow);
      if (dateOption?.date_window === Date_Window.All) {
        const newStartDate = moment(currentTeam.defaultValues.oldestFeedbackDate).startOf('day').toDate();
        const newEndDate = moment(dateOption.endDate).endOf('day').toDate();
        filterHook.setFilters({ startDate: newStartDate, endDate: newEndDate });
      } else {
        const newStartDate = moment(dateOption?.startDate).startOf('day').toDate();
        const newEndDate = moment(dateOption?.endDate).endOf('day').toDate();
        filterHook.setFilters({ startDate: newStartDate, endDate: newEndDate });
      }
    }
  }, [currentTeam.defaultValues]);

  const filterHook = useFilterHook({
    teamId,
    orgId,
  });
  const groupHook = useHomeGroupHook({
    teamId,
    orgId,
    teamName: currentTeam?.name,
    orgName: currentOrg?.name,
    pageName,
    filterInput: filterHook.filters,
    homePage: true,
    pageSize: pageSize,
    sentencesTake: 10,
    status: Group_Status.Monitored,
  });
  useEffect(() => {
    // is this comment actually true? I can't figure out how to test this. But to future devs be skeptical of this....
    // we need to unset group when we navigate away from the subroute via any method
    if (!location.pathname.includes('group')) {
      groupHook.setCurrentGroup(undefined);
    }
  }, [location.pathname]);

  return (
    <div>
      <HomePage pageName={pageName} groupHook={groupHook} filterHook={filterHook} />
      <Routes>
        <Route path="/group/:groupId" element={<GroupPage groupHook={groupHook} />} />
      </Routes>
    </div>
  );
};

export default HomePageRouter;
