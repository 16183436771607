import { ChartBarIcon } from '@heroicons/react/24/solid';
import { Chart2DFragment, ChartFragment, FilterInput, SeriesData, SeriesDataFragment } from '../../../../generated/graphql';
import ClusterSparkChart from '../../ClusterSparkChart';
import ChartView from '../../../baseComponents/ChartView';
import { CustomChart } from '../../CustomChart';

interface InsightChartProps {
  loading: boolean;
  filterInput: FilterInput | null | undefined;
  chart?: Chart2DFragment | null;
  series?: SeriesDataFragment | null;
}
const InsightChart = ({ loading, filterInput, chart, series }: InsightChartProps) => {
  if (loading) {
    return <InsightChartLoadingSkeleton />;
  }

  if (series && filterInput) {
    return (
      <ClusterSparkChart
        artificialStartDate={undefined}
        autoHeight
        aggregateData={series ? series.aggregateData[0] : chart?.series[0]}
        normalizedData={series ? series.normalizedData[0] : chart?.series[0]}
        tooltipLabels={series ? series.tooltipLabels : chart?.tooltipLabels}
        chartLabels={series ? series.chartLabels : chart?.xAxisLabels}
        filterInput={filterInput}
      />
    );
  } else if (chart && filterInput) {
    const chart2d: ChartFragment = {
      id: chart.id,
      title: chart.title,
      type: chart.config.type,
      y_axis_data: chart.config.y_axis_data,
      breakdown: chart.config.breakdown,
      seriesData: chart.config.seriesConfig?.map((series, idx, arr) => {
        return {
          aggregateData: arr.length > 1 ? [chart.series[idx]] : chart.series,
          normalizedData: arr.length > 1 ? [chart.series[idx]] : chart.series,
          tooltipLabels: chart.tooltipLabels,
          breakdown: chart.config.breakdown,
          chartLabels: chart.xAxisLabels,
          breakdownLabels: arr.length > 1 ? [chart.legend[idx]] : chart.legend,
          filterInput: series.filterInput,
          team: series.team,
          segmentGroupId: series.segmentGroupId,
        };
      }),
    };
    return <CustomChart customChartData={chart2d} showLegend={true} />;
  }
  return <InsightChartErrorSkeleton />;
};

const InsightChartErrorSkeleton = () => {
  return (
    <div
      className="custom-chart-card-skeleton space-y-5 rounded-lg bg-gray-100 relative 
        before:absolute before:inset-0
        before:-translate-x-full
        before:bg-gradient-to-r before:from-transparent before:via-blueberry  before:opacity-[0.2]
        isolate
        overflow-hidden
        before:border-t before:border-gray-100 opacity-70 h-full"
    >
      <div className="space-y-3 justify-center items-center flex py-8 h-full">
        <ChartBarIcon className="h-32 w-32 text-gray-300" />
      </div>
    </div>
  );
};
const InsightChartLoadingSkeleton = () => {
  return (
    <div
      className="custom-chart-card-skeleton space-y-5 rounded-lg bg-gray-100 relative 
        before:absolute before:inset-0
        before:-translate-x-full
        before:animate-[shimmer_2s_infinite]
        before:bg-gradient-to-r before:from-transparent before:via-blueberry  before:opacity-[0.2]
        isolate
        overflow-hidden
        before:border-t before:border-gray-100 opacity-70 h-full"
    >
      <div className="space-y-3 justify-center items-center flex py-8 h-full">
        <ChartBarIcon className="h-32 w-32 text-gray-300" />
      </div>
    </div>
  );
};

export default InsightChart;
