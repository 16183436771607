import { ChevronLeftIcon } from '@heroicons/react/24/solid';
import { useValidTeamAppContext } from '../../../v2/contexts/AppContext';
import toast from 'react-hot-toast';
import Button, { ButtonVariant } from '../../baseComponents/Button';
import { ExternalTicketData, Feedback_Integration_Type, useIntegrationsQuery } from '../../../generated/graphql';
import { NavLink } from 'react-router-dom';
import { AppRoutes } from '../../../Routes';
import { SmallSpinner } from '../SmallSpinner';
import { TicketBrowsing } from './TicketBrowsing';
import { useExternalTicketsDispatch, useExternalTicketsState } from '../../../context/externalTicketsContext';
import { ExternalTicketsActionTypes } from '../../../reducers/externalTickets/externalTicketsReducer';
import { useLinkExternalTicketHook } from '../../hooks/LinkExternalTicketHook';
import { useState } from 'react';

export const ExternalTicketsBrowsingBody = ({ groupId }: { groupId: number }) => {
  const { curOrgId: orgId, curTeamId: teamId } = useValidTeamAppContext();

  const [linkingTicket, setLinkingTicket] = useState(false);
  const linkHook = useLinkExternalTicketHook({ orgId, teamId });

  const { selectedIntegrationId } = useExternalTicketsState();
  const dispatch = useExternalTicketsDispatch();

  const { data, loading } = useIntegrationsQuery({ variables: { orgId, teamId, feedbackIntegrationType: Feedback_Integration_Type.ExternalTickets } });

  const selectedIntegration = data?.integrations?.find((integ) => integ.id === selectedIntegrationId);

  const handleLink = async ({ ticketData }: { ticketData: ExternalTicketData }) => {
    const integrationId = selectedIntegration?.orgExternalTicketsIntegration[0].id;
    const scraperKey = selectedIntegration?.scraperKey;
    if (!integrationId || !scraperKey) {
      toast.error('Internal error - please contact support');
      return;
    }

    setLinkingTicket(true);

    try {
      await linkHook.linkTicketToGroup({
        groupId,
        integrationId,
        scraperKey,
        input: {
          name: ticketData.name,
          providerUniqueId: ticketData.providerUniqueId,
          sourcePermalink: ticketData.sourcePermalink,
          status: ticketData.status,
          assignee: ticketData.assignee,
          dueDate: ticketData.dueDate,
        },
      });
      dispatch({ type: ExternalTicketsActionTypes.SET_VIEW, payload: { newView: 'list' } });
      toast.success('Ticket linked');
    } catch (err) {
      toast.error('Error linking ticket');
    }
    setLinkingTicket(false);
  };

  return (
    <div className="flex flex-col gap-y-1 text-blueberry ">
      {loading ? (
        <div className="flex flex-row items-center gap-x-2">
          <SmallSpinner />
          <p>Loading...</p>
        </div>
      ) : (
        <div className="flex flex-col gap-y-1">
          {!selectedIntegration ? (
            data?.integrations?.map((externalTicketInteg) => (
              <div
                key={externalTicketInteg.id}
                className="flex flex-row items-center gap-x-2 hover:bg-silver-darker rounded-md px-2 py-1 duration-100 cursor-pointer"
                onClick={() => dispatch({ type: ExternalTicketsActionTypes.SET_SELECTED_INTEGRATION, payload: { integrationId: externalTicketInteg.id } })}
              >
                <img src={externalTicketInteg.logoUrl} alt={externalTicketInteg.title} className="h-4 w-4" />
                <h1 className="font-semibold text-lg">{externalTicketInteg.title}</h1>
              </div>
            ))
          ) : (
            <div className="flex flex-col">
              <div className="flex flex-row items-center gap-x-2 border-b border-b-silver-darker pb-1">
                <div
                  className="flex px-2 py-2 rounded-md hover:bg-silver duration-100 cursor-pointer"
                  onClick={() => dispatch({ type: ExternalTicketsActionTypes.SET_SELECTED_INTEGRATION, payload: { integrationId: null } })}
                >
                  <ChevronLeftIcon className="h-4 w-4" />
                </div>
                <div className="flex flex-row items-center gap-x-2">
                  <img src={selectedIntegration.logoUrl} alt={selectedIntegration.title} className="h-4 w-4" />
                  <h1 className="font-semibold text-lg">{selectedIntegration.title}</h1>
                </div>
              </div>
              <div className="p-2">
                {selectedIntegration.orgExternalTicketsIntegration.length === 0 ? (
                  <NotConnectedSection title={selectedIntegration.title} orgId={orgId} />
                ) : (
                  <TicketBrowsing linkTicket={handleLink} integrationSystemKey={selectedIntegration.scraperKey} linkingTicket={linkingTicket} />
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const NotConnectedSection = ({ title, orgId }: { title: string; orgId: number }) => {
  return (
    <div className="flex flex-col gap-y-1 items-center text-center py-3">
      <h1 className="font-semibold text-lg">No active {title} connection </h1>
      <p className="text-sm">Create a connection from your Organization's page</p>
      <NavLink to={AppRoutes.v3FullPath.organizations + '/' + orgId} className="font-semibold text-blue-800">
        <Button text="Connect +" variant={ButtonVariant.Tertiary} />
      </NavLink>
    </div>
  );
};
