import { useNavigate, useParams } from 'react-router-dom';
import { FilterType } from '../../../../generated/graphql';
import { AppRoutes } from '../../../../Routes';
import Tippy from '@tippyjs/react';
import { ChatBubbleLeftIcon } from '@heroicons/react/24/outline';

export function ReplyToUsersButton() {
  const navigate = useNavigate();
  const { groupId: URLGroupId } = useParams();
  const params = new URLSearchParams(window.location.search);
  const navigateToReplyPage = () => {
    const filters = JSON.parse(decodeURIComponent(params.get('group') ?? '{}'));
    filters.groupFilter = [{ filterCondition: FilterType.And, group: [{ id: Number(URLGroupId) }] }];
    const stringifiedFilters = JSON.stringify(filters);
    params.set('group', stringifiedFilters);
    navigate(`${AppRoutes.v3FullPath.replying}?${params.toString()}`, { replace: false });
  };

  return <Tippy theme="dark" content="Reply to Users" delay={200}>
    <div
      className="rounded-full  bg-silver  text-blueberry flex flex-row justify-center items-center cursor-pointer h-8 w-8 hover:scale-110 duration-150 hover:shadow-sm"
      onClick={navigateToReplyPage}
    >
      <div>
        <ChatBubbleLeftIcon className="h-4 w-4 stroke-2" />
      </div>
    </div>
  </Tippy>;
}