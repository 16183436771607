import {
  Breakdown,
  Chart2DFragment,
  Chart_Bin_Type,
  Chart_Type,
  ChartFragment,
  EditChartInput,
  FilterType,
  Group_Type,
  GroupFilter,
  useGetChart2DPreviewLazyQuery,
} from '../../generated/graphql';
import { useValidTeamAppContext } from '../../v2/contexts/AppContext';
import { CustomChart } from './CustomChart';
import { useContext, useEffect, useState } from 'react';
import { AssistantDataContext } from '../../context/assistantContext';
import { AssistantStatus } from '../../reducers/assistant/AssistantDispatch';
import { CustomChartCardSkeleton } from './CustomChartCardSkeleton';
import { unzip, zip } from 'underscore';

export interface Segment {
  segmentGroupId: string,
  segmentValue: string,
}

export interface ChartProps {
  groupIds: number[];
  startDate: string;
  endDate: string;
  segment?: Segment
}

/**
 * Handles the error, loading, and data states of the chart in the feedback assistant
 * Right now this is coupled to a certain type of chart (group breakdown)
 * @param props - groupIds, startDate, endDate
 * @param id - id of the stream, which we use for a tag
 * @constructor
 */
export const GroupBreakdownChartPreview = ({ id }: { id: string }) => {
  const [getChartPreview, chartPreview] = useGetChart2DPreviewLazyQuery();
  const {status, components} = useContext(AssistantDataContext);
  const chartProps = components[id].props as ChartProps;
  const { curTeamId } = useValidTeamAppContext();
  const [height, setHeight] = useState<number>(0);
  const [width, setWidth] = useState<number>(0);

  useEffect(() => {
    if (!chartProps.groupIds || chartProps.groupIds.length <= 0) return;
    if(status !== AssistantStatus.done) return; // this prevents us from firing 100s of AJAX requests
    if(chartProps.groupIds.length <= 1) return; // No need to fetch chart data to render data for a single group
    const numGroupsPlotted = chartProps.groupIds.length;
    const heightInPx = Math.max(300, numGroupsPlotted * 50);
    setHeight(heightInPx);
    setWidth(heightInPx * 2);

    getChartPreview({
      variables: {
        teamId: curTeamId,
        chartBinType: Chart_Bin_Type.Dynamic,
        editChartInput: {

          breakdown: Breakdown.Group,
          series: [{
            teamIdOverride: curTeamId,
            filterInput: {
              startDate: chartProps.startDate,
              endDate: chartProps.endDate,
              segmentFilters: (chartProps.segment != undefined) ? [{
                filterCondition: FilterType.Or,
                segments: [chartProps.segment.segmentValue],
                groupId: Number(chartProps.segment.segmentGroupId),
              }] : [],
              groupFilter: chartProps.groupIds.slice(0, 6).map((id) => ({
                filterCondition: FilterType.Or,
                group: {
                  id: Number(id),
                  type: Group_Type.Cluster,
                } as GroupFilter,
              }))
            }
          }],
          title: 'Group Breakdown',
          type: Chart_Type.HorizontalBar,
        } as EditChartInput,
      }
    });
  }, [(chartProps.groupIds ?? []).length, status, id]);
  // listening to groupId count will enable us to update the state variable.
  // status will gate-keep data-fetching so that we don't fetch data on every render

  const child = () => {
    const shouldRenderChart = ((chartProps.groupIds ?? []).length > 3); // charts are useless if they have less than 3 groups

    if(!shouldRenderChart) return <></>;

    if(status == AssistantStatus.streaming) return <CustomChartCardSkeleton className={'w-full'}/>;
    // There is a moment where the assistant is done streaming markdown and the chart is still fetching data
    // without this here, the chart skeleton will disappear for a moment and the full chart will render
    // we want this transition to be seamless, so we keep the skeleton until the chart data has fetched
    if(status == AssistantStatus.done) {
      if(!chartPreview.data) return <CustomChartCardSkeleton className={'w-full'}/>;
    }

    if(chartPreview.error) return <div>Failed to get chart</div>
    if(!chartPreview.data?.getChart2DPreview) return <></>;


    return <div style={{'height': height, 'width': width}}>
      <CustomChart customChartData={adaptChartData(chartPreview.data?.getChart2DPreview)} showLegend={false} />
    </div>
  }

  return (
    <div key={id} className={`flex flex-col w-1/2`} >
      <div className={'pl-10 flex flex-row justify-center'}>{child()}</div>
    </div>
  );
}

const adaptChartData = (data: Chart2DFragment): ChartFragment => {
  const seriesData : [number, string, string][] = zip(data.series[0], data.tooltipLabels, data.xAxisLabels)
    .sort((a, b) => (b[0]- a[0]));

  const [series, tooltips, xAxisLables] = unzip(seriesData);

  return {
    id: data.id,
    title: data.title,
    type: Chart_Type.VerticalBar,
    y_axis_data: data.config.y_axis_data,
    breakdown: data.config.breakdown,
    seriesData: [
      {
        aggregateData: [series],
        normalizedData: [series],
        tooltipLabels: tooltips,
        chartLabels: xAxisLables.map((label) => label.length > 20 ? label.substring(0, 20) + '...' : label),
        breakdownLabels: data.legend,
        filterInput: data.config.seriesConfig?.[0].filterInput ?? {},
        team: data.config.seriesConfig?.[0].team ?? { id: -1, name: 'All Teams' },
      },
    ],
  };
}