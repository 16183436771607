import { GroupBase, TaxonomyGroup } from '../../v2/hooks/GroupHook';
import { logError } from "../../applicationTelemetry";
import { GroupDataFragment, GroupTaxonomyFragment, TaxonomyElement, TaxonomyTreeNode } from '../../generated/graphql';
import { adaptTaxonomyElements } from './taxonomyAdapter';

export const LOCAL_STORAGE_KEY = 'ids';

/**
 * Adapts responses from the server to the TaxonomyGroup.
 * This function will also decorate some properties on the TaxonomyGroup 
 * @param groups 
 * @param currentTaxonomy 
 * @param parentId 
 * @returns 
 */
export const getTaxonomyMap = (
  groups: GroupDataFragment[] | GroupTaxonomyFragment[] | GroupBase[] | TaxonomyElement[] | null | undefined,
  currentTaxonomy?: Map<number, TaxonomyGroup>,
  parentId?: number
): Map<number, TaxonomyGroup> => {

    if (groups == null || groups.length < 1) return new Map();

    if (containsParent(groups)) {
        return createParentTaxonomyMap(groups, parentId, currentTaxonomy);
    }

  return createTaxonomyFromOrphans(groups, currentTaxonomy)
};


/**
 * Converts TaxonomyElements to TaxonomyGroups. The frontend only understands TaxonomyGroups, so we need to convert the data to the correct format.
 * We do this by recursively calling the function on the children of the TaxonomyElement.
 * @param taxonomyElements
 * @param parent
 * @returns
 */
export const toTaxonomyMap = (taxonomyElements: TaxonomyTreeNode[]): Map<number, TaxonomyGroup> => {
  const taxonomyMap : Map<number, TaxonomyGroup> = new Map();
  const taxonomyGroups : TaxonomyGroup[] = adaptTaxonomyElements(taxonomyElements, null);

  populateTaxonomyMap(taxonomyGroups, taxonomyMap);

  return taxonomyMap;
}

const populateTaxonomyMap = (groups: TaxonomyGroup[], taxonomy: Map<number, TaxonomyGroup>) => {
  if(groups.length < 1) return;
  groups.forEach((group) => {
    taxonomy.set(group.id, group);
    populateTaxonomyMap(group.children ?? [], taxonomy);
  });
}


export function createTaxonomyFromOrphans(groups: GroupDataFragment[] | GroupTaxonomyFragment[] | GroupBase[] | TaxonomyElement[], currentTaxonomy?: Map<number, TaxonomyGroup>) {
    const taxonomy: Map<number, TaxonomyGroup> = new Map(currentTaxonomy ?? []);

    // this assumes taxonomy returned is from orphaned query
    // Check if groups is an array of TaxonomyElement
    if (isGroupTaxonomyFragmentArray(groups)) {

        const taxonomyGroups = groups as GroupTaxonomyFragment[];
        // put your handling logic for TaxonomyElement array here...
        taxonomyGroups.forEach((group) => {
            taxonomy.set(
                group.id,
                new TaxonomyGroup({
                    parentId: null,
                    showChildren: false,
                    children: [],
                    trending: group.trending,
                    canAddChildren: group.canAddChildren,
                    id: group.id,
                    title: group.title,
                    totalEntries: group.uniqueEntries,
                    denominator: group.statistics.denominator.denominatorUnfiltered,
                    processing: group.processing,
                    progress: group.progress,
                    pinnedByUser: group.isPinnedByUser,
                    centroid: group.centroidText,
                    status: group.status,
                    isExactMatch: group.isExactMatch,
                    type: group.type,
                    sentences: [],
                    entries: [],
                    tags: group.tags,
                    relativeShare: group.statistics.denominator.denominatorFiltered != 0 ? (group.uniqueEntries * 100) / group.statistics.denominator.denominatorFiltered : 0,
                    relativeShareFull: group.statistics.denominator.denominatorUnfiltered != 0 ? (group.uniqueEntries * 100) / group.statistics.denominator.denominatorUnfiltered : 0,
                    date: group.dateCreated ?? 0,
                    totalDescendents: group.totalDescendents,
                })
            );
        });
    } else if (isTaxonomyGroupArray(groups)) {
        const taxonomyGroups = groups as TaxonomyGroup[];
        // put your handling logic for TaxonomyGroup array here...
        taxonomyGroups.forEach((group: TaxonomyGroup) => {
            taxonomy.set(group.id, group);
        });
    }

    return taxonomy;
}

function createParentTaxonomyMap(groups: GroupDataFragment[] | GroupTaxonomyFragment[] | GroupBase[] | TaxonomyElement[], parentId: number | undefined, currentTaxonomy: Map<number, TaxonomyGroup> | undefined) : Map<number, TaxonomyGroup> {
    const taxonomy: Map<number, TaxonomyGroup> = new Map();
    const savedIds = getGroupIdsFromLocalStorage();
    // change this to typename
    const taxonomyElements = groups as TaxonomyElement[];

    taxonomyElements.forEach((group: TaxonomyElement) => {
        group.childrenIds?.forEach((childId) => {
            if (taxonomyElements.find((c: TaxonomyElement) => c.groupId === childId) != undefined) {
                const childNode = taxonomyElements.find((c: TaxonomyElement) => c.groupId === childId)?.node;
                if (!childNode) {
                    logError('childNode is undefined');
                    // go to next iteration
                    return;
                }
                taxonomy.set(
                    childId,
                    new TaxonomyGroup({
                        parentId: group.groupId,
                        showChildren: savedIds.includes(childId) ? true : false,
                        children: [],
                        trending: childNode.trending,
                        canAddChildren: childNode.canAddChildren,
                        id: childNode.id,
                        title: childNode.title,
                        totalEntries: childNode.uniqueEntries,
                        denominator: childNode.statistics.denominator.denominatorUnfiltered,
                        processing: childNode.processing,
                        progress: childNode.progress,
                        pinnedByUser: childNode.isPinnedByUser,
                        centroid: childNode.centroidText,
                        status: childNode.status,
                        isExactMatch: childNode.isExactMatch,
                        type: childNode.type,
                        sentences: [],
                        entries: [],
                        tags: childNode.tags,
                        relativeShare: childNode.statistics.denominator.denominatorFiltered != 0
                            ? (childNode.uniqueEntries * 100) / group.node.statistics.denominator.denominatorFiltered
                            : 0,
                        relativeShareFull: childNode.statistics.denominator.denominatorUnfiltered != 0
                            ? (childNode.uniqueEntries * 100) / group.node.statistics.denominator.denominatorUnfiltered
                            : 0,
                        date: childNode.dateCreated ?? 0,
                        totalDescendents: childNode.totalDescendents,
                    })
                );
            }
        });
        taxonomy.set(
            group.groupId!,
            // THIS SHOULD NOT BE TS_IGNORED! The whole point of TS is so that the typing system tells you that the data shapes are different!!!
            // group.node does not fit TaxonomyGroupProps!
            new TaxonomyGroup({
                ...taxonomy.get(group.groupId),
                parentId: parentId ? parentId : taxonomy.get(group.groupId)?.parentId ?? null,
                showChildren: currentTaxonomy ? currentTaxonomy.get(group.groupId)?.showChildren ?? false : savedIds.includes(group.groupId) ? true : false,
                children: group.childrenIds.map((id) => taxonomy.get(id)!).filter((c) => c) as TaxonomyGroup[],
                trending: group.node.trending,
                canAddChildren: group.node.canAddChildren,
                id: group.node.id,
                title: group.node.title,
                totalEntries: group.node.uniqueEntries,
                denominator: group.node.statistics.denominator.denominatorUnfiltered,
                processing: group.node.processing,
                progress: group.node.progress,
                pinnedByUser: group.node.isPinnedByUser,
                centroid: group.node.centroidText,
                status: group.node.status,
                isExactMatch: group.node.isExactMatch,
                type: group.node.type,
                sentences: [],
                entries: [],
                tags: group.node.tags,
                relativeShare: group.node.statistics.denominator.denominatorFiltered != 0
                    ? (group.node.uniqueEntries * 100) / group.node.statistics.denominator.denominatorFiltered
                    : 0,
                relativeShareFull: group.node.statistics.denominator.denominatorUnfiltered != 0
                    ? (group.node.uniqueEntries * 100) / group.node.statistics.denominator.denominatorUnfiltered
                    : 0,
                date: group.node.dateCreated ?? 0,
                totalDescendents: group.node.totalDescendents,
            })
        );
    });
    return taxonomy;
}

/**
 * Set group ids that should be shown as expanded in the UI
 * @param ids - Array of group ids that should be shown in the UI
 */
export const saveGroupIdsToLocalStorage = (ids: number[]) => {
  try {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(ids));
  } catch (e) {
    console.error('Error saving to localstorage', e);
  }
};

/**
 * If a groupId exists in the local storage, we expand its TaxonomyFolder Component
 * @returns {number[]} - Array of group ids that should be shown in the UI
 */
export const getGroupIdsFromLocalStorage = () => {
  try {
    const items = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (items) {
      return JSON.parse(items);
    }
    return [];
  } catch (e) {
    console.error('Error getting data from localstorage', e);
    return [];
  }
};

export function isGroupTaxonomyFragmentArray(arr: any[]): arr is GroupTaxonomyFragment[] {
  return arr && arr.length > 0 && 'statistics' in arr[0];
}

export function isTaxonomyGroupArray(arr: any[]): arr is TaxonomyGroup[] {
  return arr && arr.length > 0 && 'denominator' in arr[0];
}

/**
 * returns true if the first group in the array contains a childrenIds property
 * @param groups - groups that could contain parent
 * @returns 
 */
function containsParent(groups: GroupDataFragment[] | GroupTaxonomyFragment[] | GroupBase[] | TaxonomyElement[]) {
    return 'childrenIds' in groups[0];
}