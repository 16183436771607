import { useEffect, useState } from "react";
import { FilterInput, SeriesInput } from "../../generated/graphql";
import { ComboBox } from "../../baseComponents/ComboBox";
import { IDropDownItem } from "../baseComponents/DropDown";
import { cloneDeep } from "lodash";
import { FilterManager, FilterManagerDisplayMode } from "../sections/Filters/FilterManager";
import { EditChartInputRow } from "./EditChartSection";

export const DataCard = ({
  teamsList,
  teamId,
  filter,
  updateFilterInputs,
  deleteFilterInput,
  dataFilters,
  setDataFilters,
  index,
}: {
  teamsList: any;
  teamId: number;
  settingsFilterManagerRef: any;
  filter: SeriesInput;
  updateFilterInputs: (filterInput: SeriesInput) => void;
  deleteFilterInput: (teamId: number) => void;
  dataFilters: SeriesInput[];
  setDataFilters: (dataFilters: SeriesInput[]) => void;
  index: number;
}) => {
  const [filterTeamId, setFilterTeamId] = useState<number>(teamId);
  useEffect(() => {
    setFilterTeamId(teamId);
  }, [teamId]);
  return (
    <div className="chart-data-card w-full p-4 rounded-md border border-gray-300">
      <EditChartInputRow name="View" onRemove={index !== 0 ? () => deleteFilterInput(filterTeamId) : undefined}>
        {/* remove every but the first one */}
        <div className="chart-data-card-view-combobox py-2 w-fit">
          <ComboBox
            comboBoxData={teamsList}
            setSelectedItem={function (selectedItem: IDropDownItem | undefined): void {
              // so we have the filter team
              const updatedDataFilters = cloneDeep(dataFilters);
              const dataFilterIndex = dataFilters.findIndex((_, i) => i === index);
              if (dataFilterIndex > -1) {
                updatedDataFilters[dataFilterIndex].teamIdOverride = selectedItem?.id;
                setFilterTeamId(selectedItem?.id ?? -1);
                setDataFilters(updatedDataFilters);
              }
            }}
            selectedItem={teamsList.find((item: any) => item.id === filterTeamId)}
            capitalizeOnlyFirstLetter={false}
          />
        </div>
      </EditChartInputRow>
      <EditChartInputRow name="Filters">
        <div className="w-fit">
          <FilterManager
            pageName={'Chart'}
            dataTypeToFilter={'chartEditor'}
            displayMode={FilterManagerDisplayMode.ChartEditor}
            filterButtonText="Add Filter"
            setFilterInputs={(filterInput: FilterInput) => {
              const updatedInput = cloneDeep(filter);
              updatedInput.filterInput = filterInput;
              updateFilterInputs(updatedInput);
            }}
            teamIdOverride={teamId}
            startingFilterInput={filter.filterInput ?? {}}
          />
        </div>
      </EditChartInputRow>
    </div>
  );
};
