import { AdjustmentsHorizontalIcon } from '@heroicons/react/24/solid';
import moment from 'moment';
import { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';
import Badge from '../../components/Badge';
import { FilterInput, DataForFiltersQueryResult, useDataForFiltersLazyQuery, useTeamDefaultsLazyQuery, Date_Window } from '../../../generated/graphql';
import AppContext, { useValidTeamAppContext } from '../../../v2/contexts/AppContext';
import FilterModal from './FilterModal';
import { FilterCategory, IBadgeFilter, IFilter } from '../../sections/Filters/FiltersTypes';
import { DatePicker } from '../../baseComponents/DatePicker';
import { HorizontalDateSelector, HorizontalDateSelectorOption } from '../../components/HorizontalDateSelector';
import { FilterHook, updateFilters, useFilterHook } from '../../hooks/FilterHook';
//@ts-ignore
import uuid from 'react-uuid';
import { FilterableDataTypes } from './FiltersUtil';
import SearchInput from '../../baseComponents/SearchInput';
import { capitalizeFirstLetter, classNames, useIsMount } from '../../../v2/util';
import sourcesMap from '../../../v2/other/sourcesMap';
import Tippy from '@tippyjs/react';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import { exportFeedbackWithProgressUpdate } from '../../../eventHandlers/exports/feedbackExport';
import { Status } from '../../../exports/ProgressMonitor';
import { ProgressBar } from '../../components/ProgressBar';
import { useLocation, useNavigate } from 'react-router-dom';

export enum FilterManagerDisplayMode {
  Regular,
  ChartsPage, //Only search and date
  ChartEditor, //Only filters
  ChartEditorPreview, //Only date
  GroupModal, //Only date
  OnlyButton,
  OnlyFiltersShown,
  GroupPage,
  FeedbackPage,
  ExplorePage,
}

type ComponentType = 'search' | 'dates' | 'filterButton' | 'filtersShown' | 'exportToCSV';

function shouldShowComponent(component: ComponentType, currentMode: FilterManagerDisplayMode): boolean {
  const visibilityMap: Record<FilterManagerDisplayMode, ComponentType[]> = {
    [FilterManagerDisplayMode.Regular]: ['search', 'dates', 'filterButton', 'filtersShown'],
    [FilterManagerDisplayMode.ChartsPage]: ['dates', 'filterButton', 'filtersShown'],
    [FilterManagerDisplayMode.ChartEditor]: ['filterButton', 'filtersShown'],
    [FilterManagerDisplayMode.ChartEditorPreview]: ['dates'],
    [FilterManagerDisplayMode.GroupModal]: ['dates'],
    [FilterManagerDisplayMode.OnlyButton]: ['filterButton'],
    [FilterManagerDisplayMode.OnlyFiltersShown]: ['filtersShown'],
    [FilterManagerDisplayMode.GroupPage]: ['dates', 'filterButton', 'filtersShown'],
    [FilterManagerDisplayMode.FeedbackPage]: ['search', 'dates', 'filterButton', 'filtersShown', 'exportToCSV'],
    [FilterManagerDisplayMode.ExplorePage]: ['dates', 'filterButton', 'filtersShown'],
  };

  return visibilityMap[currentMode].includes(component);
}

interface FilterManagerProps {
  pageName: string;
  filterHook?: FilterHook;
  setFilterInputs?: (filterInput: FilterInput) => void;
  dataTypeToFilter: FilterableDataTypes;
  searchEnabled?: boolean;
  queryStringAppliesToGroupTitle?: boolean;
  displayMode?: FilterManagerDisplayMode;
  filterButtonText?: string;
  teamIdOverride?: number;
  startingFilterInput?: FilterInput;
  overridenFiltersShown?: IFilter[];
  parentSetFiltersShown?: (filters: IFilter[]) => void;
}

const endOfToday = moment().endOf('day').toDate();
export const DateOptions: HorizontalDateSelectorOption[] = [
  { name: '7D', startDate: moment().subtract(7, 'days').startOf('day').toDate(), endDate: endOfToday, date_window: Date_Window.Last_7d },
  { name: '30D', startDate: moment().subtract(30, 'days').startOf('day').toDate(), endDate: endOfToday, date_window: Date_Window.Last_30d },
  { name: '90D', startDate: moment().subtract(90, 'days').startOf('day').toDate(), endDate: endOfToday, date_window: Date_Window.Last_90d },
  { name: '1Y', startDate: moment().subtract(1, 'year').startOf('day').toDate(), endDate: endOfToday, date_window: Date_Window.Last_1y },
  { name: 'All', startDate: moment().startOf('day').toDate(), endDate: endOfToday, disabled: true, date_window: Date_Window.All },
];

const getDateOptionFromDateRange = (startDate: Date | undefined, endDate: Date | undefined): HorizontalDateSelectorOption | undefined => {
  //This checks if the date range is one of the default options, and if so, returns it.
  //If both dates are undefined, it should choose a default option, in this case we'll choose 90D.
  if (!startDate && !endDate) return DateOptions.find((option) => option.name === '90D');
  return DateOptions.find((option) => {
    return (
      option.startDate.getTime() === moment(startDate).startOf('day').toDate().getTime() &&
      option.endDate.getTime() === moment(endDate).endOf('day').toDate().getTime()
    );
  });
};

export const FilterManager = forwardRef(
  (
    {
      pageName,
      filterHook,
      dataTypeToFilter,
      queryStringAppliesToGroupTitle,
      displayMode = FilterManagerDisplayMode.Regular,
      filterButtonText,
      setFilterInputs,
      teamIdOverride,
      startingFilterInput,
      //This needs to be done to be able to share this array in some places.
      //Once this gets reworked into better Manager/Hook structure, this will be removed.
      overridenFiltersShown,
      parentSetFiltersShown,
    }: FilterManagerProps,
    ref
  ): JSX.Element => {
    const { curTeamId: teamId, curOrgId: orgId } = useValidTeamAppContext();
    const defaultFilterHook = useFilterHook({ teamId, orgId, startingFilterInput: startingFilterInput ?? {}, disableUrlFilters: true });
    const hook = filterHook ? filterHook : defaultFilterHook;
    const filters = hook.filters;
    const [currentFilter, setCurrentFilter] = useState<IFilter | undefined>(undefined);
    const [filtersShown, setFiltersShown] = useState<IFilter[]>(overridenFiltersShown ?? []);
    const [modalOpen, setModalOpen] = useState(false);
    const [queryString, setQueryString] = useState<string | undefined>(
      queryStringAppliesToGroupTitle && filters?.groupTitleFilterQuery ? filters?.groupTitleFilterQuery : filters?.queryString?.[0] ?? undefined
    );
    const [getDataForFilters] = useDataForFiltersLazyQuery({ variables: { teamId: teamIdOverride ?? teamId, orgId } });
    const { app } = useContext(AppContext);
    const dateSelectorRef = useRef<any>(null);
    const [dateOptions, setDateOptions] = useState<HorizontalDateSelectorOption[]>(DateOptions);
    const urlDateFiltersExist = !!hook.filtersFromUrl?.startDate || !!hook.filtersFromUrl?.endDate;

    const [getTeamDefaults, { data: teamDefaults }] = useTeamDefaultsLazyQuery({
      variables: { teamId },
      onCompleted(data) {
        /*
        Team Defaults Logic:
        - The oldestFeedbackDate is used to determine the value of the "All" date option.
        - Regarding the default date logic:
          - If the team has both a start and end date, set the date selector to those dates and ignore the window.
          - If the start, end, or both dates are missing, and the team has a default window, set the date selector to that window.
          - If the team has no default window or dates, do nothing.
        URL Date Filters override these defaults, as we need to obey what's on the URL.
        This logic is applied to the Explore, Feedback, and Charts pages.
        The Group Page inherits the filters from the Explore Page even when coming from a separate URL, so groups follow the same logic. 
        Applying this to the Group Page would override inherited filters and cause conflict, so we need the condition, at least until a future rework.
        */

        //Setting the ALL date option to the oldest feedback date.
        const newDateOptions = [
          ...dateOptions.map((option) => {
            return option.name === 'All' ? { ...option, startDate: moment.utc(data.teamDefaults.oldestFeedbackDate).toDate(), disabled: false } : option;
          }),
        ];
        setDateOptions(newDateOptions);

        const defStartDate = data.teamDefaults.startDate;
        const defEndDate = data.teamDefaults.endDate;
        if (!urlDateFiltersExist) {
          if (pageName === 'Explore Page' || pageName === 'Feedback Page' || pageName === 'Charts Page') {
            if (defStartDate && defEndDate) {
              const startDt = moment.utc(defStartDate).toDate();
              const endDt = moment.utc(defEndDate).toDate();
              updateClustersDates(startDt, endDt);
              dateSelectorRef.current?.updateSelectedOption(startDt, endDt);
            } else {
              //We omit the "ALL" option, as it's handled on a separate useEffect once the ALL selector's state has been populated and becomes available.
              if (data.teamDefaults.exploreDefaultWindow && data.teamDefaults.exploreDefaultWindow !== Date_Window.All) {
                dateSelectorRef.current.setOptionByDateWindow(data.teamDefaults.exploreDefaultWindow);
              }
            }
          }
        }
      },
    });

    function updateFiltersShown(filters: IFilter[]) {
      parentSetFiltersShown?.(filters);
      if (!!!overridenFiltersShown) setFiltersShown(filters);
    }

    useEffect(() => {
      setFiltersShown(overridenFiltersShown ?? []);
    }, [overridenFiltersShown]);

    useEffect(() => {
      //If dateOptions got "All" updated and the team has an "All" default window, set the date selector to that window.
      if (
        !urlDateFiltersExist &&
        dateSelectorRef.current &&
        !dateOptions.find((option) => option.name === 'All')?.disabled &&
        teamDefaults?.teamDefaults?.exploreDefaultWindow === Date_Window.All &&
        !teamDefaults?.teamDefaults?.startDate &&
        !teamDefaults?.teamDefaults?.endDate
      ) {
        dateSelectorRef.current.setOptionByDateWindow(teamDefaults.teamDefaults.exploreDefaultWindow);
      }
    }, [dateOptions]);

    const isFirstRender = useIsMount();

    useEffect(() => {
      let isMount = true;
      if (isMount) {
        setUiFilters(filters, updateFiltersShown, () => getDataForFilters({ variables: { teamId: teamIdOverride ?? teamId, orgId } }));
        getTeamDefaults();
      }
      return () => {
        isMount = false;
      };
    }, [teamIdOverride]);

    useEffect(() => {
      if (!isFirstRender && dateSelectorRef.current) {
        dateSelectorRef.current.reset();
        setDateOptions((dateOptions) => dateOptions.map((option) => (option.name === 'All' ? { ...option, disabled: true } : option)));
        getTeamDefaults({ variables: { teamId } });
      }
      if (!isFirstRender) setQueryString(undefined);
      updateFiltersShown([]);
    }, [teamId]);

    useEffect(() => {
      getTeamDefaults({ variables: { teamId } });
    }, []);
    useImperativeHandle(ref, () => ({
      refreshUiFilters: (filters: FilterInput) => {
        setUiFilters(filters, updateFiltersShown, () => getDataForFilters({ variables: { teamId: teamIdOverride ?? teamId, orgId } }));
      },
    }));

    const openModal = () => setModalOpen(true);

    const onAddFilter = () => {
      setCurrentFilter(undefined);
      openModal();
    };

    const onEditFilter = (badge: IBadgeFilter) => {
      setCurrentFilter(badge.filter);
      openModal();
    };

    const saveFilter = (filter: IFilter, teamId: number) => {
      const existingIndex = filtersShown.findIndex((existing) => {
        return existing.uiId === filter.uiId;
      });

      if (existingIndex > -1) {
        filtersShown.splice(existingIndex, 1);
      }
      const newFilters = [...filtersShown];
      if (filter.values.length !== 0) {
        newFilters.push(filter);
      }

      updateFilters(
        teamId,
        orgId,
        newFilters,
        filterHook ? hook.setFilters : (filterInput) => setFilterInputs!(filterInput),
        filters.startDate,
        filters.endDate,
        queryString,
        'group',
        queryStringAppliesToGroupTitle,
        hook.disableUrlFilters
      );
      updateFiltersShown(newFilters);
    };

    const onRemoveFilter = (badge: IBadgeFilter, teamId: number): void => {
      const index = filtersShown.findIndex((filter) => {
        return filter === badge.filter;
      });
      filtersShown.splice(index, 1);
      updateFiltersShown([...filtersShown]);
      updateFilters(
        teamId,
        orgId,
        filtersShown,
        filterHook ? hook.setFilters : (filterInput) => setFilterInputs!(filterInput),
        filters.startDate,
        filters.endDate,
        queryString,
        'group',
        queryStringAppliesToGroupTitle,
        hook.disableUrlFilters
      );
    };

    const updateClustersDates = (newStartDate: Date = filters.startDate, newEndDate: Date = filters.endDate) => {
      updateFilters(
        teamId,
        orgId,
        filtersShown,
        filterHook ? hook.setFilters : (filterInput) => setFilterInputs!(filterInput),
        newStartDate,
        moment(newEndDate).endOf('day').toDate(),
        queryString,
        'group',
        queryStringAppliesToGroupTitle,
        hook.disableUrlFilters
      );
    };

    const [exportProgress, updateExportProgress] = useState({ status: Status.idle, percent: 0 });
    //if (loadingTeamDefaults) return <></>;
    return (
      <div>
        {!app?.isPreviewMode && (
          <FilterModal
            teamIdOverride={teamIdOverride}
            currentFilter={currentFilter}
            modalOpen={modalOpen}
            setCurrentFilter={setCurrentFilter}
            setModalOpen={setModalOpen}
            saveFilter={saveFilter}
            page={pageName}
            dataTypeToFilter={dataTypeToFilter}
          />
        )}
        <div className="flex flex-col">
          <div className="flex flex-row items-center justify-between gap-x-0.5">
            {shouldShowComponent('search', displayMode) ? (
              <div className="relative hidden w-1/4 flex-col items-center xl:flex">
                <SearchInput
                  locked={displayMode === FilterManagerDisplayMode.ChartsPage}
                  queryString={queryString}
                  setQueryString={setQueryString}
                  onSearch={(query) => {
                    updateFilters(
                      teamId,
                      orgId,
                      filtersShown,
                      filterHook ? hook.setFilters : (filterInput) => setFilterInputs!(filterInput),
                      filters.startDate,
                      filters.endDate,
                      query,
                      'group',
                      queryStringAppliesToGroupTitle,
                      hook.disableUrlFilters
                    );
                  }}
                  noPadding
                />
              </div>
            ) : null}
            {shouldShowComponent('dates', displayMode) ? (
              <div id="date-selector" className="flex flex-row gap-x-1">
                <DatePicker
                  date={filters.startDate}
                  onChange={(dt: Date) => {
                    updateClustersDates(dt, undefined);
                    dateSelectorRef.current?.updateSelectedOption(dt, filters.endDate);
                  }}
                  isChanged={!moment(hook.originalStartDate).isSame(filters.startDate)}
                />
                <DatePicker
                  date={filters.endDate}
                  onChange={(dt: Date) => {
                    updateClustersDates(undefined, dt);
                    dateSelectorRef.current?.updateSelectedOption(filters.startDate, dt);
                  }}
                  isChanged={!moment().endOf('day').isSame(filters.endDate)}
                />
                <HorizontalDateSelector
                  onChooseDate={(newStartDate: Date | undefined, newEndDate: Date | undefined) => {
                    hook.updateFilterDates(newStartDate, newEndDate, filtersShown, queryStringAppliesToGroupTitle);
                  }}
                  options={dateOptions}
                  defaultSelect={getDateOptionFromDateRange(filters.startDate, filters.endDate)}
                  ref={dateSelectorRef}
                />
              </div>
            ) : null}
            <div className="flex flex-row gap-x-1">
              {shouldShowComponent('filterButton', displayMode) ? (
                <Tippy theme="dark" delay={200} content="Add Filter">
                  <div
                    id="filter-modal-opener"
                    className={classNames(
                      'flex h-full cursor-pointer items-center gap-x-2 rounded-full bg-silver p-2 text-blueberry duration-200 hover:bg-blueberry hover:text-milk',
                      filterButtonText ? 'gap-x-2 px-3' : ''
                    )}
                    onClick={() => onAddFilter()}
                  >
                    <AdjustmentsHorizontalIcon className="h-5 w-5" />
                    {filterButtonText ? <p>{filterButtonText}</p> : null}
                  </div>
                </Tippy>
              ) : null}
              {shouldShowComponent('exportToCSV', displayMode) ? (
                <Tippy theme="dark" content="Export to CSV" delay={200}>
                  <div
                    className="rounded-full h-full gap-x-2 bg-silver text-blueberry flex flex-row justify-center items-center cursor-pointer p-2 duration-200 hover:bg-blueberry hover:text-milk"
                    onClick={() => exportFeedbackWithProgressUpdate({ teamId: teamId, filterInput: filters }, exportProgress, updateExportProgress)}
                  >
                    <div>
                      <ArrowDownTrayIcon className="h-5 w-5 stroke-2" />
                    </div>
                  </div>
                </Tippy>
              ) : null}
            </div>
          </div>
          {shouldShowComponent('search', displayMode) ? (
            <div className="mt-4 block w-1/2 xl:hidden xl:w-1/3">
              <SearchInput
                queryString={queryString}
                setQueryString={setQueryString}
                onSearch={(query) => {
                  updateFilters(
                    teamId,
                    orgId,
                    filtersShown,
                    filterHook ? hook.setFilters : (filterInput) => setFilterInputs!(filterInput),
                    filters.startDate,
                    filters.endDate,
                    query,
                    'group',
                    queryStringAppliesToGroupTitle,
                    hook.disableUrlFilters
                  );
                }}
                noPadding
              />
            </div>
          ) : null}

          {shouldShowComponent('filtersShown', displayMode) ? (
            <>
              {filtersShown.length > 0 ? (
                <div className="mt-2 flex flex-row flex-wrap items-center gap-x-1 gap-y-1 font-bold text-blueberry">
                  {filtersShown.map((filter, index) => {
                    const badgeValue: IBadgeFilter = { text: getBadgeText(filter), id: index.toString(), filter: filter };
                    return (
                      <div id="applied-filter" className="" key={index}>
                        <Badge
                          badge={badgeValue}
                          key={index}
                          onEdit={(badge: any) => {
                            onEditFilter(badge as IBadgeFilter);
                          }}
                          onRemove={(badge) => onRemoveFilter(badge as IBadgeFilter, teamId)}
                          color="bg-blueberry"
                          textColor="text-milk"
                          capitalize={false}
                        />
                      </div>
                    );
                  })}
                </div>
              ) : null}
            </>
          ) : null}
        </div>
        {exportProgress.status !== Status.idle ? (
          <div className="flex flex-col items-center justify-center py-2.5">
            <ProgressBar exportProgress={exportProgress} />
          </div>
        ) : null}
      </div>
    );
  }
);

export const getBadgeText = (filter: IFilter, hideTeamName?: boolean): string => {
  const properCase = (text: string) => (['NEGATIVE', 'NEUTRAL', 'POSITIVE'].includes(text) ? capitalizeFirstLetter(text) : text);
  return (
    (!hideTeamName && filter.teamName != null ? filter.teamName + ': ' : '') +
    '' +
    filter.filterCategoryTitle +
    ':' +
    filter.values
      .map((value, index) =>
        index === 0 ? ' ' + properCase(value.displayName ?? value.title) : ' ' + filter.filterCondition + ' ' + properCase(value.displayName ?? value.title)
      )
      .join('')
  );
};

export const setUiFilters = async (
  filterInput: FilterInput,
  setFiltersShown: (filters: IFilter[]) => void,
  getDataForFilters: () => Promise<DataForFiltersQueryResult>
) => {
  const filters: IFilter[] = [];
  const { data } = await getDataForFilters();

  filterInput.segmentFilters?.map((segment) => {
    filters.push({
      filterCategory: FilterCategory.Segment,
      filterCategoryTitle: 'Custom Field',
      filterCategoryId: segment.groupId,
      uiId: uuid(),
      filterCondition: segment.filterCondition,
      values: segment.segments.map((seg) => {
        return { segment: seg, uiId: uuid(), title: seg };
      }),
    });
  });

  filterInput.sentimentFilter?.map((sentiment) => {
    filters.push({
      filterCategory: FilterCategory.Sentiment,
      filterCategoryTitle: 'Sentiment',
      uiId: uuid(),
      filterCondition: sentiment.filterCondition,
      values: sentiment.sentiments.map((sentiment) => {
        return { uiId: uuid(), title: sentiment, sentiment: sentiment };
      }),
    });
  });

  filterInput.sourceFitler?.map((source) => {
    filters.push({
      filterCategory: FilterCategory.Source,
      filterCategoryTitle: 'Source',
      uiId: uuid(),
      filterCondition: source.filterCondition,
      values: source.sources.map((sourceInd) => {
        return { uiId: uuid(), title: sourcesMap[sourceInd]?.name ?? sourceInd, source: sourceInd };
      }),
    });
  });
  filterInput.tagFilters?.map((tag) => {
    filters.push({
      filterCategory: FilterCategory.Tag,
      filterCategoryTitle: 'Tag',
      uiId: uuid(),
      filterCondition: tag.filterCondition,
      values: tag.tags.map((tagInd) => {
        return { uiId: uuid(), title: data?.getTags?.find((t) => t.id === tagInd.id)!.name ?? '', id: tagInd.id };
      }),
    });
  });
  filterInput.clusterFilters?.map((clusterFilter) => {
    filters.push({
      filterCategory: FilterCategory.GroupTitle,
      filterCategoryTitle: 'Group Title',
      uiId: uuid(),
      filterCondition: clusterFilter.filterCondition,
      values: clusterFilter.clusters.map((clusterFilterInd) => {
        return {
          id: clusterFilterInd.id ?? -1,
          title: clusterFilterInd.title,
          uiId: uuid(),
        };
      }),
    });
  });
  filterInput.ownerFilter?.map((ownerFilter) => {
    filters.push({
      filterCategory: FilterCategory.Owner,
      filterCategoryTitle: 'Owner',
      uiId: uuid(),
      filterCondition: ownerFilter.filterCondition,
      values: ownerFilter.owner.map((ownerFilterInd) => {
        return {
          id: ownerFilterInd.user_id ?? -1,
          title: data?.getOrganizationUsers?.find((u) => u.user?.id === ownerFilterInd.user_id)?.email ?? '',
          uiId: uuid(),
        };
      }),
    });
  });
  filterInput.groupFilter?.map((groupFilter) => {
    if (groupFilter.group[0].id) {
      // if groupFilter has an id, then add a group title filter
      filters.push({
        filterCategory: FilterCategory.GroupTitle,
        filterCategoryTitle: 'Group Title',
        uiId: uuid(),
        filterCondition: groupFilter.filterCondition,
        values: groupFilter.group.map((groupFilterInd) => {
          return {
            id: groupFilterInd.id ?? -1,
            title: data?.getAllGroups?.find((g: any) => g.id === groupFilterInd.id)?.title ?? '',
            uiId: uuid(),
          };
        }),
      });
    } else {
      // else add a group type filter
      filters.push({
        filterCategory: FilterCategory.GroupType,
        filterCategoryTitle: 'Group Type',
        uiId: uuid(),
        filterCondition: groupFilter.filterCondition,
        values: groupFilter.group.map((groupFilterInd) => {
          return {
            uiId: uuid(),
            title: groupFilterInd.type ?? '',
            group: groupFilterInd.type,
            //I dont like this conditional here, but we have similar filter logic repeated in different places.
            //We should take care of that first to fix stuff like this.
            displayName: groupFilterInd.type === 'search' ? 'User Created' : 'Found by Unwrap',
          };
        }),
      });
    }
  });
  filterInput.minStarsFilter?.map((minStarsFilter) => {
    filters.push({
      filterCategory: FilterCategory.MinStars,
      filterCategoryTitle: 'Min Stars',
      uiId: uuid(),
      filterCondition: minStarsFilter.filterCondition,
      values: minStarsFilter.amounts.map((amount) => {
        return {
          id: amount,
          title: amount.toString(),
          uiId: uuid(),
          displayName: `${amount.toString()} ${'⭒'.repeat(amount)}`,
        };
      }),
    });
  });
  filterInput.maxStarsFilter?.map((maxStarsFilter) => {
    filters.push({
      filterCategory: FilterCategory.MaxStars,
      filterCategoryTitle: 'Max Stars',
      uiId: uuid(),
      filterCondition: maxStarsFilter.filterCondition,
      values: maxStarsFilter.amounts.map((amount) => {
        return {
          id: amount,
          title: amount.toString(),
          uiId: uuid(),
          displayName: `${amount.toString()} ${'⭒'.repeat(amount)}`,
        };
      }),
    });
  });
  filterInput.entryGroupedStatusFilter?.map((entryGroupedStatusFilter) => {
    filters.push({
      filterCategory: FilterCategory.EntryGroupedStatus,
      filterCategoryTitle: 'Entry Grouped Status',
      uiId: uuid(),
      filterCondition: entryGroupedStatusFilter.filterCondition,
      values: entryGroupedStatusFilter.grouped.map((groupedBool) => {
        return {
          title: groupedBool ? 'Grouped' : 'Ungrouped',
          uiId: uuid(),
          displayName: groupedBool ? 'Grouped' : 'Ungrouped',
        };
      }),
    });
  });
  filterInput.sourceUrlFilter?.map((sourceUrlFilter) => {
    filters.push({
      filterCategory: FilterCategory.SourceUrl,
      filterCategoryTitle: 'Source Url',
      uiId: uuid(),
      filterCondition: sourceUrlFilter.filterCondition,
      values: sourceUrlFilter.urls.map((url) => {
        return {
          title: url,
          uiId: uuid(),
        };
      }),
    });
  });
  filterInput.groupExternalTicketsFilter?.map((groupExternalTicketsFilter) => {
    filters.push({
      filterCategory: FilterCategory.GroupExternalTicketsStatus,
      filterCategoryTitle: 'Group Action Items',
      uiId: uuid(),
      filterCondition: groupExternalTicketsFilter.filterCondition,
      values: groupExternalTicketsFilter.status.map((status) => {
        return {
          title: status,
          uiId: uuid(),
          externalTicketStatus: status,
        };
      }),
    });
  });
  setFiltersShown(filters);
};
