import { useContext, useEffect, useState } from 'react';
import _, { Dictionary } from 'lodash';
import { FilterHook } from './FilterHook';
import { ArrayElement } from '../../utilities';
import {
  FeedbackEntriesQuery,
  Feedback_Integration_Type,
  Integration_Category,
  useGenerateGptRepliesLazyQuery,
  useIntegrationsQuery,
  useReplyToReviewsMutation,
} from '../../generated/graphql';
import { FeedbackHook } from './FeedbackHook';
import AppContext from '../../v2/contexts/AppContext';
import { writeToastError } from '../../v2/hooks/GroupHook';
import { deepEqual } from 'assert';
export interface Reply {
  text: string;
  error: string;
  success?: boolean;
  submitter?: string;
  sent?: boolean;
}

export const replyMap: Record<string, string> = {
  'google play': 'gplay',
  'apple app store': 'appstore',
};
export const useReplyHook = ({
  filterHook,
  feedbackHook,
  initialReply,
}: {
  filterHook?: FilterHook;
  feedbackHook?: FeedbackHook;
  initialReply?: ArrayElement<FeedbackEntriesQuery['entries']>;
}) => {
  const [generateGPT] = useGenerateGptRepliesLazyQuery({ fetchPolicy: 'no-cache' });
  //Why are we not using useValidTeamAppContext here? Need to check and change if necessary...
  const { curTeamId: teamId, curOrgId: orgId } = useContext(AppContext);
  const { data } = useIntegrationsQuery({
    fetchPolicy: 'no-cache',
    variables: { teamId: teamId ?? -1, orgId: orgId ?? -1, feedbackIntegrationType: Feedback_Integration_Type.DataImport },
  });
  const validIntegrations =
    data?.integrations
      ?.filter((i) => i.requirements.some((r) => r.category === Integration_Category.Reply) && i.requirements.every((r) => r.value.length > 0))
      .map((i) => i.title.toLowerCase())
      .map((i) => (replyMap[i] as string) ?? i) ?? [];

  const [replies, setReplies] = useState<Dictionary<Reply>>({
    ...(feedbackHook
      ? feedbackHook.entries.reduce((acc, entry) => {
          if (entry?.conversationParts?.length === 0) return acc;
          acc[entry.id] = {
            text: entry.conversationParts[0].fullText ?? '',
            error: '',
            success: true,
            submitter: entry.conversationParts[0].submitter ?? 'Reply',
            sent: true,
          };
          return acc;
        }, {} as Dictionary<Reply>)
      : {}),
    ...(initialReply && initialReply?.conversationParts?.length !== 0
      ? {
          [initialReply.id]: {
            text: initialReply.conversationParts[0].fullText ?? '',
            error: '',
            success: true,
            submitter: initialReply.conversationParts[0].submitter ?? 'Reply',
            sent: true,
          },
        }
      : {}),
  });
  const [generatingGeneralReplies, setGeneratingGeneralReplies] = useState(false);
  const [replyToReviews] = useReplyToReviewsMutation();
  useEffect(() => {
    // set initial replies to be feedback entries conversation parts

    if (feedbackHook?.entries) {
      const newReplies = feedbackHook.entries.reduce((acc, entry) => {
        if (!entry.conversationParts || entry.conversationParts.length === 0) return acc;
        acc[entry.id] = {
          text: entry.conversationParts[0].fullText ?? '',
          error: '',
          success: true,
          submitter: entry.conversationParts[0].submitter ?? 'Reply',
          sent: true,
        };
        return acc;
      }, {} as Dictionary<Reply>);

      if (_.isEqual(replies, newReplies) === false) {
        setReplies({ ...newReplies });
      }
    }
  }, [filterHook?.filters, feedbackHook?.entries]);
  const sendIndividualReply = async (entryId: number, text: string, cb?: () => void) => {
    if (!teamId) return;
    if (text.length === 0) return;
    try {
      const result = await replyToReviews({ variables: { replyInput: [{ entryId, text }], teamId } });
      if (result.errors) {
        const error = result.errors[0].message;
        setReplies({ ...replies, [entryId]: { text: '', error, success: false } });
        writeToastError('There was an error sending the reply. Please try again.');
      } else {
        const reply = result.data?.replyToReviews[0];
        setReplies({
          ...replies,
          [entryId]: {
            text: reply?.conversationPart?.fullText,
            error: reply?.errorMessage,
            success: reply?.success,
            submitter: reply?.conversationPart?.userId ? 'Sent from Unwrap' : 'Agent',
            sent: true,
          },
        });
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        writeToastError('There was an error sending replies. Please try again.');
      }
    }
    cb?.();
  };
  const sendAllReplies = async (cb?: () => void) => {
    if (!teamId) return;
    const repliesToSend = Object.entries(replies)
      .map(([id, reply]) => ({ entryId: Number(id), text: reply.text }))
      .filter((reply) => reply.text.length > 0);
    try {
      const result = await replyToReviews({ variables: { replyInput: repliesToSend, teamId } });
      if (result.data?.replyToReviews) {
        const newReplies = result.data.replyToReviews.reduce((acc, reply) => {
          acc[reply.feedbackEntryId] = {
            text: reply.conversationPart?.fullText ?? '',
            error: reply.errorMessage ?? '',
            success: reply.success,
            submitter: reply?.conversationPart?.userId ? 'Sent from Unwrap' : 'Agent',
            sent: true,
          };
          return acc;
        }, {} as Dictionary<Reply>);
        setReplies({ ...replies, ...newReplies });
      }

      // if there were any errors outside the errorMessage flag, we just toast

      if (result.errors) {
        writeToastError('There was an error sending some replies. Please try again.');
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        writeToastError('There was an error sending replies. Please try again.');
      }
    }
    cb?.();
  };

  const handleGenerateReply = async (text: string, entry: ArrayElement<FeedbackEntriesQuery['entries']>) => {
    if (!teamId) return;
    const { data, error } = await generateGPT({
      variables: { teamId, gptRepliesInput: [{ entryId: entry.id, feedbackText: entry.text ?? '', details: text ?? '' }] },
    });
    const reply = data?.generateGptReplies?.[0];
    if (!reply || error) setReplies({ ...replies, [entry.id]: { text: '', error: 'There was an error generating a reply.' } });
    else {
      setReplies({ ...replies, [entry.id]: { text: data?.generateGptReplies?.[0]?.generatedText ?? '', error: '' } });
    }
  };
  const handleCopyReplies = (text: string) => {
    processReplies(() => text);
  };

  const handleGenerateReplies = async (text: string) => {
    setGeneratingGeneralReplies(true);
    if (!teamId) return;
    await processReplies(async (entry) => {
      const { data } = await generateGPT({
        variables: { teamId, gptRepliesInput: [{ entryId: entry.id, feedbackText: entry.text ?? '', details: text ?? '' }] },
      });
      const reply = data?.generateGptReplies?.[0];

      return reply?.generatedText ?? '';
    });

    setGeneratingGeneralReplies(false);
  };

  const processReplies = async (handlerFunction: (entry: ArrayElement<FeedbackEntriesQuery['entries']>) => string | Promise<string>) => {
    if (!feedbackHook) return;
    const newDict: Dictionary<Reply> = replies;
    for (let i = 0; i < feedbackHook.entries.length; i++) {
      const entry = feedbackHook.entries[i];
      if (replies[entry.id]?.sent) {
        newDict[entry.id] = replies[entry.id];
        continue;
      }

      try {
        const result = handlerFunction(entry);
        if (newDict[entry.id]?.success) return;
        if (typeof result === 'string') {
          newDict[entry.id] = { ...newDict[entry.id], text: result, error: '', success: false, sent: false };
        } else {
          const resolvedResult = await result;
          newDict[entry.id] = { ...newDict[entry.id], text: resolvedResult, error: '', success: false, sent: false };
        }
      } catch (e: any) {
        if (e instanceof Error) {
          newDict[entry.id] = { ...newDict[entry.id], text: '', error: e.message, success: false, sent: false };
        }
      }
      setReplies({ ...newDict });
    }
  };

  return {
    replies,
    validIntegrations,
    handleCopyReplies,
    handleGenerateReplies,
    handleGenerateReply,
    generatingGeneralReplies,
    sendIndividualReply,
    sendAllReplies,
  };
};
