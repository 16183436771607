import Button, { ButtonVariant } from '../../baseComponents/Button';
import toast from 'react-hot-toast';
import {
  FilterInput,
  GroupDataFragment,
  useAlertGroupFailedMutation,
  useCreateGroupIncludeDataMutation,
  useGetGroupLazyQuery,
} from '../../../generated/graphql';
import { useValidTeamAppContext } from '../../../v2/contexts/AppContext';
import { useContext, useState } from 'react';
import { SearchPreviewContext } from '../../context/SearchPreviewContext';
import { TaxonomyDispatchContext } from '../../context/TaxonomyDispatchContext';
import { SearchPreviewDispatchContext } from '../../context/SearchPreviewDispatchContext';
import { logError } from '../../../applicationTelemetry';

export function BuildGroupButton(props: { filterInput: FilterInput }) {
  // const [createGroupMutation] = useCreateGroupMutation({});
  const [alertGroupFailed] = useAlertGroupFailedMutation({});
  const [getGroupFull] = useGetGroupLazyQuery({});

  const { curTeamId: teamId } = useValidTeamAppContext();
  const { excludeFromNewFilter } = useContext(SearchPreviewContext);
  const searchTerm = useContext(SearchPreviewContext).searchTerm;
  const taxonomyDispatch = useContext(TaxonomyDispatchContext);
  const searchPreviewDispatch = useContext(SearchPreviewDispatchContext);
  const [loading, setLoading] = useState(false);

  const [createGroupMutation, _] = useCreateGroupIncludeDataMutation({});

  const executeSearchQuery = (group: GroupDataFragment) => {
    if (group.isExactMatch) {
      getGroupFull({
        variables: { teamId, groupId: group.id, belongs: true, filterInput: props.filterInput },
        onCompleted: (data) => {
          toast.success(`Successfully created search group for ${data.getGroup.title}`);
          const finalGroup = data.getGroup;
          taxonomyDispatch({
            type: 'addGroup',
            payload: { group: finalGroup },
          });
          searchPreviewDispatch({ type: 'setSearchInput', payload: { searchInput: '' } });
          searchPreviewDispatch({ type: 'setSearchTerm', payload: { searchTerm: '' } });
        },
      });
    } else if (group.groupEntries?.every((entry) => !entry.belongsToGroup)) {
      searchPreviewDispatch({ type: 'setSearchInput', payload: { searchInput: '' } });
      searchPreviewDispatch({ type: 'setSearchTerm', payload: { searchTerm: '' } });
      toast.error('No sentences found for this search. Please try a different search.');
    } else {
      searchPreviewDispatch({ type: 'createGroup', payload: { group: group } });
    }
  };

  const buildGroupToPreview = async (searchInput: string) => {
    let breakCircuit = false;
    let errorCount = 0;
    let errorThrown = false;
    while (!breakCircuit) {
      try {
        const result = await createGroupMutation({
          variables: {
            teamId,
            queryString: searchInput,
            excludeFromNewFilter,
          },
        });

        errorThrown = (result.errors ?? []).length >= 1;

        if (result.data?.createGroup) {
          breakCircuit = true;
          executeSearchQuery(result.data.createGroup);
        }
      } catch (e) {
        // we have to explicitly catch the error here because any 500 errors don't show up in the error object from the mutation, you'd expect the apollo client to handle this but no it doesn't
        // I do wonder if this is something to do with our latencyTracker middleware on the window.fetch method.
        errorThrown = true;
        logError(e);
      }

      if (errorThrown) {
        errorCount += 1;
        // I want to know every time a preview fails. If a user has to wait more than 30 seconds for the preview to complete, that's a problem and we need to know about it.
        await alertGroupFailed({ variables: { teamId, queryString: searchInput, wasRetried: errorCount > 1 } });
        toast.error('Sorry, this is taking a little longer than normal.');
        if (errorCount > 1) {
          breakCircuit = true;
          toast.error("We're sorry, but we were unable to create a group for this search. Please try again later.");
        }
      }
    }
    setLoading(false);
  };

  return (
    <div className={'mb-2'}>
      <Button
        data-testid="build-new-group"
        disabled={loading}
        id={'build-new-group'}
        variant={ButtonVariant.Primary}
        text={'Didn’t find what you want? Build a new Group'}
        loadingText={'Building Group Preview....'}
        loadingConfirm={loading}
        className="text-sm mb-2"
        onClick={() => {
          buildGroupToPreview(searchTerm);
          setLoading(true);
        }}
      />
    </div>
  );
}
