import { useEditFeedbackEntrySentimentMutation } from '../../generated/graphql';

export const useEditSentimentHook = () => {
  const [editSentimentMutation] = useEditFeedbackEntrySentimentMutation();
  const handleEditSentiment = (teamId: number, entryId: number, sentiment: number) => {
    editSentimentMutation({
      variables: { entryId, teamId, sentiment },
      update: (cache, data) => {
        cache.modify({
          id: `feedback_entries:${entryId}`,
          fields: {
            sentiment: () => data.data?.editFeedbackEntrySentiment?.sentiment ?? null,
          },
        });
      },
    });
  };

  return { handleEditSentiment };
};
