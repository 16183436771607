import { Combobox } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/24/outline';
import { ChevronDownIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { useState, RefObject, useRef } from 'react';
import AdjustableLoadingIcon from '../../baseComponents/AdjustableLoadingIcon';
import { classNames } from '../../v2/util';

import { IDropDownItem } from './DropDown';

interface OwnerComboBoxProps {
  comboBoxData: IDropDownItem[];
  selectedItem?: IDropDownItem;
  setSelectedItem: (item: IDropDownItem | undefined) => void;
  placeholder?: string;
  defaultOption?: boolean;
  loading?: boolean;
  boxRef?: RefObject<HTMLDivElement> | null;
  styleLikeDropdown?: boolean;
}

export const OwnerComboBox = ({
  comboBoxData,
  selectedItem,
  setSelectedItem,
  placeholder,
  defaultOption,
  boxRef,
  loading,
  styleLikeDropdown,
}: OwnerComboBoxProps): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [query, setQuery] = useState<string>('');
  const [hover, setHover] = useState<boolean>(false);
  const filteredItems =
    query === ''
      ? comboBoxData
      : comboBoxData.filter((data) => {
          return data.name ? data.name.toLowerCase().includes(query.toLowerCase()) : data.title?.toLowerCase().includes(query.toLowerCase());
        });

  return (
    <Combobox
      as="div"
      value={selectedItem}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => setHover(false)}
      onChange={(item: IDropDownItem | undefined) => {
        setSelectedItem(item);
        setQuery('');
        inputRef?.current?.blur();
      }}
      className="hover:bg:white w-full bg-transparent"
      ref={boxRef}
    >
      <div className="relative w-full">
        <Combobox.Input
          ref={inputRef}
          placeholder={selectedItem?.name ? selectedItem.name : selectedItem?.displayName ? selectedItem.displayName : ''}
          id="combo-box-input"
          data-testid="combo-box-input"
          className={classNames(
            `w-full bg-gray-100 text-blueberry placeholder-blueberry ${
              hover ? 'border border-blueberry bg-gray-200' : ''
            }  rounded-md border py-1.5 pl-3 pr-14 transition-colors  focus:border-blueberry focus:outline-none focus:ring-1 focus:ring-blueberry sm:text-sm`,
            styleLikeDropdown ? 'border border-gray-300' : 'border-none'
          )}
          onChange={(event) => {
            setQuery(event.target.value);
          }}
          displayValue={() => ''}
        />
        <div className="absolute top-0 flex h-full w-full flex-row justify-end">
          <Combobox.Button className="flex w-full"></Combobox.Button>
          <div className="flex items-center">
            {loading ? (
              <AdjustableLoadingIcon width={4} height={4} />
            ) : selectedItem && selectedItem.displayName !== 'None' ? (
              <XMarkIcon
                onClick={() => {
                  setSelectedItem(undefined);
                  setQuery('');
                  inputRef?.current?.blur();
                }}
                className="h-4 w-4 cursor-pointer text-blueberry"
                aria-hidden="true"
              />
            ) : null}
          </div>
          <Combobox.Button className="flex items-center  pr-2">
            <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
          </Combobox.Button>
        </div>

        {filteredItems.length > 0 && (
          <Combobox.Options className="absolute right-0 z-10 mt-1 max-h-60 min-w-full overflow-auto rounded-md bg-white py-1 text-base text-blueberry shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredItems.map((item) => (
              <Combobox.Option
                key={item.id}
                value={item}
                className={({ active }) =>
                  classNames('relative cursor-pointer select-none py-2 pl-3 pr-9', active ? 'bg-blueberry text-white' : 'text-gray-900')
                }
              >
                {({ active, selected }) => (
                  <>
                    <span className={classNames('block', selected && 'font-semibold')}>{item.displayName}</span>
                    <span className="text-gray-400">{item.name}</span>

                    {selected && (
                      <span className={classNames('absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-blueberry')}>
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
};
