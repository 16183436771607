import { Dispatch, SetStateAction, useState } from 'react';
import { Dialog } from '@headlessui/react';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import Modal from '../../baseComponents/Modal';
import Form from '../../../baseComponents/Form';
import Button, { ButtonSize, ButtonVariant } from '../../baseComponents/Button';

interface YesCancelModalProps {
  text?: string;
  subtext?: string;
  confirmText?: string;
  modalOpen: boolean;
  callbackModal: Dispatch<SetStateAction<boolean>>;
  confirmButton?: () => Promise<void> | void;
  loadingConfirm?: boolean;
  // this isn't used anywhere
  idToPass?: number;
  closeOnConfirm?: boolean;
  understandCheck?: boolean;
}

export default function YesCancelModal({
  text,
  subtext,
  confirmText,
  modalOpen,
  callbackModal,
  confirmButton,
  loadingConfirm,
  closeOnConfirm,
  understandCheck,
}: YesCancelModalProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [check, setCheck] = useState(false);
  const onClickOk = async () => {
    setIsLoading(true);
    confirmButton && (await confirmButton());
    setIsLoading(false);
    closeOnConfirm && callbackModal(false);
  };
  const getFormButtons = () => {
    return (
      <div className="mt-4 flex flex-row justify-between gap-x-4 text-center">
        <Button variant={ButtonVariant.Tertiary} onClick={() => callbackModal(false)} text="Cancel"></Button>
        <Button
          variant={ButtonVariant.Primary}
          text={confirmText}
          disabled={loadingConfirm || isLoading || (understandCheck && !check)}
          onClick={() => {
            onClickOk();
          }}
          loadingConfirm={loadingConfirm}
        ></Button>
      </div>
    );
  };
  return (
    <Modal open={modalOpen} setOpen={callbackModal}>
      <div>
        <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full ">
          <ExclamationCircleIcon className="h-8 w-8 text-raspberry" aria-hidden="true" />
        </div>
        <div className="mt-3 text-center sm:mt-5">
          <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
            {text}
          </Dialog.Title>
        </div>
        <div className="mt-3 text-center sm:mt-5">
          <Dialog.Title as="h4" className="text-md font-normal leading-6 text-gray-900">
            {subtext}
          </Dialog.Title>
        </div>
        {understandCheck && (
          <div className="mt-3 flex flex-row items-center justify-center sm:mt-5 cursor-default" onClick={() => setCheck((prev) => !prev)}>
            <input type="checkbox" checked={check} />
            <p className="text-md ml-2">I understand.</p>
          </div>
        )}

        <Form
          bottomRow={getFormButtons()}
          onSubmit={(e) => {
            e.preventDefault();
            onClickOk();
          }}
        ></Form>
      </div>
    </Modal>
  );
}
