import { useEffect, useState, useContext } from 'react';
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import IntegrationsPageV3 from '../v3/pages/IntegrationsPage';
import { ProfilePage as ProfilePageV3 } from '../v3/pages/ProfilePage';
import AdminPage from '../v3/pages/AdminPage';
import OrganizationPageV3 from '../v3/pages/OrganizationsPage';
import InvalidLinkPage from './pages/InvalidLinkPage';
import DeniedAccessPage from './pages/DeniedAccessPage';
import toast, { ToastBar, Toaster } from 'react-hot-toast';
import { useTeamFlagsLazyQuery, useGetOrganizationsLightLazyQuery, useTeamDefaultsLazyQuery } from '../generated/graphql';
import UserContext, { IUser } from './contexts/UserContext';
import { useIsMount } from './util';
import { AnalyticsTracking } from './AnalyticsUtil';
import { useOrganizationHook } from './hooks/OrganizationHook';
import DashboardLayout from '../v3/layouts/DashboardLayout/DashboardLayout';
import { FeedbackPage as FeedbackPageV3 } from '../v3/pages/FeedbackPage';
import { AlertsPage as AlertsPageV3 } from '../v3/pages/AlertsPage';
import { AppRoutes, V3_DASHBOARD } from '../Routes';
import AppContext from './contexts/AppContext';
import { ChartsPage } from '../v3/pages/ChartsPage';
import { ReplyingPage } from '../v3/pages/ReplyingPage';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { useReplyHook } from '../v3/hooks/ReplyHook';
import DigestPage from '../v3/pages/DigestPage';
import { ExplorePageRouter } from '../v3/pages/explore/ExplorePageRouter';
import HomePageRouter from '../v3/pages/home/HomePageRouter';
import { HomePageWithNoData } from '../v3/pages/home/HomePageWithNoData';
import { FeedbackAssistantPage } from '../v3/pages/FeedbackAssistantPage';

/**
 * This is the Router for our logged-in-user accessible App Pages
 *
 * @returns
 */
export default function DashboardV2() {
  const [customerFacingAlertOpen, setCustomerFacingAlertOpen] = useState(false);
  const { identifyUser } = AnalyticsTracking();
  const { user } = useContext(UserContext);
  const {
    currentTeam,
    curOrgId,
    currentOrg,
    curTeamId,
    setTeamAndOrgFromUrlOrLocalStorage,
    setCurOrgId,
    setCurTeamId,
    orgsHaveLoaded,
    updateTeamDefaultValues,
  } = useContext(AppContext);
  const [fetchOrgs, _] = useGetOrganizationsLightLazyQuery({});
  const { addTeam, addOrganization } = useOrganizationHook();
  const { validIntegrations } = useReplyHook({});

  const navigate = useNavigate();

  const redirectToAccessRequested = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    urlSearchParams.set('destination', window.location.pathname);
    setCurOrgId(null);
    setCurTeamId(null);
    navigate({ pathname: `/${V3_DASHBOARD}${AppRoutes.denied}`, search: urlSearchParams.toString() });
  };

  useEffect(() => {
    fetchOrgs({
      onCompleted: (data) => {
        //You can pass the orgList to set it from this function
        setTeamAndOrgFromUrlOrLocalStorage({
          orgList: data.getOrganizations ?? [],
          invalidTeamCallback: redirectToAccessRequested,
          invalidOrgCallback: redirectToAccessRequested,
        });
      },
    });
  }, []);

  const isFirstRender = useIsMount();

  useEffect(() => {
    identifyUser();
  }, []);

  const flagsLazyQuery = useTeamFlagsLazyQuery();
  const [getFlagsPromise, flags] = flagsLazyQuery;

  useEffect(() => {
    if (curTeamId && curOrgId && !isFirstRender) {
      getFlagsPromise({
        notifyOnNetworkStatusChange: true, //This updates the loading property on refetchs
        variables: { teamId: curTeamId },
      });
    }
  }, [curTeamId]);

  useEffect(() => {
    if (currentOrg && user?.isUnwrapper && currentOrg?.isCustomerFacing) {
      setCustomerFacingAlertOpen(true);
    } else {
      setCustomerFacingAlertOpen(false);
    }
  }, [currentOrg]);

  const [getTeamDefaults] = useTeamDefaultsLazyQuery({});
  useEffect(() => {
    if (!curTeamId || !!currentTeam?.defaultValues?.oldestFeedbackDate) return;
    getTeamDefaults({
      variables: { teamId: curTeamId },
      onCompleted(data) {
        updateTeamDefaultValues(curTeamId, data.teamDefaults);
      },
    });
  }, [curTeamId]);

  return (
    <div>
      {
        <>
          <Toaster
            position="top-right"
            toastOptions={{
              duration: 4000,
              className: '',
              style: {
                border: '1px solid white',
                fontFamily: 'sofiapro',
                backgroundColor: '#292E5B',
                zIndex: 1000,
                color: 'white',
                borderRadius: '1rem',
                minWidth: '8%',
              },
            }}
          >
            {(t) => (
              <ToastBar toast={t}>
                {({ icon, message }) => (
                  <div className="flex flex-row">
                    {icon}
                    {message}
                    {t.type !== 'loading' && (
                      <button className="px-1 pt-0.5" onClick={() => toast.dismiss(t.id)}>
                        <XMarkIcon className="w-4 h-4 outline-2" />
                      </button>
                    )}
                  </div>
                )}
              </ToastBar>
            )}
          </Toaster>
          <DashboardLayout
            customerFacingAlertOpen={customerFacingAlertOpen}
            setCustomerFacingAlertOpen={setCustomerFacingAlertOpen}
            flags={flags}
            addTeam={addTeam}
            addOrganization={addOrganization}
          >
            <Routes>
              <Route path="/*" element={<Navigate to={AppRoutes.v3FullPath.home} />} />

              <Route path={`${AppRoutes.v3Pages.home}/*`} element={curOrgId && curTeamId ? <HomePageRouter pageName="Home Page" /> : <HomePageWithNoData />} />
              {/* need to check if user is unwrapper */}
              {user?.isUnwrapper && <Route path={AppRoutes.v3Pages.admin} element={<AdminPage />} />}
              <Route
                path={`${AppRoutes.v3Pages.explore}/*`}
                element={curOrgId && curTeamId ? <ExplorePageRouter pageName="Explore Page" /> : <NavigateToHome />}
              />
              <Route
                path={AppRoutes.v3Pages.integrations}
                element={curOrgId && curTeamId ? <IntegrationsPageV3 pageName="Integrations Page" /> : <NavigateToHome />}
              />
              <Route path={AppRoutes.v3Pages.feedback} element={curOrgId && curTeamId ? <FeedbackPageV3 pageName="Feedback Page" /> : <NavigateToHome />} />
              <Route
                path={AppRoutes.v3Pages.replying}
                element={
                  curOrgId && curTeamId && (validIntegrations.length > 0 || user?.isUnwrapper) ? (
                    <ReplyingPage pageName="Replying Page" />
                  ) : (
                    <Navigate to={`${AppRoutes.v3FullPath.feedback}${window.location.search}`} replace />
                  )
                }
              />
              <Route path={AppRoutes.v3Pages.alerts} element={curOrgId && curTeamId ? <AlertsPageV3 /> : <NavigateToHome />} />
              <Route path={`${AppRoutes.v3Pages.charts}/*`} element={curOrgId && curTeamId ? <ChartsPage pageName="Charts Page" /> : <NavigateToHome />} />
              <Route path={`${AppRoutes.v3Pages.organizations}/*`} element={orgsHaveLoaded && <OrganizationPageV3 pageName={'Organizations'} />} />
              <Route path={AppRoutes.v3Pages.invalid} element={<InvalidLinkPage />} />
              <Route path={AppRoutes.denied} element={<DeniedAccessPage />} />
              <Route path={AppRoutes.profile} element={<ProfilePageV3 />} />
              <Route path={AppRoutes.v3Pages.assistant} element={<FeedbackAssistantPage/>} />
              <Route element={<Navigate to={AppRoutes.v3FullPath.invalid} />} />

              {createUnwrapperRoutes({ user })}
            </Routes>
          </DashboardLayout>
        </>
      }
    </div>
  );
}

/**
 * This route checks if user is an unwrapper and if so adds the element and path to the route
 * @param user
 * @param element
 * @returns
 */
const createUnwrapperRoutes = (props: { user: IUser | undefined }): JSX.Element[] => {
  if (props.user?.isUnwrapper) {
    return [<Route element={<AdminPage />} path={AppRoutes.v3Pages.admin} />, <Route element={<DigestPage />} path={AppRoutes.v3Pages.digest} />];
  }
  return [];
};

const NavigateToHome = () => <Navigate to={`${AppRoutes.v3FullPath.home}`} replace />;
