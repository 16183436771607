import { Fragment, useContext, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon, Square3Stack3DIcon, BellAlertIcon, Squares2X2Icon, MagnifyingGlassIcon, ChartBarSquareIcon } from '@heroicons/react/24/outline';
import {
  Square3Stack3DIcon as Square3Stack3DIconSolid,
  BellAlertIcon as BellAlertIconSolid,
  Squares2X2Icon as Squares2X2IconSolid,
  MagnifyingGlassIcon as MagnifyingGlassIconSolid,
  ChartBarSquareIcon as ChartBarSquareIconSolid,
} from '@heroicons/react/24/solid';
import { classNames } from '../../v2/util';
import unwrapLightLogo from '../../assets/unwrap_full_logo_2_MAIN.svg';
import moment from 'moment';
import PreviewFiltersForClusterization from '../../v2/components/PreviewPage/PreviewFiltersForClusterization';
import {
  Date_Window,
  Group_Status,
  Sentence,
  useAddWidgetFeedbackMutation,
  useCreatePreviewPageLeadMutation,
  useGetPreviewPageLeadQuery,
  useTeamDefaultsQuery,
  useTeamsQuery,
} from '../../generated/graphql';
import AppContext from '../../v2/contexts/AppContext';
import { PreviewModalOrigin } from '../../v2/AnalyticsUtil';
import sourcesMap from '../../v2/other/sourcesMap';
import { useExploreHook } from '../../v2/hooks/ExploreHook';
import { SmallWhiteSpinner } from '../../v2/components/SmallWhiteSpinner';
import { SearchGroupDualLists } from '../../v2/components/SearchGroupDualLists';
import GroupCard from '../components/GroupCard';
import LoadingSpinner from '../../v2/components/LoadingSpinner';
import GetStartedModal from '../../v2/components/Modals/GetStartedModal';
import EnterEmailModal from '../../v2/components/Modals/EnterEmailModal';
import { Events, logEvent } from '../../v2/AnalyticsUtil';
import { useExitIntent } from '../../v2/hooks/ExitIntent';
import PreviewExitSurvey from '../../v2/components/Modals/PreviewExitSurvey';
import { useExploreGroupHook } from '../hooks/ExploreGroupHook';
import { DateOptions } from '../sections/Filters/FilterManager';
import { DenominatorOptions, denominatorOptionsDropdown } from '../sections/Filters/FiltersTypes';
import { GroupFull } from '../../v2/hooks/GroupHook';
const isLocalhost = window.location.hostname === 'localhost';
const navigation = [
  { name: 'Key Insights', href: '#', icon: Square3Stack3DIcon, selectedIcon: Square3Stack3DIconSolid, current: true },
  { name: 'Explore', href: '#', icon: MagnifyingGlassIcon, selectedIcon: MagnifyingGlassIconSolid, current: false },
  { name: 'Charts', href: '#', icon: ChartBarSquareIcon, selectedIcon: ChartBarSquareIconSolid, current: false },
  { name: 'Alerts', href: '#', icon: BellAlertIcon, selectedIcon: BellAlertIconSolid, current: false },
  {
    name: 'Integrations',
    href: '#',
    icon: Squares2X2Icon,
    selectedIcon: Squares2X2IconSolid,
    current: false,
    innerMenu: [
      ...Object.keys(sourcesMap).map((key, idx) => {
        return { name: sourcesMap[key].name, img: sourcesMap[key].logo, key: key };
      }),
      { name: '+100s more', img: null, key: null },
    ],
  },
];
export default function PreviewPage() {
  const urlParams = new URLSearchParams(window.location.search);
  const paramsTeamId = urlParams.get('team_id');
  const paramsEmail: string | null = urlParams.get('email') ?? null;
  const paramsName: string | null = urlParams.get('name') ?? null;
  const [demoRequested, setDemoRequested] = useState(false);
  const [demoModalOpen, setDemoModalOpen] = useState(false);
  const [exitModalOpen, setExitModalOpen] = useState(false);
  const [emailModalOpen, setEmailModalOpen] = useState(paramsEmail == null);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [startDate, setStartDate] = useState(moment().subtract(90, 'days').startOf('day').toDate());
  const originalStartDate = moment().subtract(90, 'days').startOf('day').toDate();
  const [endDate, setEndDate] = useState(moment().endOf('day').toDate());
  const [showAfterSubmitMessage, setShowAfterSubmitMessage] = useState(false);
  const [showAfterSubmitFeedbackMessage, setShowAfterSubmitFeedbackMessage] = useState(false);
  const { app } = useContext(AppContext);
  const [searchInput, setSearchInput] = useState<string>('');
  const [filters, setFilters] = useState({ startDate, endDate });

  const { groups, addGroup, replaceOrAddToSearchGroups, updateProgress, loadAllSentences, loadingStatuses, loadListView } = useExploreGroupHook({
    teamId: -1,
    orgId: -1,
    teamName: 'Preview Page',
    orgName: 'previewPage',
    pageName: 'Preview Landing Page',
    filterInput: filters,
    sentenceFilterInput: filters,
    status: Group_Status.Monitored,
    pageSize: 3,
    teamUuid: paramsTeamId ?? '',
    email: paramsEmail ?? '',
    selectedDenominator: denominatorOptionsDropdown.find((d) => d.name === DenominatorOptions.DateFilteredFeedback),
  });

  useExitIntent(() => setExitModalOpen(true), !demoRequested && !loadingStatuses.fetchingGroups && (paramsEmail ? true : false));

  const exploreHook = useExploreHook({ teamId: -1, orgId: -1, addGroup, email: paramsEmail ?? '' });
  useEffect(() => {
    loadListView(-1, filters, paramsTeamId ?? '');
  }, []);
  const {
    loading: teamLoading,
    error: teamError,
    data: teamData,
  } = useTeamsQuery({
    variables: { teamsId: -1, uuid: app?.currentUuid },
    onCompleted: (data) => {
      if (!data.teams) {
        window.location.href = 'https://unwrap.ai';
        return;
      } else {
        !isLocalhost &&
          logEvent(Events.PreviewPageLoaded, {
            View_ID: teamData!.teams!.id,
            View_Name: teamData!.teams!.name,
            Name: paramsName ?? '',
            Email: paramsEmail ?? '',
            Page: 'Preview Page',
          });
      }
    },
  });
  const { loading: loadingTeamDefaults } = useTeamDefaultsQuery({
    variables: { teamId: -1, teamUuid: app?.currentUuid },
    onCompleted(data) {
      if (data.teamDefaults.exploreDefaultWindow) {
        const dateOption = DateOptions.find((option) => option.date_window === data.teamDefaults.exploreDefaultWindow);
        if (dateOption) {
          if (dateOption.date_window === Date_Window.All) {
            const newStartDate = moment(data.teamDefaults.oldestFeedbackDate).startOf('day').toDate();
            const newEndDate = moment(dateOption.endDate).endOf('day').toDate();
            setStartDate(newStartDate);
            setEndDate(newEndDate);
            setFilters({ ...filters, startDate: newStartDate, endDate: newEndDate });
          } else {
            const newStartDate = moment(dateOption.startDate).startOf('day').toDate();
            const newEndDate = moment(dateOption.endDate).endOf('day').toDate();
            setStartDate(newStartDate);
            setEndDate(newEndDate);
            setFilters({ ...filters, startDate: newStartDate, endDate: newEndDate });
          }
        }
      } else {
        if (moment(data.teamDefaults.oldestFeedbackDate).isAfter(moment(originalStartDate))) {
          const date = moment(data.teamDefaults.oldestFeedbackDate).toDate();
          setStartDate(date);
          setFilters({ ...filters, startDate: moment(date).startOf('day').toDate() });
        }
      }
    },
  });
  const [sources, setSources] = useState<string[]>([]);
  const [addFeedback] = useAddWidgetFeedbackMutation({});
  const [createLeadMutation, _] = useCreatePreviewPageLeadMutation({});
  const { data: leadData } = useGetPreviewPageLeadQuery({ variables: { email: paramsEmail } });
  useEffect(() => {
    if (sources.length === 0) {
      const unique = [
        ...Array.from(new Set(groups.flatMap((group) => group.entries.map((entry) => entry.source).filter((source): source is string => !!source)))),
      ];
      setSources(unique);
    }
  }, [groups]);
  useEffect(() => {
    if (leadData?.getPreviewPageLead) {
      setDemoRequested(true);
    }
  }, [leadData]);

  if (!paramsTeamId) {
    window.location.href = 'https://unwrap.ai';
    return <></>;
  }
  const clickOnModalOpener = (originatedBy: PreviewModalOrigin) => {
    setDemoModalOpen(true);
    !isLocalhost &&
      logEvent(originatedBy, {
        View_ID: teamData!.teams!.id,
        View_Name: teamData!.teams!.name,
        Name: paramsName ?? '',
        Email: paramsEmail ?? '',
        Page: 'Preview Page',
      });
  };

  const submitData = async (email: string, fullName: string) => {
    await createLeadMutation({
      variables: {
        email,
        name: fullName,
      },
      onError: (err) => {
        console.error('Error in createLeadMutation', err);
      },
    });
    setDemoRequested(true);
    setShowAfterSubmitMessage(true);
    !isLocalhost &&
      logEvent(Events.RequestDemo, {
        View_ID: teamData!.teams!.id,
        View_Name: teamData!.teams!.name,
        Name: paramsName ?? '',
        Email: paramsEmail ?? '',
        Page: 'Preview Page',
      });
  };

  const submitSurvey = async (choice: string) => {
    addFeedback({
      variables: {
        teamId: 1096, // internal feedback team
        email: paramsEmail ?? '',
        name: paramsName ?? '',
        feedback: choice,
      },
    });
    setShowAfterSubmitFeedbackMessage(true);
  };

  const onExecuteQuery = () => {
    if (!searchInput.length) return;
    exploreHook.executeSearchQuery(searchInput);
  };
  const onBuildGroup = () => exploreHook.buildGroup(() => setSearchInput(''));
  const onConfirmSearch = () => !exploreHook.querySearchInfo.loading && onExecuteQuery();
  const setEmailUrlParams = (email: string, name: string) => {
    urlParams.set('email', email);
    urlParams.set('name', name);
    window.location.search = urlParams.toString();
    setEmailModalOpen(false);
  };
  const openPreviewModal = () => {
    clickOnModalOpener(PreviewModalOrigin.other);
  };
  return (
    <div className="min-h-screen font-sofiapro">
      <GetStartedModal modalOpen={demoModalOpen} callbackModal={() => setDemoModalOpen(false)} />
      <PreviewExitSurvey
        modalOpen={exitModalOpen}
        callbackModal={() => setExitModalOpen(false)}
        onConfirm={(choice: string) => submitSurvey(choice)}
        afterSubmit={showAfterSubmitFeedbackMessage}
      />
      {teamData?.teams?.name && (
        <EnterEmailModal
          modalOpen={emailModalOpen}
          defaultName={paramsName}
          defaultEmail={paramsEmail}
          afterSubmit={showAfterSubmitMessage}
          onConfirm={(email: string, fullName: string) => {
            submitData(email, fullName);
            setEmailUrlParams(email, fullName);
          }}
          teamName={teamData?.teams?.name}
        />
      )}
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-40 lg:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-blueberry">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="h-0 flex-1 overflow-y-auto pt-5 pb-4">
                  <a href="https://unwrap.ai" target="_blank" rel="noreferrer">
                    <div className="flex flex-shrink-0 items-center px-4">
                      <img className="h-8 w-auto" src={unwrapLightLogo} alt="Unwrap" />{' '}
                    </div>
                  </a>
                  <nav className="mt-5 space-y-1 px-2">
                    {navigation.map((item) => (
                      <div
                        key={item.name}
                        /* href={item.href} */
                        onClick={() => clickOnModalOpener(PreviewModalOrigin.other)}
                        className={classNames(
                          item.current
                            ? 'bg-black bg-opacity-20'
                            : 'transition-colors duration-100 hover:border-l-4 hover:border-l-raspberry hover:bg-raspberry hover:bg-opacity-10',
                          'group flex items-center rounded-md px-4 py-3 text-base font-medium text-milk'
                        )}
                      >
                        {item.current ? (
                          <item.selectedIcon className={classNames('mr-4 h-7 w-7 flex-shrink-0 text-raspberry')} aria-hidden="true" />
                        ) : (
                          <item.icon className={classNames('mr-5 h-6 w-6 flex-shrink-0 stroke-2 text-milk')} aria-hidden="true" />
                        )}
                        {item.name}
                      </div>
                    ))}
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div className="w-14 flex-shrink-0">{/* Force sidebar to shrink to fit close icon */}</div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-60 lg:flex-col">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex min-h-0 flex-1 flex-col bg-blueberry">
          <div className="flex flex-1 flex-col gap-y-4 overflow-y-auto pt-5 pb-4">
            <a href="https://unwrap.ai" target="_blank" rel="noreferrer">
              <div className="flex flex-shrink-0 items-center px-5">
                <img className="h-8 w-auto" src={unwrapLightLogo} alt="Unwrap" />
              </div>
            </a>
            <nav className="containerScrollbar mt-5 flex-1 space-y-2">
              {navigation.map((item) => (
                <div key={item.name} className="flex flex-col">
                  <div
                    /* href={item.href} */
                    onClick={() => !item.current && clickOnModalOpener(PreviewModalOrigin.other)}
                    className={classNames(
                      item.current
                        ? 'bg-black bg-opacity-20'
                        : 'transition-colors duration-100 hover:border-l-4 hover:border-l-raspberry hover:bg-raspberry hover:bg-opacity-10 ',
                      'group flex cursor-pointer select-none items-center border-l-4 border-transparent px-4 py-3 font-medium text-milk font-sofiapro'
                    )}
                  >
                    {item.current ? (
                      <item.selectedIcon className={classNames('mr-4 h-7 w-7 flex-shrink-0 text-raspberry')} aria-hidden="true" />
                    ) : (
                      <item.icon className={classNames('mr-5 h-6 w-6 flex-shrink-0 stroke-2 text-milk')} aria-hidden="true" />
                    )}
                    {item.name}
                  </div>
                  <div className="flex flex-col gap-y-1 pl-14">
                    {item.innerMenu?.map((innerItem, idx) => (
                      <div
                        key={innerItem.key ?? idx}
                        className={`flex flex-row items-center gap-x-2 ${
                          sources.includes(innerItem.key ?? '') ? 'hover:bg-black/30' : 'opacity-30 hover:bg-black hover:bg-opacity-50'
                        } cursor-pointer select-none rounded-lg px-2 transition-all duration-150`}
                        onClick={() => !item.current && clickOnModalOpener(PreviewModalOrigin.other)}
                      >
                        {innerItem.img && <img src={innerItem.img} className="h-5 w-5" alt={innerItem.name} />}
                        <p className="text-milk">{innerItem.name}</p>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </nav>
          </div>
        </div>
      </div>
      <div className="flex flex-1 flex-col lg:pl-56">
        <div className="sticky top-0 z-10 bg-gray-100 pl-1 pt-1 sm:pl-3 sm:pt-3 lg:hidden">
          <button
            type="button"
            className="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <main className="flex-1">
          <div className="py-6">
            <div className="mx-auto lg:max-w-7xl lg:px-4">
              {!teamData?.teams || loadingTeamDefaults ? (
                <LoadingSpinner />
              ) : (
                <div className="flex flex-col items-center gap-y-8 text-blueberry">
                  <div className="flex flex-col items-center gap-y-6 px-4">
                    <h1 className="text-center text-5xl font-bold leading-none lg:text-7xl">What are customers saying about {teamData?.teams?.name}?</h1>
                    <h1 className="text-center text-xl font-medium">Your feedback contains hundreds of action items. Here's a sneak peek.</h1>
                    <button
                      id="see-all-action-items"
                      className="text-md rounded-full bg-raspberry py-3 px-8 text-milk duration-200 hover:scale-105 hover:bg-blueberry"
                      onClick={() => clickOnModalOpener(PreviewModalOrigin.getStarted)}
                    >
                      See all action items
                    </button>
                  </div>
                  <div className="w-11/12 md:w-5/6">
                    <PreviewFiltersForClusterization
                      teamId={-1}
                      orgId={-1}
                      orgName={'Preview Page'}
                      teamName={'Preview Page'}
                      clusterFilters={{ startDate, endDate }}
                      sentenceFilters={{}}
                      setClusterFilters={() => {}}
                      setSentenceFilters={() => {}}
                      clusterIdSort={undefined}
                      setClusterIdSort={() => {}}
                      setSentenceIdSort={() => {}}
                      sentenceIdSort={undefined}
                      openPreviewModal={(originatedBy) => clickOnModalOpener(originatedBy)}
                      pageName={'Preview Landing Page'}
                      originalStartDate={originalStartDate}
                      searchInput={searchInput}
                      setSearchInput={setSearchInput}
                      onConfirmSearch={onConfirmSearch}
                    />
                  </div>

                  {!exploreHook.currentSearchId && !exploreHook.querySearchInfo.loading ? null : (
                    <div className={'mt-6 flex w-10/12 flex-col items-center'}>
                      {exploreHook.querySearchInfo.loading ? (
                        <div className="my-8 flex items-center justify-center gap-x-4 text-blueberry">
                          <div className="h-10 w-10 animate-spin rounded-full border-t-2 border-blueberry"></div>
                          <h1 className="text-xl">Generating search preview...</h1>
                        </div>
                      ) : (
                        <>
                          <SearchGroupDualLists
                            leftList={exploreHook.searchLists.inSearchList as Sentence[]}
                            rightList={exploreHook.searchLists.notInSearchList as Sentence[]}
                            switchToLeft={exploreHook.searchLists.switchSentenceToInSearch}
                            switchToRight={exploreHook.searchLists.switchSentenceToNotInSearch}
                          />
                          <button
                            className={classNames(
                              exploreHook.loadingStatuses.buildingGroup ? 'disabled cursor-default bg-blueberry-lighter' : 'bg-blueberry',
                              'mt-4 flex flex-row items-center justify-center gap-x-2 rounded-full py-3 px-6 text-sm text-milk duration-200 hover:bg-blueberry-lighter'
                            )}
                            onClick={() => !exploreHook.loadingStatuses.buildingGroup && onBuildGroup()}
                          >
                            {exploreHook.loadingStatuses.buildingGroup ? <SmallWhiteSpinner /> : undefined}
                            {exploreHook.loadingStatuses.buildingGroup ? 'Building group from this search...' : 'Build group from this search'}
                          </button>
                        </>
                      )}
                    </div>
                  )}

                  <div className="flex w-11/12 flex-col gap-y-4 pr-1 md:w-5/6">
                    {!loadingStatuses.fetchingGroups ? (
                      groups.map((group) => {
                        if (group instanceof GroupFull) {
                          return (
                            <GroupCard
                              group={group as GroupFull}
                              key={group.id}
                              filterInput={filters}
                              page={'Preview Landing Page'}
                              togglePinGroup={openPreviewModal}
                              discardSearchGroup={openPreviewModal}
                              replaceOrAddToSearchGroups={replaceOrAddToSearchGroups}
                              getAllGroupSentences={() => loadAllSentences(group.id)}
                              loadingAllSentences={loadingStatuses.loadingAllSentences}
                              updateProgress={updateProgress}
                              groupExport={openPreviewModal}
                              copyLink={openPreviewModal}
                            />
                          );
                        }
                      })
                    ) : (
                      <div className="flex items-center justify-center">
                        <div className="h-32 w-32 animate-spin rounded-full border-t-2 border-b-2 border-blueberry"></div>
                      </div>
                    )}
                  </div>
                  <div className="w-11/12 md:w-5/6">
                    <div className="rounded-3xl bg-blueberry p-8">
                      <div className="flex flex-col gap-y-6">
                        <h1 className="text-3xl font-semibold text-milk">Sign up for a 14 day trial to unlock:</h1>
                        <div>
                          <ul className="text-md flex list-disc flex-col gap-y-4 pl-8 text-milk">
                            <li>20+ more action items for {teamData?.teams?.name}</li>
                            <li>Analysis on your internal data sources</li>
                            <li>Alerting of new issues directly to your Slack or inbox</li>
                            <li>Search for and quantify any specific issue in your feedback</li>
                          </ul>
                        </div>
                        <button
                          className="text-md rounded-full bg-raspberry py-3 px-8 text-milk duration-200 hover:bg-peachring "
                          onClick={() => clickOnModalOpener(PreviewModalOrigin.getStarted)}
                        >
                          Start free trial
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
