import { GroupFull } from '../../../../v2/hooks/GroupHook';
import { FilterInput } from '../../../../generated/graphql';
import { Dispatch, useContext, useState } from 'react';
import { ProgressState, Status } from '../../../../exports/ProgressMonitor';
import AppContext from '../../../../v2/contexts/AppContext';
import Tippy from '@tippyjs/react';
import { exportGroupWithProgressUpdate } from '../../../../eventHandlers/exports/groupExport';
import toast from 'react-hot-toast';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';

export function ExportToCSVButton(props: {
  group: GroupFull | undefined;
  filters: FilterInput,
  exportProgress: ProgressState,
  updateExportProgress: Dispatch<React.SetStateAction<ProgressState>>
}) {
  const {group, filters} = props;
  const {curTeamId} = useContext(AppContext);


  return <Tippy theme="dark" content="Export to CSV" delay={200}>
    <div
      role={'button'}
      className=" rounded-full  bg-silver  text-blueberry flex flex-row justify-center items-center cursor-pointer h-8 w-8 hover:scale-110 duration-150 hover:shadow-sm"
      onClick={() => {
        if (group && group.title) {
          exportGroupWithProgressUpdate(
            {
              groupTitle: group.title!,
              teamId: curTeamId!,
              groupIds: [group!.id],
              filterInput: filters,
            },
            props.exportProgress,
            props.updateExportProgress,
          );
        } else {
          toast.error('Could not export data: Group is invalid or not defined.');
        }
      }}
    >
      <div>
        <ArrowDownTrayIcon className="h-4 w-4 stroke-2" />
      </div>
    </div>
  </Tippy>;
}