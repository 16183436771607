import { GroupFull } from '../../../../v2/hooks/GroupHook';
import { useState } from 'react';
import { ExternalTicketsWidget } from '../../externalTickets/ExternalTicketsWidget';
import { ExternalTicketsProvider } from '../../../../context/externalTicketsContext';

export function ExternalTicketsButton(props: { group: GroupFull }) {
  return (
    <div
      id="group-external-tickets"
      onClick={(event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
      }}
    >
      {/* Not sure if it's OK to delegate the responsibility of wrapping the Widget in a provider to the parent that contains the component...maybe use a HOC? */}
      <ExternalTicketsProvider>
        <ExternalTicketsWidget groupId={props.group.id} />
      </ExternalTicketsProvider>
    </div>
  );
}
