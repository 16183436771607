import { SearchInput } from '../../../baseComponents/SearchInput';
import { VirtualizedComboBox } from '../../VirtualizedComboBox';
import { IDropDownItem } from '../../../baseComponents/DropDown';
import TooltipIcon from '../../Modals/TooltipIcon';
import { classNames } from '../../../../v2/util';

interface BrowsingSectionProps<T extends { name: string; providerUniqueId: string }> {
  title: string;
  titleTooltipContent?: string;
  searchPlaceholder: string;
  comboBoxPlaceholder: string;
  onSearch: (queryString: string | undefined) => void;
  data: T[];
  selectedItem?: T | null;
  setSelectedItem: (item: T | null) => void;
  loading: boolean;
  disabled?: boolean;
}

export const BrowsingSection = <T extends { name: string; providerUniqueId: string }>({
  title,
  titleTooltipContent,
  searchPlaceholder,
  comboBoxPlaceholder,
  onSearch,
  data,
  selectedItem,
  setSelectedItem,
  loading,
  disabled,
}: BrowsingSectionProps<T>) => {
  const comboBoxData: IDropDownItem[] = mapToDropDownItem(data);

  return (
    <div className={classNames('flex flex-col gap-y-1', disabled ? 'pointer-events-none opacity-70' : '')}>
      <div className="flex flex-row items-center gap-x-2">
        <p className="select-none">{title}</p>
        {titleTooltipContent ? <TooltipIcon tooltipContent={titleTooltipContent} /> : null}
      </div>
      <SearchInput onSearch={onSearch} noPadding rounded="md" placeholder={searchPlaceholder} />
      <VirtualizedComboBox
        comboBoxData={comboBoxData}
        selectedItem={comboBoxData.find((item) => item.value === selectedItem?.providerUniqueId)}
        setSelectedItem={(newItem) => {
          const selected = data.find((item) => item.providerUniqueId === newItem?.value);
          setSelectedItem(selected ?? null);
        }}
        placeholder={loading ? `Loading ${comboBoxPlaceholder}...` : `Select ${comboBoxPlaceholder}`}
        loading={loading}
      />
    </div>
  );
};

export const mapToDropDownItem = <T extends { name: string; providerUniqueId: string }>(data: T[]): IDropDownItem[] => {
  return data.map((item, idx) => ({
    name: item.name,
    id: idx,
    value: item.providerUniqueId,
  }));
};
