import { ExploreGroupHook } from '../../hooks/ExploreGroupHook';
import { HomeGroupHook } from '../../hooks/HomeGroupHook';
import { FilterHook } from '../../hooks/FilterHook';
import { GROUP_ENTRIES_PAGE_SIZE, GroupFull } from '../../../v2/hooks/GroupHook';
import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useIsMount } from '../../../v2/util';
import FeedbackEntryCard from '../FeedbackEntryCard';
import SearchInput from '../../baseComponents/SearchInput';
import { SmallSpinner } from '../SmallSpinner';

export const GroupEntries = ({ groupHook, filterHook }: { groupHook: ExploreGroupHook | HomeGroupHook; filterHook: FilterHook }) => {
  const group = groupHook.currentGroup as GroupFull | undefined;
  const [page, setPage] = useState(GROUP_ENTRIES_PAGE_SIZE);
  const [addSentencesPage, setAddSentencesPage] = useState(0);
  const [paginating, setPaginating] = useState(false);
  const [endOfEntriesReached, setEndOfEntriesReached] = useState(false);
  const [endOfAddSentencesReached, setEndOfAddSentencesReached] = useState(false);
  const [addSentencesQuery, setAddSentencesQuery] = useState<string>('');
  const { ref: scrollRef, inView } = useInView({
    rootMargin: '300px',
    threshold: 0,
  });

  const SENTENCES_PAGE_SIZE = 20;

  const [currentTabId, setCurrentTabId] = useState<number>(0);
  const tabs = [
    {
      id: 0,
      name: 'Mentions',
    },
    { id: 1, name: 'Add Sentences +' },
  ];

  useEffect(() => {
    if (!group) return;
    if (currentTabId == 0 && endOfEntriesReached) return;
    if (currentTabId == 1 && endOfAddSentencesReached) return;
    if (inView) {
      setPaginating(true);
      if (currentTabId === 0) {
        groupHook
          .loadMoreSentences(
            group.id,
            page,
            GROUP_ENTRIES_PAGE_SIZE,
            () => {
              setEndOfEntriesReached(true);
            },
            () => {}
          )
          .then(() => {
            setPage((prev) => prev + GROUP_ENTRIES_PAGE_SIZE);
            setPaginating(false);
          });
      } else if (currentTabId === 1) {
        groupHook
          .refetchSimilarSentences(
            addSentencesQuery,
            addSentencesPage,
            SENTENCES_PAGE_SIZE,
            () => setEndOfAddSentencesReached(true),
            () => {
              setPaginating(false);
            }
          )
          .then(() => {
            setAddSentencesPage((prev) => prev + SENTENCES_PAGE_SIZE);
            setPaginating(false);
          });
      }
    }
  }, [inView, currentTabId]);

  const isFirstRender = useIsMount();
  useEffect(() => {
    if (!isFirstRender) {
      setPage(0);
      setEndOfEntriesReached(false);
    }
  }, [filterHook.filters]);

  useEffect(() => {
    if (isFirstRender) groupHook.clearSimilarSentences();
  }, []);

  if (!group)
    return (
      <div className="flex flex-col gap-y-4 min-h-screen w-full bg-milk mt-4 px-8">
        {/* Show three itmes below of the loading skeleton */}
        <EntriesLoadingSkeleton />
        <EntriesLoadingSkeleton />
        <EntriesLoadingSkeleton />
      </div>
    );
  return (
    <div className="flex flex-col gap-y-2 min-h-screen bg-milk mt-4 mx-8">
      <div className="flex flex-row gap-x-4 items-baseline">
        {tabs.map((tab) => {
          return (
            <p
              key={tab.id}
              id={tab.name.replace(' ', '')}
              className={`${
                currentTabId === tab.id ? 'font-semibold text-blueberry decoration-2 underline underline-offset-4' : ' text-gray-500'
              } cursor-pointer text-lg`}
              onClick={() => setCurrentTabId(tab.id)}
            >
              {tab.name}
            </p>
          );
        })}
      </div>

      {currentTabId === 0
        ? group?.entries.map((entry) => {
            const sentences = group.sentences.filter((sentence) => entry.sentences.map((s) => s.id).includes(sentence.id));
            return <FeedbackEntryCard key={entry.id} entry={entry} compactAvailable={true} compact={true} sentences={sentences} groupHook={groupHook} />;
          })
        : null}
      {currentTabId === 1 && (
        <div className="pt-2 relative">
          <div className="px-1 mb-3">
            <SearchInput
              queryString={addSentencesQuery}
              placeholder={'Add more sentences'}
              setQueryString={(string) => setAddSentencesQuery(string ?? '')}
              onSearch={async (q) => {
                setPaginating(true);
                setAddSentencesPage(0);
                setEndOfAddSentencesReached(false);
                groupHook.clearSimilarSentences();
                await groupHook.refetchSimilarSentences(
                  q ?? '',
                  0,
                  SENTENCES_PAGE_SIZE,
                  () => setEndOfAddSentencesReached(true),
                  () => setPaginating(false),
                  true
                );
              }}
            />
          </div>
          <div className="flex flex-col gap-y-2">
            {!paginating && !groupHook.loadingStatuses.loadingSimilarSentences && groupHook.similarSentences?.length === 0 ? (
              <p className="pt-4">No similar sentences were found.</p>
            ) : (
              groupHook.similarSentences &&
              groupHook.similarSentences.map((sentence) => {
                if (!group) return;
                return (
                  <FeedbackEntryCard
                    key={sentence.id}
                    entry={sentence.entry!}
                    compactAvailable={true}
                    compact={true}
                    sentences={[sentence]}
                    groupHook={groupHook}
                    addMode={true}
                  />
                );
              })
            )}
          </div>
        </div>
      )}
      {paginating ? (
        <div className="flex flex-row items-baseline gap-x-2">
          <SmallSpinner />
          Loading more entries...
        </div>
      ) : null}
      {group.entries ? <div ref={scrollRef} className="mb-4"></div> : null}
    </div>
  );
};

const EntriesLoadingSkeleton = () => {
  return (
    <div
      className="custom-chart-card-skeleton space-y-5 rounded-lg bg-gray-100 relative 
        before:absolute before:inset-0
        before:-translate-x-full
        before:animate-[shimmer_2s_infinite]
        before:bg-gradient-to-r before:from-transparent before:via-blueberry before:opacity-[0.2]
        isolate
        overflow-hidden
        before:border-t before:border-gray-100 opacity-70 h-[8rem]"
    >
      <div className="space-y-3 p-4">
        <div className="h-4 bg-gray-300 rounded w-3/4"></div>
        <div className="h-4 bg-gray-300 rounded w-1/2"></div>
        <div className="h-4 bg-gray-300 rounded w-5/6"></div>
        <div className="h-4 bg-gray-300 rounded w-1/3"></div>
      </div>
    </div>
  );
};
