import { Popover, Tab, Transition } from '@headlessui/react';
import { BellAlertIcon, ClockIcon } from '@heroicons/react/24/outline';
import Tippy from '@tippyjs/react';
import { Dispatch, Fragment, SetStateAction, useState } from 'react';
import { classNames } from '../../../v2/util';
import { SlackChannelSelector } from '../SlackChannelSelector';
import { EmailSelector } from '../EmailSelector';
import DropDown, { Align, IDropDownItem } from '../../baseComponents/DropDown';
import { CadenceType, New_Alerts } from '../../../generated/graphql';
import { useValidTeamAppContext } from '../../../v2/contexts/AppContext';
import Button, { ButtonVariant } from '../../baseComponents/Button';
import TooltipIcon from '../Modals/TooltipIcon';
import { useAlertHook } from '../../hooks/AlertHook';
import { AppRoutes } from '../../../Routes';
import { NavLink } from 'react-router-dom';
import { useOrganizationHook } from '../../../v2/hooks/OrganizationHook';

//const tabs = ['Subscribe', 'See Your Subscriptions'];
const tabs = [
  {
    name: 'Subscribe',
    path: null,
  },
  {
    name: 'See Your Subscriptions',
    path: AppRoutes.v3FullPath.alerts,
  },
];
export const cadenceOptions: IDropDownItem[] = [
  { id: 0, name: 'Daily', value: CadenceType.Daily },
  { id: 1, name: 'Weekly', value: CadenceType.Weekly },
];

export function GroupSubscriptionPopover({
  groupId,
  updateKey,
  children,
  subscriptionToEdit,
  parentAlertHook,
  customIconElem,
}: {
  groupId: number;
  updateKey: Dispatch<SetStateAction<number>>;
  children?: JSX.Element;
  subscriptionToEdit?: New_Alerts;
  //If the popover is in the Alerts page, we want the parent hook. Otherwise (inside group modals), we use the one declared in this component.
  parentAlertHook?: ReturnType<typeof useAlertHook>;
  customIconElem?: JSX.Element;
}) {
  const { curTeamId: teamId } = useValidTeamAppContext();
  const [selectedCadence, setSelectedCadence] = useState<IDropDownItem>(
    cadenceOptions.find((cad) => cad.value === subscriptionToEdit?.cadence) ?? cadenceOptions[0]
  );
  const { filteredOrgUsers } = useOrganizationHook();
  const [emailList, setEmailList] = useState<string[]>(subscriptionToEdit?.emails.map((email) => email.to_email ?? '') ?? []);
  const [slackChannelId, setSelectedSlackChannelID] = useState<string | null>(subscriptionToEdit?.slack?.[0]?.slack_channel_ID ?? null);
  const [slackChannelName, setSelectedSlackChannelName] = useState<string | null>(subscriptionToEdit?.slack?.[0]?.slack_channel_name ?? null);
  const { createSubscription, loadingStatuses } = useAlertHook({ teamId, preventDefaultLoad: true });

  const addEmailToList = (newEmail: string) => setEmailList((eList) => [...eList, newEmail]);
  const removeEmailFromList = (emailToRemove: string) => setEmailList((eList) => eList.filter((e) => e !== emailToRemove));

  const onSubscriptionConfirm = async () => {
    if (!!subscriptionToEdit) {
      if (!parentAlertHook) {
        console.error('Internal Error (No parent alert hook was provided to the group subscription popover)');
        return;
      }
      //We're editing an existing subscription
      await parentAlertHook?.editSubscription(subscriptionToEdit.id, {
        emails: emailList,
        cadence: selectedCadence.value as CadenceType,
        slackChannelId,
        slackChannelName,
      });
    } else {
      //We're creating a new subscription
      await createSubscription({
        groupId,
        emails: emailList,
        cadence: selectedCadence.value as CadenceType,
        slackChannelId,
        slackChannelName,
      });
    }
    updateKey((key) => key + 1);
  };

  return (
    <Popover className="relative items-center font-sofiapro">
      <Popover.Button className="flex focus:outline-none">
        {children
          ? children
          : customIconElem ?? (
              <Tippy theme="dark" delay={200} content={<p className="text-center">Subscribe to this group</p>}>
                <div className="rounded-full p-2 transition hover:cursor-pointer hover:bg-slate-900/25 ">
                  <div>
                    <BellAlertIcon id="subscribe-to-group" className="h-5 w-5 focus:outline-none " />
                  </div>
                </div>
              </Tippy>
            )}
      </Popover.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-150"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="absolute flex-col gap-y-4 right-0 z-50 text-blueberry bg-milk rounded-lg shadow-lg p-4 flex w-[28rem] px-8">
          {({ close }) => (
            <Tab.Group>
              {!!!subscriptionToEdit ? (
                <Tab.List className="flex border-b">
                  {tabs.map((tab, idx) => (
                    <Tab
                      key={idx}
                      disabled={!!tab.path}
                      className={({ selected }) =>
                        classNames(
                          'w-full duration-100 leading-5 text-sm font-medium',
                          idx === 0 ? 'rounded-l-md' : '',
                          idx === tabs.length - 1 ? 'rounded-r-md' : '',
                          selected ? 'bg-blueberry text-white' : 'bg-silver opacity-80 hover:bg-blueberry-lighter hover:text-white'
                        )
                      }
                    >
                      {tab.path ? (
                        <NavLink to={tab.path} target="_blank" className=" flex items-center justify-center py-2 px-2">
                          {tab.name}
                        </NavLink>
                      ) : (
                        <div className="flex items-center justify-center py-2 px-2"> {tab.name}</div>
                      )}
                    </Tab>
                  ))}
                </Tab.List>
              ) : null}
              <Tab.Panels>
                <Tab.Panel>
                  <div className="flex flex-col gap-y-4">
                    <h1 className="text-sm self-center">
                      {!!subscriptionToEdit ? 'Update your group subscription' : 'Start receiving updates about this group'}
                    </h1>
                    <div className="flex flex-col gap-y-6">
                      <div className="flex flex-row gap-x-3 items-center">
                        <div className="flex flex-row items-center gap-x-2">
                          <ClockIcon className="h-4 w-4" />
                          <h1 className={'text-sm font-semibold'}>Cadence</h1>
                        </div>
                        <div className="flex w-full flex-row flex-wrap items-center gap-x-2">
                          <DropDown
                            align={Align.Left}
                            dropDownData={cadenceOptions}
                            selectedItem={selectedCadence}
                            setSelectedItem={(selectedItem?: IDropDownItem) => {
                              setSelectedCadence(selectedItem ?? cadenceOptions[0]);
                            }}
                          />
                          <TooltipIcon tooltipContent={'Weekly subscriptions get sent every Monday.'} />
                        </div>
                      </div>
                      <EmailSelector
                        inline
                        totalList={filteredOrgUsers.map((orgUser) => orgUser.email)}
                        selectedList={emailList}
                        removeEmailFromList={removeEmailFromList}
                        addEmailToList={addEmailToList}
                      />
                      <div className="pb-5">
                        <SlackChannelSelector
                          inline
                          initialChannelName={slackChannelName ?? undefined}
                          updateChannelId={(channelId: string | null, channelName: string | null) => {
                            setSelectedSlackChannelID(channelId);
                            setSelectedSlackChannelName(channelName);
                          }}
                        />
                      </div>
                    </div>
                    <div className="flex flex-row gapw-full justify-between">
                      <Button onClick={() => close()} variant={ButtonVariant.Tertiary} text="Cancel" />
                      <Button
                        variant={ButtonVariant.Primary}
                        text={!!subscriptionToEdit ? 'Confirm Changes' : 'Create Subscription'}
                        disabled={loadingStatuses.creatingAlert || (!!subscriptionToEdit && parentAlertHook?.loadingStatuses.editingAlert)}
                        onClick={async () => {
                          await onSubscriptionConfirm();
                          close();
                        }}
                        loadingConfirm={loadingStatuses.creatingAlert || (!!subscriptionToEdit && parentAlertHook?.loadingStatuses.editingAlert)}
                      />
                    </div>
                  </div>
                </Tab.Panel>
                <Tab.Panel></Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          )}
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
