import Tippy from '@tippyjs/react';
import { Cog6ToothIcon } from '@heroicons/react/24/outline';

export function EditDigestButton(props: { onClick: () => void }) {
  return <Tippy content="Edit Digest" delay={200}>
    <div
      className="rounded-full  bg-silver  text-blueberry flex flex-row justify-center items-center cursor-pointer h-8 w-8 hover:scale-110 duration-150 hover:shadow-sm focus:outline-none"
      onClick={props.onClick}
    >
      <div>
        <Cog6ToothIcon className="h-4 w-4 stroke-2 focus:outline-none" />
      </div>
    </div>
  </Tippy>;
}