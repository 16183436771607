import { FilterInput, Group_Status, useGetInsightGroupsQuery } from '../../../generated/graphql';
import { useValidTeamAppContext } from '../../../v2/contexts/AppContext';
import { GroupFull, getGroups } from '../../../v2/hooks/GroupHook';
import { Events, logEvent } from '../../../v2/AnalyticsUtil';

import InsightCard from '../../components/InsightCard';
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { useState } from 'react';

interface OldInsightGroupViewerProps {
  openGroup: (groupId: number, filterInput: FilterInput) => void;
  pageName: string;
}

const OldInsightGroupViewer = (props: OldInsightGroupViewerProps) => {
  const { currentTeam, currentOrg } = useValidTeamAppContext();
  const [startDate, _] = useState(moment().subtract(90, 'days').startOf('day').toDate());
  const [endDate, _1] = useState(new Date());
  const { data } = useGetInsightGroupsQuery({
    variables: {
      teamId: currentTeam.id,
      belongs: true,
      sentencesTake: 3,
      take: 10,
      filterInput: { startDate, endDate },
      status: Group_Status.Monitored,
    },
  });

  if (data) {
    return (
      <>
        {data.getGroups.length > 0 ? (
          <div>
            <h1 className="text-2xl font-semibold text-blueberry mb-1">Top Insights This Week</h1>
            <div id="carouselRootElement" className="slide carousel carousel-dark relative" data-bs-ride="carousel">
              <div className="carousel-inner relative w-full overflow-hidden h-full ">
                {data.getGroups.map((group, index) => {
                  const groupFull = getGroups([group])[0];
                  return (
                    <div className={`carousel-item ${index === 0 ? 'active' : ''} relative float-left w-full`} key={index} data-bs-interval="10000">
                      <InsightCard
                        group={groupFull as GroupFull}
                        onClick={() => {
                          props.openGroup(group.id, {});
                          logEvent(Events.InsightCardClicked, {
                            View_ID: currentTeam.id,
                            View_Name: currentTeam?.name,
                            Org_ID: currentOrg.id,
                            Org_Name: currentOrg?.name,
                            Page: props.pageName,
                            Depth: index,
                          });
                        }}
                      />
                    </div>
                  );
                })}
              </div>

              {data.getGroups.length > 1 && (
                <>
                  <div className="flex flex-row justify-center pt-3">
                    <div className="group px-5">
                      <button
                        className="flex items-center justify-center h-9 p-1 rounded-full  text-blueberry hover:text-milk bg-silver duration-200 hover:bg-blueberry shadow pr-1.5"
                        data-bs-target="#carouselRootElement"
                        data-bs-slide="prev"
                      >
                        <ChevronDoubleLeftIcon className="inline-block h-6 w-6 stroke-2" />
                        {/* <span className="carousel-control-prev-icon inline-block bg-no-repeat group-hover:text-white" aria-hidden="true"></span> */}
                      </button>
                    </div>

                    <div className="flex flex-col items-center justify-center">
                      <div className="carousel-indicators flex justify-center ">
                        {data.getGroups.map((_, index) => {
                          return (
                            <button
                              onClick={() =>
                                logEvent(Events.CarouselButtonClicked, {
                                  View_ID: currentTeam.id,
                                  View_Name: currentTeam?.name,
                                  Org_ID: currentOrg.id,
                                  Org_Name: currentOrg?.name,
                                  Page: props.pageName,
                                  Depth: index,
                                })
                              }
                              key={index}
                              type="button"
                              data-bs-target="#carouselRootElement"
                              data-bs-slide-to={index}
                              className={index === 0 ? 'active' : ''}
                              aria-current="true"
                              aria-label={`Slide ${index}`}
                            ></button>
                          );
                        })}
                      </div>
                    </div>
                    <div className="group px-5">
                      <button
                        className="flex items-center justify-center h-9 p-1 rounded-full  text-blueberry hover:text-milk bg-silver duration-200 hover:bg-blueberry shadow pl-1.5"
                        data-bs-target="#carouselRootElement"
                        data-bs-slide="next"
                      >
                        <ChevronDoubleRightIcon className="inline-block h-6 w-6 stroke-2 " />
                        {/* <span className="carousel-control-prev-icon inline-block bg-no-repeat group-hover:text-white" aria-hidden="true"></span> */}
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        ) : null}
      </>
    );
  }
  return <div data-testid="old-insight-group-viewer"></div>;
};

export default OldInsightGroupViewer;
