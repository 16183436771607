import { useEffect } from 'react';
import {
  Chart_Bin_Type,
  FilterInput,
  Insight_Collection_Type,
  useGetInsightPreviewChartToDeleteLazyQuery,
  useGetInsightPreviewChartsLazyQuery,
} from '../../../../generated/graphql';
import InsightChart from './InsightChart';

interface InsightPreviewChartProps {
  teamId: number;
  collectionId: number;
  collectionType: Insight_Collection_Type;
  filterInput: FilterInput;
  chartBin: Chart_Bin_Type;
}

/**
 * This fetches relevant chart data given a collection type & id, teamId, and filterInput
 * @param props
 * @returns
 */
const InsightPreviewChart = (props: InsightPreviewChartProps) => {
  const { filterInput, chartBin } = props;
  const [groupChartQuery, series] = useGetInsightPreviewChartToDeleteLazyQuery({
    variables: {
      teamId: props.teamId,
      collectionId: props.collectionId,
      collectionType: props.collectionType,
      filterInput: filterInput,
      chartBin: chartBin,
    },
  });
  const [customChartQuery, chart] = useGetInsightPreviewChartsLazyQuery({
    variables: {
      teamId: props.teamId,
      collectionId: props.collectionId,
      collectionType: props.collectionType,
      filterInput: filterInput,
      chartBin: chartBin,
    },
  });
  useEffect(() => {
    if (props.collectionType === Insight_Collection_Type.Chart) {
      customChartQuery();
    } else {
      groupChartQuery();
    }
  }, []);

  return (
    <InsightChart
      chart={chart.data?.getInsightPreviewCharts}
      series={series.data?.getInsightPreviewChartToDelete}
      filterInput={filterInput}
      loading={series.loading || chart.loading}
    />
  );
};

export default InsightPreviewChart;
