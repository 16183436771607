import { useContext, useEffect, useState } from 'react';
import { DigestStatus, useGetDigestSeriesQuery, useGetDigestsQuery } from '../../../generated/graphql';
import { DigestDataContext, DigestDataDispatchContext } from '../../../context/digestDataContext';
import { DigestDataActions, DigestObjectType } from '../../../reducers/digests/digestDataReducer';
import CreateDigestSeries from './CreateDigestSeries';
import CreateDigest from './CreateDigest';
import Button, { ButtonVariant } from '../../baseComponents/Button';
import DigestDigestSeriesListChooser from './DigestDigestSeriesListChooser';
import DigestListItem from './DigestListItem';
import DigestSeriesListItem from './DigestSeriesListItem';

const DigestSideBarSection = () => {
  const dispatchDigestData = useContext(DigestDataDispatchContext);
  const digestSeriesData = useGetDigestSeriesQuery({});
  const digestData = useGetDigestsQuery({});
  const [createDigestSeriesModalOpen, setCreateDigestSeriesModalOpen] = useState(false);
  const [createDigestModalOpen, setCreateDigestModalOpen] = useState(false);
  const [chosenDorDsSelector, setChosenDorDsSelector] = useState<DigestObjectType>(DigestObjectType.Digest);

  useEffect(() => {
    if (digestSeriesData.data?.digestSeries) {
      dispatchDigestData?.({ type: DigestDataActions.SetDigestSeriesData, payload: { digestSeriesData: digestSeriesData.data?.digestSeries } });
    }
  }, [digestSeriesData.data]);

  useEffect(() => {
    if (digestData.data?.digests) {
      dispatchDigestData?.({ type: DigestDataActions.SetDigestData, payload: { digests: digestData.data.digests } });
    }
  }, [digestData.data]);

  return (
    <div className="flex flex-col gap-y-2 h-screen bg-milk">
      {/* Modals for creating digest and digest series */}
      <CreateDigestSeries modalOpen={createDigestSeriesModalOpen} setFalseToCloseModal={setCreateDigestSeriesModalOpen} />
      <CreateDigest modalOpen={createDigestModalOpen} setFalseToCloseModal={setCreateDigestModalOpen} />
      <Button
        expandWidth={true}
        id="create-digest"
        variant={ButtonVariant.Primary}
        text="Create New"
        onClick={() => {
          if (chosenDorDsSelector === DigestObjectType.Digest) {
            setCreateDigestModalOpen(true);
          } else {
            setCreateDigestSeriesModalOpen(true);
          }
        }}
      />
      <DigestDigestSeriesListChooser chosenState={chosenDorDsSelector} setChoseState={setChosenDorDsSelector} />
      <div className="overflow-y-auto">{chosenDorDsSelector === DigestObjectType.Series ? <DigestSeriesList /> : <DigestList />}</div>
    </div>
  );
};

const DigestList = () => {
  const digestData = useContext(DigestDataContext);
  return (
    <div className="gap-y-0">
      {[DigestStatus.Draft, DigestStatus.PendingReview, DigestStatus.Approved, DigestStatus.Sent].map((status: DigestStatus) =>
        digestData.digests
          .filter((digest) => digest.status === status)
          .map((digest) => {
            return <DigestListItem key={digest.id} item={digest} />;
          })
      )}
    </div>
  );
};

const DigestSeriesList = () => {
  const digestData = useContext(DigestDataContext);
  return (
    <div className="gap-y-0">
      {digestData.digestSeriesData.map((digestSeriesItem) => {
        return <DigestSeriesListItem item={digestSeriesItem} />;
      })}
    </div>
  );
};

export default DigestSideBarSection;
