import { useContext, useEffect, useRef, useState } from 'react';
import { useValidTeamAppContext } from '../../v2/contexts/AppContext';
import {
  Breakdown,
  ChartFragment,
  Chart_Bin_Type,
  Chart_Type,
  SeriesInput,
  Y_Axis_Data,
  useCreateTagMutation,
  useDataForFiltersLazyQuery,
  useDataForFiltersQuery,
  useGetCustomChart2DLazyQuery,
  useGetCustomChartLazyQuery,
} from '../../generated/graphql';
import { useFilterHook } from '../hooks/FilterHook';
import UserContext from '../../v2/contexts/UserContext';
import { useCustomChartHook } from '../hooks/CustomChartHook';
import { alphabeticalSort, capitalizeFirstLetter, useIsMount } from '../../v2/util';
import { useClickOutside } from '../../utilities';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import DropDown, { Align, IDropDownItem } from '../baseComponents/DropDown';
import { ITag } from '../../v2/hooks/GroupHook';
import { cloneDeep } from 'lodash';
import { BreakdownMockList, ChartBinTypes, ChartTypeList, PlotUnitMockList, buildFilterInputFromSavedFilterInput } from '../pages/ChartsPage';
import { AppRoutes } from '../../Routes';
import Button, { ButtonVariant } from '../baseComponents/Button';
import LoadingSpinner from '../baseComponents/LoadingSpinner';
import InlineEditableTextArea from '../../baseComponents/InlineEditableTextArea';
import { DataCard } from './ChartDataCard';
import Tippy from '@tippyjs/react';
import Badge from './Badge';
import { ComboBox } from '../../baseComponents/ComboBox';
import AdjustableLoadingIcon from '../../baseComponents/AdjustableLoadingIcon';
import { FilterManager, FilterManagerDisplayMode } from '../sections/Filters/FilterManager';
import { PlusIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { FullCustomChartCard } from './CustomChartCard';

export const EditChartSection = ({ mode }: { mode: 'editor' | 'creator' }) => {
  const { curTeamId: teamId, curOrgId: orgId, organizations } = useValidTeamAppContext();
  const [binType, setBinType] = useState(Chart_Bin_Type.Dynamic);
  const teams = organizations.find((org) => org.id === orgId)?.teams;
  const teamsList = teams?.map((team) => ({ id: team.id, name: team.name })) || [];
  //This hook is used for the chart's settings
  const editChartFilterHook = useFilterHook({ teamId, orgId, disableUrlFilters: true });
  //This hook is used for the visualization (only the dates that change the chart's range)
  const datePreviewFilterHook = useFilterHook({ teamId, orgId, filterKey: 'chart' });
  const { user } = useContext(UserContext);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const { currentChart, createCustomChart, loadingStatues, setChartConfigs, chartConfigs, editCustomChart } = useCustomChartHook({
    teamId,
    orgId,
    filterInput: editChartFilterHook.filters,
    dateFilterInput: datePreviewFilterHook.filters,
    mode,
    binType,
    initialLoadComplete,
  });
  const isFirstRender = useIsMount();
  //Tags stuff
  const ref = useRef<HTMLDivElement>(null);
  const [addTagOpen, setAddTagOpen] = useState(false);
  const [addLoading, setAddLoading] = useState<boolean>(false);
  useClickOutside([ref], () => addTagOpen && setAddTagOpen(false));
  useEffect(() => {
    //This checks if the user edited the chart. In that case, we want to display the edited chart and no longer the original chart.
    if (mode === 'editor' && displayReturnedChart && initialLoadComplete) setDisplayReturnedChart(false);
  }, [currentChart]);

  //This state helps decrease latency by displaying the current chart in case it's in editor mode, so we don't have to wait until the Preview query (which finishes after the Chart query)
  const [displayReturnedChart, setDisplayReturnedChart] = useState(mode === 'editor');
  const { chartId } = useParams<{ chartId: string }>();
  const [ownerId, setOwnerId] = useState<number>();
  const [tags, setTags] = useState<ITag[]>([]);
  const [dataFilters, setDataFilters] = useState<SeriesInput[]>([{ teamIdOverride: teamId }]);
  const [getFilterData, filterData] = useDataForFiltersLazyQuery({ variables: { teamId, orgId } });
  const [segments, setSegments] = useState<IDropDownItem[] | undefined>();
  const [createTag, _] = useCreateTagMutation();
  const settingsFilterManagerRef = useRef<any>(null);

  //TODO: Move this to hook
  const { data: ownerTagsData, loading: loadingOwnerTagsData } = useDataForFiltersQuery({ variables: { teamId, orgId } });
  const [getCustom2DChart, custom2DChart] = useGetCustomChart2DLazyQuery({ fetchPolicy: 'no-cache' });
  const [customChart, setCustomChart] = useState<ChartFragment | undefined>();
  useEffect(() => {
    let updatedInputs = cloneDeep(dataFilters);
    updatedInputs = updatedInputs.map((filter) => {
      return {
        ...filter,
        filterInput: { ...filter.filterInput, startDate: datePreviewFilterHook.filters?.startDate, endDate: datePreviewFilterHook.filters?.endDate },
      };
    });
    setDataFilters(updatedInputs);
  }, [datePreviewFilterHook.filters]);

  const fetchCustomChart = async () => {
    return await getCustom2DChart({
      variables: { teamId, chartId: Number(chartId), filterInput: datePreviewFilterHook.filters },
      onCompleted(data) {
        const chart: ChartFragment = {
          id: data.getChart2D.id,
          title: data.getChart2D.title,
          type: data.getChart2D.config.type,
          y_axis_data: data.getChart2D.config.y_axis_data,
          breakdown: data.getChart2D.config.breakdown,
          seriesData: data.getChart2D.config.seriesConfig?.map((series, idx, arr) => {
            return {
              aggregateData: arr.length > 1 ? [data.getChart2D.series[idx]] : data.getChart2D.series,
              normalizedData: arr.length > 1 ? [data.getChart2D.series[idx]] : data.getChart2D.series,
              tooltipLabels: data.getChart2D.tooltipLabels,
              breakdown: data.getChart2D.config.breakdown,
              chartLabels: data.getChart2D.xAxisLabels,
              breakdownLabels: arr.length > 1 ? [data.getChart2D.legend[idx]] : data.getChart2D.legend,
              filterInput: series.filterInput,
              team: series.team,
              segmentGroupId: series.segmentGroupId,
            };
          }),
        };
        if (chart.seriesData) {
          settingsFilterManagerRef?.current?.refreshUiFilters(editChartFilterHook.filters);
        }
        const seriesData =
          chart.seriesData?.map((sd) => ({
            filterInput: {
              ...buildFilterInputFromSavedFilterInput(sd.filterInput ?? {}, editChartFilterHook.filters.startDate, editChartFilterHook.filters.endDate),
            },
            teamIdOverride: sd.team.id,
            segmentGroupId: sd.segmentGroupId,
          })) ?? [];
        setChartConfigs((prev) => {
          return {
            ...prev,
            y_axis_data: PlotUnitMockList.find((item) => item.value === chart?.y_axis_data)?.value as Y_Axis_Data,
            breakdown: BreakdownMockList.find((item) => item.value === chart?.breakdown || item.value === chart?.seriesData?.[0].breakdown)?.value as Breakdown,
            title: chart?.title ?? '',
            series: seriesData,
            type: ChartTypeList.find((item) => item.value === chart?.type)?.value as Chart_Type,
          };
        });
        setDataFilters(seriesData);
        setTags(chart.tags ?? []);
        setOwnerId(chart.owner?.id);
        setInitialLoadComplete(true);
        setCustomChart(chart);
      },
      onError(error) {
        navigate(AppRoutes.v3FullPath.charts);
      },
    });
  };

  const getSegments = () => {
    getFilterData({
      onCompleted(data) {
        setSegments(
          data.segments?.map((segment) => {
            return { id: segment.id, name: segment.displayName };
          })
        );
      },
    });
  };

  useEffect(() => {
    getSegments();
    if (chartId)
      fetchCustomChart().then(() => {
        setInitialLoadComplete(true);
      });
    else setInitialLoadComplete(true);
  }, []);

  useEffect(() => {
    // Not super nice. Revisit.
    if (!isFirstRender) setChartConfigs((prev) => ({ ...prev, breakdown: dataFilters.length === 1 ? prev?.breakdown : undefined, series: dataFilters }));
    dataFilters.forEach((filter) => {
      settingsFilterManagerRef?.current?.refreshUiFilters(filter.filterInput);
    });
  }, [dataFilters]);
  let navigate = useNavigate();

  const location = useLocation();

  const handleCreateTag = async (tag: string, cb?: () => void) => {
    createTag({
      variables: {
        teamId,
        name: tag,
      },
      onCompleted(data) {
        const newTag = data.createTag as ITag;
        setTags((tags) => [...tags, newTag]);
        cb?.();
      },
    });
  };

  const updateFilterInputs = (filterInput: SeriesInput, index: number) => {
    const updateInputs = cloneDeep(dataFilters);
    updateInputs[index] = filterInput;
    setDataFilters(updateInputs);
  };
  const deleteFilterInput = (teamId: number, index: number) => {
    setDataFilters((prev) => prev.filter((f, i) => !(f.teamIdOverride === teamId && i === index)));
  };

  const handleCancelClick = () => {
    const navigatePath = `${AppRoutes.v3FullPath.charts}${location.search}`;
    navigate(navigatePath);
  };

  return (
    <div className="flex flex-col gap-y-4 h-screen">
      <div className="flex flex-row justify-between my-2 border-b-2 border-gray-100 pb-4">
        <div>
          <h1 className="text-blueberry text-3xl font-bold">{mode === 'editor' ? 'Edit' : 'Create'} Chart</h1>
        </div>
        <div className="flex flex-row gap-x-4">
          <Button id="cancel-chart-creation" variant={ButtonVariant.Tertiary} text="Cancel" onClick={handleCancelClick} />
          <Button
            id="save-chart"
            variant={ButtonVariant.Primary}
            text="Save Chart"
            onClick={async () => {
              mode === 'creator' ? await createCustomChart(tags) : await editCustomChart(Number(chartId), tags);
              navigate(AppRoutes.v3FullPath.charts);
            }}
          />
        </div>
      </div>
      <div className="grid grid-cols-12 divide-x-2 divide-gray-100 rounded-3xl w-full">
        {!initialLoadComplete || custom2DChart.loading ? (
          <div className="col-span-12">
            <LoadingSpinner />
          </div>
        ) : (
          <>
            <div className="col-span-4 pr-4 flex flex-col gap-y-4 relative">
              <EditChartInputRow name="Title">
                <InlineEditableTextArea
                  textAreaId="chart-title"
                  additionalClassNames="bg-silver hover:bg-silver-darker px-3 rounded-lg focus:border text-blueberry"
                  value={chartConfigs?.title ?? ''}
                  onEditComplete={(newText: string) =>
                    setChartConfigs((prev) => {
                      return { ...prev, title: newText };
                    })
                  }
                />
              </EditChartInputRow>
              <EditChartInputRow name="Type">
                <div className="w-fit">
                  <DropDown
                    id="chart-type-dropdown"
                    align={Align.Left}
                    dropDownData={ChartTypeList}
                    setSelectedItem={function (selectedItem: IDropDownItem): void {
                      setChartConfigs((prev) => {
                        return { ...prev, type: selectedItem.value as Chart_Type };
                      });
                    }}
                    selectedItem={ChartTypeList.find((item) => item.value === chartConfigs?.type)}
                    fullWidthText
                  />
                </div>
              </EditChartInputRow>
              <EditChartInputRow
                name="Data"
                onAdd={
                  dataFilters.length <= 10
                    ? () => {
                        setDataFilters((prev) => [...prev, { teamIdOverride: teamsList[0].id }]);
                      }
                    : undefined
                }
              >
                {dataFilters.map((filter, index) => {
                  return (
                    <DataCard
                      key={filter.teamIdOverride ?? '' + index}
                      teamsList={teamsList}
                      settingsFilterManagerRef={settingsFilterManagerRef}
                      teamId={filter.teamIdOverride ?? teamId}
                      filter={filter}
                      updateFilterInputs={(filterInput) => updateFilterInputs(filterInput, index)}
                      deleteFilterInput={(teamId: number) => deleteFilterInput(teamId, index)}
                      index={index}
                      dataFilters={dataFilters}
                      setDataFilters={(dataFilters) => setDataFilters(dataFilters)}
                    />
                  );
                })}
              </EditChartInputRow>
              <EditChartInputRow name="Plot Unit">
                <div className="w-fit">
                  <DropDown
                    id="chart-plotunit-dropdown"
                    align={Align.Left}
                    dropDownData={PlotUnitMockList}
                    setSelectedItem={function (selectedItem: IDropDownItem): void {
                      setChartConfigs((prev) => {
                        return { ...prev, y_axis_data: selectedItem.value as Y_Axis_Data };
                      });
                    }}
                    selectedItem={PlotUnitMockList.find((item) => item.value === chartConfigs?.y_axis_data)}
                    fullWidthText
                  />
                </div>
              </EditChartInputRow>
              <EditChartInputRow name="Breakdown">
                <div className="w-fit">
                  <Tippy theme="dark" disabled={dataFilters.length <= 1} content={<p>You can only break down when there's one data series in the chart</p>}>
                    <div>
                      <DropDown
                        id="chart-breakdown-dropdown"
                        disabled={dataFilters.length > 1}
                        align={Align.Left}
                        dropDownData={segments && segments.length > 0 ? BreakdownMockList : BreakdownMockList.filter((b) => b.value !== Breakdown.Segment)}
                        setSelectedItem={function (selectedItem: IDropDownItem): void {
                          setChartConfigs((prev) => {
                            return { ...prev, breakdown: selectedItem.value as Breakdown };
                          });
                          const updatedDataFilters = cloneDeep(dataFilters);
                          updatedDataFilters[0].segmentGroupId = selectedItem.value === Breakdown.Segment ? updatedDataFilters[0].segmentGroupId : undefined;
                          setDataFilters(updatedDataFilters);
                        }}
                        selectedItem={BreakdownMockList.find((item) => item.value === chartConfigs?.breakdown)}
                        fullWidthText
                      />
                    </div>
                  </Tippy>
                  {chartConfigs?.breakdown === Breakdown.Segment ? (
                    <div className="mt-2">
                      <DropDown
                        align={Align.Left}
                        dropDownData={segments}
                        setSelectedItem={function (selectedItem: IDropDownItem): void {
                          const updatedDataFilters = cloneDeep(dataFilters);
                          updatedDataFilters[0].segmentGroupId = selectedItem?.id;
                          setDataFilters(updatedDataFilters);
                        }}
                        //@ts-ignore
                        selectedItem={segments?.find((segment) => {
                          return segment.id === chartConfigs.series?.[0].segmentGroupId;
                        })}
                      />
                    </div>
                  ) : null}
                </div>
              </EditChartInputRow>
              <EditChartInputRow name="Chart Tags">
                <div className="w-fit">
                  <div className="flex flex-row gap-x-2 gap-y-1 flex-wrap">
                    {tags?.map((tag) => {
                      return (
                        <Badge
                          key={tag.id}
                          capitalize={true}
                          badge={{ id: tag.id.toString(), text: tag.name }}
                          onRemove={(item) => {
                            setTags((tags) => tags.filter((t) => t.id !== Number(item.id)));
                          }}
                        />
                      );
                    })}
                    {addTagOpen ? (
                      !addLoading ? (
                        <div>
                          <ComboBox
                            comboBoxData={
                              ownerTagsData?.getTags
                                ?.filter((tag) => !tags.some((t) => t.id === tag.id))
                                .sort((a, b) => alphabeticalSort(a.name, b.name))
                                .map((tag) => {
                                  return { ...tag, name: capitalizeFirstLetter(tag.name) };
                                }) ?? []
                            }
                            defaultOption={true}
                            boxRef={ref}
                            setSelectedItem={(item) => {
                              if (item) {
                                if (!ownerTagsData?.getTags?.some((tag) => tag.id === item.id)) {
                                  setAddLoading(true);
                                  handleCreateTag(item.name, () => {
                                    setAddLoading(false);
                                  });
                                } else {
                                  setTags((tags) => [...tags, item]);
                                  setAddLoading(false);
                                }
                              }
                            }}
                          />
                        </div>
                      ) : (
                        <AdjustableLoadingIcon width={4} height={4} />
                      )
                    ) : (
                      <Tippy theme="dark" content={<p>Add another tag</p>}>
                        <div className="cursor-pointer" onClick={() => setAddTagOpen((prev) => !prev)}>
                          <Badge badge={{ id: '0', text: '+' }} id="add-tag" />
                        </div>
                      </Tippy>
                    )}
                  </div>
                </div>
              </EditChartInputRow>
              <EditChartInputRow name="Chart Owner">
                <div className="w-fit">
                  <p>{`${user?.firstName} ${user?.lastName}`}</p>
                </div>
              </EditChartInputRow>
            </div>
            <div className="col-span-8 pl-4 ">
              <div className="flex flex-col gap-y-6 sticky md:top-6 sm:top-16">
                <div className="flex flex-row justify-between ">
                  <FilterManager
                    pageName={''}
                    filterHook={datePreviewFilterHook}
                    dataTypeToFilter={'chartEditor'}
                    displayMode={FilterManagerDisplayMode.ChartEditorPreview}
                    filterButtonText="Add Filter"
                  />
                  <Tippy theme="dark" content="Change the x-axis range">
                    <div>
                      <DropDown
                        id="chart-bin-type-dropdown"
                        selectedItem={ChartBinTypes.find((item) => item.value === binType)}
                        dropDownData={ChartBinTypes}
                        setSelectedItem={(item) => {
                          if (item.value) {
                            setBinType(item.value as Chart_Bin_Type);
                          }
                        }}
                      />
                    </div>
                  </Tippy>
                </div>
                <div className="flex flex-col h-96" id="section">
                  {!displayReturnedChart ? (
                    currentChart ? (
                      <FullCustomChartCard
                        key={currentChart.id}
                        chartData={currentChart}
                        filterInput={editChartFilterHook.filters}
                        newCard={true}
                        loading={loadingStatues.creatingChart}
                      />
                    ) : (
                      <LoadingSpinner />
                    )
                  ) : null}
                  {mode === 'editor' && displayReturnedChart ? (
                    customChart ? (
                      <FullCustomChartCard
                        key={customChart.id}
                        chartData={customChart}
                        filterInput={editChartFilterHook.filters}
                        newCard={true}
                        loading={loadingStatues.creatingChart}
                      />
                    ) : (
                      <LoadingSpinner />
                    )
                  ) : null}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export const EditChartInputRow = ({
  name,
  children,
  onAdd,
  onRemove,
}: {
  name: string;
  children?: React.ReactNode;
  onAdd?: () => void;
  onRemove?: () => void;
}) => {
  return (
    <div className="flex flex-col gap-y-2">
      <div className="flex flex-row justify-between  text-blueberry">
        <h1 className="font-semibold">{name}</h1>
        {onAdd ? (
          <Tippy theme="dark" content={<p>You can add a new data series to the chart to compare data between views. </p>}>
            <div className="add-chart-data-series cursor-pointer" onClick={onAdd}>
              <PlusIcon className="h-4 w-4 stroke-2" />
            </div>
          </Tippy>
        ) : null}
        {onRemove ? (
          <Tippy theme="dark" content={<p>Remove current data series from chart</p>}>
            <div className="remove-chart-data-series cursor-pointer" onClick={onRemove}>
              <XMarkIcon className="h-4 w-4 stroke-2" />
            </div>
          </Tippy>
        ) : null}
      </div>

      <>{children}</>
    </div>
  );
};
