import { getGroupLink, GroupFull, writeToastMessage } from '../../../../v2/hooks/GroupHook';
import { FilterInput } from '../../../../generated/graphql';
import { useContext } from 'react';
import AppContext from '../../../../v2/contexts/AppContext';
import Tippy from '@tippyjs/react';
import { LinkIcon } from '@heroicons/react/24/outline';

export const CopyGroupToClipBoardButton = (props: { group: GroupFull, filters: FilterInput }) => {
  const { curOrgId, curTeamId } = useContext(AppContext);
  return <Tippy theme="dark" content="Link to Group" delay={200}>
    <div
      className="rounded-full  bg-silver  text-blueberry flex flex-row justify-center items-center cursor-pointer h-8 w-8 hover:scale-110 duration-150 hover:shadow-sm"
      onClick={async () => {
        const groupLink = getGroupLink(props.group.id, props.filters, curTeamId!, curOrgId!, 'group');
        await navigator.clipboard.writeText(groupLink);
        writeToastMessage('Copied group link to clipboard');
      }}
    >
      <div>
        <LinkIcon className="h-4 w-4 stroke-2" />
      </div>
    </div>
  </Tippy>;
};