import { PlusIcon } from '@heroicons/react/24/solid';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { Integration_Type } from '../../generated/graphql';
import { IIntegrationRedirect } from '../pages/IntegrationsPage';
import Button, { ButtonSize, ButtonVariant } from '../baseComponents/Button';

export const ExternalTicketsIntegrationCardBody = ({
  integration,
  redirect,
  openModalOrAuthRedirect,
}: {
  integration: Integration_Type;
  redirect?: IIntegrationRedirect;
  openModalOrAuthRedirect: (integration: Integration_Type, redirect?: IIntegrationRedirect) => void;
}) => {
  return (
    <div key={integration.id} className="divide-y divide-gray-200">
      <div className="block">
        <div className="flex items-center py-4">
          <div className="flex min-w-0 flex-1 items-center">
            <div className="flex-shrink-0">{integration.logoUrl && <img className="h-10 w-10" src={integration.logoUrl} alt="" />}</div>
            <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
              <div>
                <h1 className="text-md truncate font-medium text-blueberry">{integration.title}</h1>
                <p className="flex items-center text-sm text-gray-500">
                  <span className="truncate">{integration.description}</span>
                </p>
              </div>
              <div className="flex items-center"></div>
            </div>
          </div>
          <div>
            {integration.orgExternalTicketsIntegration.length > 0 ? (
              <Button
                variant={ButtonVariant.Tertiary}
                size={ButtonSize.Small}
                disabled={false}
                disabledTooltip="Only Admins can unlink connections."
                icon={<CheckCircleIcon className="h-3 w-3 stroke-2" />}
                iconPosition="left"
                text="Connected"
                onClick={() => openModalOrAuthRedirect(integration, redirect)}
              >
                <XCircleIcon className="h-5 w-5" />
              </Button>
            ) : (
              <Button
                text={'Connect'}
                variant={ButtonVariant.Tertiary}
                size={ButtonSize.Small}
                icon={<PlusIcon className="h-3 w-3 stroke-2" />}
                iconPosition="left"
                onClick={() => openModalOrAuthRedirect(integration, redirect)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
