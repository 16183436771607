import { useEffect, useState } from 'react';
import { GroupFull } from '../../../v2/hooks/GroupHook';
import { OwnerComboBox } from '../../baseComponents/OwnerComboBox';
import { ExploreGroupHook } from '../../hooks/ExploreGroupHook';
import { HomeGroupHook } from '../../hooks/HomeGroupHook';
import { IDropDownItem } from '../../baseComponents/DropDown';
import { useOrganizationHook } from '../../../v2/hooks/OrganizationHook';
import { alphabeticalSort } from '../../../v2/util';

const OwnerSelector = (props: { group: GroupFull | undefined; groupHook: ExploreGroupHook | HomeGroupHook }) => {
  const { groupHook, group } = props;
  const [ownerLoading, setOwnerLoading] = useState<boolean>();
  const [users, setUsers] = useState<IDropDownItem[]>([{ id: 0, name: '', displayName: 'None' }]);
  const { filteredOrgUsers } = useOrganizationHook();

  useEffect(() => {
    let isMount = true;
    if (filteredOrgUsers && users.length === 1) {
      // only update users once, when we get org users
      const users = filteredOrgUsers
        .map((user) => {
          return { id: user.user?.id ?? -1, name: user.email, displayName: `${user.user?.firstName ?? ''} ${user.user?.lastName ?? ''}` };
        })
        .sort((a, b) => alphabeticalSort(a.displayName, b.displayName));
      isMount && setUsers((prev) => [...users, ...prev]);
    }
    return () => {
      isMount = false;
    };
  }, [filteredOrgUsers]);

  if (!group) return <></>;
  return (
    <div className="flex flex-row col-span-5 xl:col-span-2 gap-x-1 items-start mt-0.5 ">
      <p className="font-semibold text-gray-500 mt-0.5">Owner:</p>
      <div id="owner-change" className="-mt-0.5">
        {
          <OwnerComboBox
            selectedItem={users.find((user) => {
              if (!group) return;
              const idToCheck = group.ownerId ?? 0;
              return user.id === idToCheck;
            })}
            comboBoxData={users}
            loading={ownerLoading || users.length === 1}
            setSelectedItem={(item) => {
              setOwnerLoading(true);
              if (!group) return;
              if (item == null || item.id === 0) groupHook.removeOwner(group.id, () => setOwnerLoading(false));
              else {
                if (!group) return;
                if (item.id !== -1) groupHook.updateOwner(group.id, item.id, () => setOwnerLoading(false));
              }
            }}
          />
        }
      </div>
    </div>
  );
};

export default OwnerSelector;
