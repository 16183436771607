import { FetchResult } from '@apollo/client';
import { SHA256 } from 'crypto-js';
import {
  CreateFeedbackSegmentGroupMutation,
  CreateFeedbackSegmentGroupMutationHookResult,
  FeedbackEntriesUploadMutation,
  FeedbackEntriesUploadMutationHookResult,
  FeedbackEntryInput,
  FeedbackIntegrationMutationHookResult,
  SegmentConfigInput,
  SegmentGroupLazyQueryHookResult,
  SegmentGroupQuery,
  SegmentType,
} from '../../generated/graphql';
import { ParsedCSV, CSVRow, CSVError, HeaderEntry } from './csvTypes';
import { FormatCSVSegments } from './segmentsUtils/formatCSVSegments';
import { IFilterRow } from '../../v3/sections/Filters/FiltersTypes';

export interface FeedbackSegmentConfig {
  __typename?: 'feedback_segment_config' | undefined;
  id: number;
  path: string;
}

type FeedbackEntry = FetchResult<FeedbackEntriesUploadMutation, Record<string, any>, Record<string, any>>;

interface CSVUploaderProps {
  createCSVSegmentGroup: CreateFeedbackSegmentGroupMutationHookResult[0];
  getAllSegGroups: SegmentGroupLazyQueryHookResult[0];
  createCSVFeedbackEntries: FeedbackEntriesUploadMutationHookResult[0];
  csvIntegrationMutation: FeedbackIntegrationMutationHookResult[0];
}

export class CSVUploader {
  private segmentFormatter: FormatCSVSegments;

  private createCSVSegmentGroup: CreateFeedbackSegmentGroupMutationHookResult[0];
  private getAllSegGroups: SegmentGroupLazyQueryHookResult[0];
  private createCSVFeedbackEntries: FeedbackEntriesUploadMutationHookResult[0];
  private csvIntegrationMutation: FeedbackIntegrationMutationHookResult[0];

  constructor(props: CSVUploaderProps) {
    this.createCSVSegmentGroup = props.createCSVSegmentGroup;
    this.getAllSegGroups = props.getAllSegGroups;
    this.createCSVFeedbackEntries = props.createCSVFeedbackEntries;
    this.csvIntegrationMutation = props.csvIntegrationMutation;

    this.segmentFormatter = new FormatCSVSegments();
  }

  async uploadSegmentGroups(csvWithoutSegmentMetaData: ParsedCSV, _teamId: number, segmentFilters: IFilterRow[]): Promise<ParsedCSV> {
    // case 0) no segments selected
    if (segmentFilters.filter((seg) => seg.isSelected).length === 0) {
      throw new CSVError(`Unexpected Error: Trying to upload segments when none are selected`);
    }

    // step 1) create segment groups and have them returned back
    const csvWithSegmentMetaData = this.segmentFormatter.generateSegmentGroupMetadata(csvWithoutSegmentMetaData, segmentFilters);

    await this.createNewSegmentGroups(csvWithSegmentMetaData, _teamId);

    const updatedSegGroups: SegmentGroupQuery | undefined = (await this.getAllSegGroups({ variables: { teamId: _teamId } })).data;

    // step 2) populate csvWithSegmentMetaData with groupIds
    const csvWithGroupIds: ParsedCSV = this.segmentFormatter.populateSegmentGroupIds(csvWithSegmentMetaData, updatedSegGroups);

    return csvWithGroupIds;
  }

  /**
   * @description creates new SegmentGroups for the csv
   * @param csvWithSegments a CSV containing segmentGroups that may need to be created
   * @param _teamId current team
   * @returns an Promise wrapping an array containing all new SegmentGroups
   * @throws CSVError if there are no segments or the segmentGroup name to write is null
   */
  private createNewSegmentGroups(csvWithSegments: ParsedCSV, _teamId: number): Promise<CreateFeedbackSegmentGroupMutation[]> {
    // case 0) no segments exist
    if (!csvWithSegments.segmentHeader) throw new CSVError(`Unexpected Error: Trying to create new segment groups with a csv containing no segments`);

    // step 1) get all segments that need to be created
    const newSegmentsToCreate = csvWithSegments.segmentHeader.segHeader.filter(
      (seg) => seg.segmentMetaData?.shouldUpload && !seg.segmentMetaData.doesSegGroupExist
    );

    // step 2) call the `SegmentGroup` Mutation
    const groupResultsPromises: Promise<CreateFeedbackSegmentGroupMutation | null | undefined>[] = newSegmentsToCreate.map((newSegGroup) => {
      const targetDisplayName = newSegGroup.segmentMetaData?.segmentGroup;

      // case 2a) the targetDisplayName in metadata is null
      if (!targetDisplayName) {
        throw new CSVError(`Unexpected Error: Attempting to create new segment for ${newSegGroup.fieldTitle} but targetDisplayName is null`);
      }

      // case 2b) targetDisplayName exists
      return this.createCSVSegmentGroup({
        variables: {
          teamId: _teamId,
          valueType: SegmentType.String,
          displayName: targetDisplayName,
        },
      })
        .then((result) => result.data)
        .catch((e) => {
          throw e;
        });
    });

    // step 3) resolve the promises created
    const groupResults: Promise<CreateFeedbackSegmentGroupMutation[]> = Promise.all(groupResultsPromises).then(
      (results) => results.filter((result) => !!result) as CreateFeedbackSegmentGroupMutation[]
    );

    return groupResults;
  }

  /**
   * @description creates a `FeedbackIntegration` and populates segmentConfigs if segments exist
   * @param parsedCSV a CSV that may contain SegmentGroupIDs if segments are defined
   * @param _teamId current team
   * @returns the original parsedCSV if there are no segments, or a csv with segmentsConfigIds if segments are present
   */
  public async uploadIntegrationAndConfigIds(parsedCSV: ParsedCSV, _teamId: number): Promise<ParsedCSV> {
    // create CSV Integration
    const [integrationId, segConfigs]: [number, FeedbackSegmentConfig[]] = await this.createCSVIntegration(parsedCSV, _teamId);

    // update the csv's integration_id
    parsedCSV.feedback_integration_id = integrationId;

    // case 1) no segments exist
    if (segConfigs.length === 0) {
      return parsedCSV;
    }

    // case 2) segments exist, link the csv to the newly written segmentConfigs
    const csvWithSegmentConfigIds = this.segmentFormatter.populateConfigIds(parsedCSV, segConfigs);

    return csvWithSegmentConfigIds;
  }

  /**
   * @description creates a `FeedbackIntegration` and returns any segmentConfigs created
   * @param parsedCSVData csv which may or may not contain segment data
   * @param _teamId the current team
   * @returns a tuple with the FeedbackIntegration_ID and the Segments Configuration
   * @throws a CSVError if the IntegrationResult.data field is undefined
   */
  async createCSVIntegration(parsedCSVData: ParsedCSV, _teamId: number): Promise<[number, FeedbackSegmentConfig[]]> {
    let segmentConfigs: SegmentConfigInput[] | null = null;
    // step 1) CSV has segments
    if (parsedCSVData.segmentHeader) {
      // create SegmentsConfigInput payload
      segmentConfigs = parsedCSVData.segmentHeader.segHeader
        .filter((seg) => seg.segmentMetaData?.shouldUpload)
        .map((seg: HeaderEntry) => {
          // case 2a) segments exist but metaData has not been created
          if (!seg.segmentMetaData) {
            throw new CSVError(`Unexpected Error: ${seg.fieldTitle} has no segmentMetaData`);
          }

          // case 2b) segmentsMetaData exists
          return {
            segmentGroupId: seg.segmentMetaData.groupId,
            fieldPath: seg.fieldTitle,
          } as SegmentConfigInput;
        }) as SegmentConfigInput[];
    }

    // step 2) create integration
    const integrationResult = await this.csvIntegrationMutation({
      variables: {
        input: {
          teamId: _teamId,
          integrationTypeId: 8, // todo check that hardcoding thie value is ok
          requirements: [], // CSVs have all the requirements specified on the UI
          segments: segmentConfigs,
        },
      },
    });

    if (!integrationResult.data) {
      throw new CSVError(`Unexpected Error: CSV Feedback Integration Failed`);
    }
    return [integrationResult.data.feedbackIntegration.id, integrationResult.data.feedbackIntegration.segmentConfig];
  }

  public async uploadCSVRows(parsedCSVData: ParsedCSV, sourceName: string, _teamId: number, concatenationSeparator: string): Promise<number> {
    const integration_id = parsedCSVData.feedback_integration_id;
    if (!integration_id) throw new CSVError(`Error: FeedbackIntegration not created`);

    // batch the parsedCSV into batches of size `uploadRowBatchSize`
    const uploadRowBatchSize: number = 1000;
    let batchedCsvRows: CSVRow[][] = [];
    for (let i: number = 0; i < parsedCSVData.rows.length; i += uploadRowBatchSize) {
      batchedCsvRows.push(parsedCSVData.rows.slice(i, i + uploadRowBatchSize));
    }

    const batchedUpload: Promise<FeedbackEntry>[] = batchedCsvRows.map((aBatch: CSVRow[]) => {
      const aFeedbackEntryBatch: FeedbackEntryInput[] = aBatch.map((aRow: CSVRow) => {
        return {
          providerUniqueId: aRow.id === '' ? createIdHash(aRow) : aRow.id,
          title: aRow.title,
          fullText: aRow.details,
          feedbackDate: aRow.date,
          feedbackSubmitterAlias: aRow.user,
          stars: aRow.stars,
          dataSource: sourceName,
          teamId: _teamId,
          feedbackIntegrationId: integration_id,
          dataSourcePermalink: aRow.source_url,
          segments: this.segmentFormatter.createFeedbackSegmentInput(aRow, parsedCSVData.segmentHeader?.segHeader, concatenationSeparator),
        } as FeedbackEntryInput;
      });

      return this.createCSVFeedbackEntries({
        variables: {
          teamId: _teamId,
          feedbackIntegrationId: integration_id,
          input: aFeedbackEntryBatch,
        },
      });
    });

    const resolved_batched_entries: FeedbackEntry[] = await Promise.all(batchedUpload.flat());

    // calculate the total number (should be the same as the row count) in case any rows failed or due to dupes
    const ids = new Set<number>(
      nullGuard(resolved_batched_entries.flatMap((e) => e.data?.entriesUpload.map((entry) => entry.id)).filter((id) => id !== undefined))
    );
    return [...ids].length;
  }
}

// Does Array filtering of null and undefined values in a way that TS recognizes the result as an array of type T and not T | null | undefined
const nullGuard = <T>(inputArray: (T | null | undefined)[]): T[] => {
  return inputArray.filter((item) => !!item) as T[];
};

// UNIQUE ID UTILITIES
/**
 * @description creates an ID for the row entered as a hash of its contents concatenated with the date
 * @param rowEntry a row of the CSV
 * @returns a hexidecimal shaw256 hash
 */
function createIdHash(rowEntry: CSVRow): string {
  // step 1) concatenate all fields in the row
  const contentToHash = Object.entries(rowEntry)
    // cast each field to a string
    .map(([fieldName, fieldValue]: [string, keyof CSVRow]) => String(fieldValue))
    // concat them together
    .join('');

  // step 2) create a hash object of the row values concatentated with the date
  const rowHash = SHA256(contentToHash);

  // step 3) concatenate hash with the date
  const finalHash: string = rowHash.toString().concat(' ').concat(rowEntry.date);

  return finalHash;
}
