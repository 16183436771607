import { TaxonomyGroup } from '../../v2/hooks/GroupHook';
import { FilterInput, GroupDataFragment } from '../../generated/graphql';
import { useContext, useState } from 'react';
import { TaxonomyContext } from '../context/TaxonomyContext';
import { TaxonomyFolder } from './TaxonomyFolder';
import { useValidTeamAppContext } from '../../v2/contexts/AppContext';
import { useInView } from 'react-intersection-observer';
import { SearchPreviewContext } from '../context/SearchPreviewContext';
import { GroupBreadcrumbs } from './taxonomy/GroupBreadcrumbs';
import Button, { ButtonVariant } from '../baseComponents/Button';
import { BuildGroupButton } from './Search/BuildGroupButton';

/**
 * Displays the results of a semantic search on the Group's centroid text.
 *
 * This component is very similar to the Taxonomy view only the taxonomy folders gere will not have children
 * as they are not relevant to the semantic search results.
 *
 * We only care about the parent level groups that are returned from the search. The reasoning is that a child is an
 * instance of a parent group and if the child group is relevant to the search, then the parent group will also be relevant.
 *
 * @param props
 * @constructor
 */
export function GroupSemanticSearchResults(props: {
  copyGroupLink: (groupId: number) => string,
  updateProgress: (groupId: number, newProgress: number) => void,
  filterInput: FilterInput,
  replaceOrAddToSearchGroups: (group: GroupDataFragment) => void,
  togglePinGroup: (groupId: number, cb?: () => void) => void,
  setCurrentGroupId: (groupId: number) => void,
}) {
  const [activeId, setActiveId] = useState<number | undefined>(undefined);
  const taxonomyGroups = useContext(TaxonomyContext);
  const { curTeamId: teamId, curOrgId: orgId, currentOrg } = useValidTeamAppContext();
  const { ref } = useInView({ threshold: 0 });
  const searchTerm = useContext(SearchPreviewContext).searchTerm;

  const searchResults = Array.from(taxonomyGroups.values()).map((group: TaxonomyGroup) => {
    return (
      <div>
        <TaxonomyFolder
          isSearchResult={true}
          trending={group.trending}
          replaceOrAddToSearchGroups={props.replaceOrAddToSearchGroups}
          updateProgress={props.updateProgress}
          taxonomyGroup={taxonomyGroups.get(group.id)!}
          itemRef={ref}
          key={group.id}
          children={[]}
          copyLink={props.copyGroupLink}
          teamId={teamId}
          setActiveId={(id) => {
            setActiveId(id);
          }}
          togglePinGroup={props.togglePinGroup}
          setCurrentGroupId={(id) => {
            // do nothing for now
            props.setCurrentGroupId(id);
          }}
          zIndex={activeId === group.id ? 'z-20' : 'z-0'}
          filterInput={props.filterInput ?? {}}
          depth={0}
        />
      </div>
    );
  });

  if (searchResults.length === 0) return (
    <div className="mt-2 flex w-full flex-col items-center gap-y-2 pt-2">
      <div className="mt-2 flex w-full flex-col items-center gap-y-4 pt-2 text-blueberry">
        <h1>No search groups match the given search term and selected filters.</h1>
        <BuildGroupButton filterInput={props.filterInput}/>
      </div>
    </div>
  );

  return (
    <div>
      <div className="mt-2 flex w-full flex-col items-center gap-y-2 pt-2 text-blueberry">
        <p>Showing the most similar Groups to "{searchTerm}" below. </p>
        <BuildGroupButton filterInput={props.filterInput} />
      </div>
      {searchResults}
    </div>
  );
}
