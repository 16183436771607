import { PlusIcon } from '@heroicons/react/24/solid';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { Integration_Type } from '../../generated/graphql';
import { IIntegrationRedirect } from '../pages/IntegrationsPage';

export const FeedbackIntegrationCardBody = ({
  integration,
  redirect,
  openModalOrAuthRedirect,
}: {
  integration: Integration_Type;
  redirect?: IIntegrationRedirect;
  openModalOrAuthRedirect: (integration: Integration_Type, redirect?: IIntegrationRedirect) => void;
}) => {
  return (
    <div
      className="group col-span-1 flex h-full cursor-pointer select-none flex-col gap-y-3 divide-y divide-gray-300 rounded-3xl bg-silver  text-center text-blueberry duration-300 "
      onClick={() => openModalOrAuthRedirect(integration, redirect)}
    >
      <div className="integration-content flex flex-1 flex-col justify-center gap-y-3 p-8">
        <img className="mx-auto h-auto w-10 flex-shrink-0" src={integration.logoUrl} alt="" />
        <h1 className="text-xl font-semibold">{integration.title}</h1>
        <h1 className="text-md font-normal">{integration.description}</h1>
      </div>
      <div>
        <div className="-mt-px flex divide-x divide-gray-200">
          <div className="flex w-0 flex-1">
            <div className="text-md relative -mr-px inline-flex w-0 flex-1 cursor-pointer items-center justify-center rounded-b-3xl border border-transparent bg-blueberry  py-4 text-sm font-medium  text-white duration-300 group-hover:bg-raspberry ">
              {(integration.teamIntegration ?? integration.orgExternalTicketsIntegration ?? []).length > 0 ? (
                <>
                  <h1 className="mr-1 font-semibold">Connected</h1>
                  <CheckCircleIcon className="h-4 w-4 " aria-hidden="true" />
                </>
              ) : (
                <>
                  <h1 className="mr-1 font-semibold">Connect</h1>
                  <PlusIcon className="h-4 w-4 " aria-hidden="true" />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
