import React, { useContext, useEffect, useState } from 'react';
import { AssistantDataContext, AssistantDispatchContext } from '../../../context/assistantContext';
import { AssistantActions, AssistantStatus } from '../../../reducers/assistant/AssistantDispatch';
import { ChatClient } from '../../lib/chatClient';
import { mapObject } from 'underscore';
import { UserInput } from './UserInput';
import { AnswerSection } from './AnswerSection';
import { logError } from '../../../applicationTelemetry';
import AppContext from '../../../v2/contexts/AppContext';
import {
  SuggestedQuestionsDataContext,
  SuggestedQuestionsDispatchContext,
} from '../../../context/suggestedQuestionsContext';
import {
  SuggestedQuestionsActions,
  SuggestedQuestionsStatus,
} from '../../../reducers/assistant/SuggestedQuestionsDispatch';
import { useNavigate } from 'react-router';

export const FeedbackAssistant = () => {
  const {curTeamId: teamId} = useContext(AppContext);
  const [prevTeam, setPrevTeam] = useState<number>(-1);
  const dispatch = useContext(AssistantDispatchContext);
  const suggestedQuestionsDispatch = useContext(SuggestedQuestionsDispatchContext);
  const { status, question, questionId } = useContext(AssistantDataContext);
  const client = ChatClient.getInstance();
  const {load, page} = useContext(SuggestedQuestionsDataContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!teamId) {
      navigate('/dashboard/home'); // Redirect to the dashboard/home route
    }
  }, [teamId, navigate]);

  useEffect(() => {

    if(prevTeam < 0) {
      setPrevTeam(teamId!);
    } else if(prevTeam != teamId) {
      dispatch({ type: AssistantActions.RESET_ASSISTANT, payload: {} });
      setPrevTeam(teamId!);
    }

    if(load) {
      suggestedQuestionsDispatch({
        type: SuggestedQuestionsActions.UPDATE_SUGGESTED_QUESTIONS_STATUS,
        payload: {
          status: SuggestedQuestionsStatus.loading
        }
      });

      client.generateQuestions({teamId: teamId!, page: page}).then((response) => {
        suggestedQuestionsDispatch({
          type: SuggestedQuestionsActions.UPDATE_SUGGESTED_QUESTIONS,
          payload: {
            questions: response.data.questions
          }
        });
      }).catch((e) => {
        logError(e);
        suggestedQuestionsDispatch({
          type: SuggestedQuestionsActions.UPDATE_SUGGESTED_QUESTIONS_STATUS,
          payload: {
            status: SuggestedQuestionsStatus.failed
          }
        });
      });
    }
    switch (status) {
      case AssistantStatus.loading:

        client.abort(); // abort a previous query if running
        client.streamAnswer({ question: question, teamId: teamId!,
          onData: (data) => {
            const serverComponents = data.components;
            mapObject(serverComponents, (serverComponent, id) => {
              dispatch({ type: AssistantActions.UPDATE_COMPONENT, payload: { ...serverComponent, id } });
            });
          },
          onDone: () => {
            dispatch({ type: AssistantActions.UPDATE_ASSISTANT_STATUS, payload: { status: AssistantStatus.done } });
          }
        }).catch((e) => {
          if(e.message == 'network error') {
            dispatch({ type: AssistantActions.UPDATE_ASSISTANT_STATUS, payload: { status: AssistantStatus.failed } });
            return;
          }

          // This will happen when a user submits a query while an existing one is streaming.
          // We do not want to show an error, so we gracefully exit
          if(e.name == 'AbortError') return;

          // We intentionally ignore these errors, we allow users to abort network calls.
          if (e instanceof DOMException || e == 'NetworkCallAborted') {
            return;
          }
          else {
            throw e;
          }
        });
        break;
      default:
        return;
    }
  }, [load, questionId, teamId]);


  return <div className={'px-20'}>
    <div className="gap-y-4">
      <UserInput />
    </div>
    <AnswerSection />
  </div>
}
