import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useContext, useEffect, useReducer, useRef, useState } from 'react';
import { getFiltersStatsMode, useIsMount } from '../../v2/util';
import { ExploreGroupHook } from '../hooks/ExploreGroupHook';
import AppContext from '../../v2/contexts/AppContext';
import Button, { ButtonSize, ButtonVariant } from '../baseComponents/Button';
import { AppRoutes, V3_DASHBOARD } from '../../Routes';
import { useFilterHook } from '../hooks/FilterHook';
import { useClickOutside } from '../../utilities';
import { GroupFull } from '../../v2/hooks/GroupHook';
import { FilterManager, FilterManagerDisplayMode } from '../sections/Filters/FilterManager';
import { GroupDeleter } from '../components/GroupDeleter';
import UserContext from '../../v2/contexts/UserContext';
import { HomeGroupHook } from '../hooks/HomeGroupHook';
import { FilterInput, Insight_Collection_Type } from '../../generated/graphql';
import 'tippy.js/themes/light.css';
import { PageWrapper } from './PageWrapper';
import { ScrollToTop } from '../baseComponents/ScrollToTop';
import { ProgressState, Status } from '../../exports/ProgressMonitor';
import { TaxonomyContext } from '../context/TaxonomyContext';
import { GroupEntries } from '../components/groups/GroupEntries';
import { GroupToolBar } from '../components/groups/GroupToolBar';
import { GroupPageDataFetch } from '../components/groups/GroupPageDataFetch';
import GroupTitleBar from '../components/groups/GroupTitleBar';
import GroupPageInfoBox from '../components/groups/GroupPageInfoBox';
import OwnerSelector from '../components/groups/OwnerSelector';
import GroupTagSelector from '../components/groups/GroupTagSelector';
import CreateInsightFromCollectionModal from '../components/digests/insights/CreateInsightFromCollectionModal';
import { GroupDataContext, GroupDispatchContext, defaultGroupState } from '../../context/groupContext';
import { GroupReducer } from '../../reducers/group/GroupDispatch';

interface GroupPageProps {
  groupHook: ExploreGroupHook | HomeGroupHook;
  currentFilters?: FilterInput;
}

const GroupPage = ({ groupHook, currentFilters }: GroupPageProps) => {
  const [groupData, dispatch] = useReducer(GroupReducer, defaultGroupState);
  return (
    <GroupDispatchContext.Provider value={dispatch}>
      <GroupDataContext.Provider value={groupData}>
        <GroupPageActual groupHook={groupHook} currentFilters={currentFilters} />
      </GroupDataContext.Provider>
    </GroupDispatchContext.Provider>
  );
};

const GroupPageActual = ({ groupHook, currentFilters }: GroupPageProps) => {
  const { user } = useContext(UserContext);

  const group = groupHook.currentGroup as GroupFull | undefined; //We need to do something with this to avoid having to check for a valid group every time, but not sure what. Maybe at a higher level?
  const { groupId: URLGroupId } = useParams();
  const { curTeamId, curOrgId } = useContext(AppContext);
  const params = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const location = useLocation();
  const openTaxonomyOnLoad = location.search.includes('taxonomy=true');
  const teamId = params.get('teamId') != null ? Number(params.get('teamId')) : curTeamId ?? -1; // default to current teamId, but should check belonging via link for proper access denied redirect
  const filterHook = useFilterHook({
    teamId,
    orgId: curOrgId ?? -1,
    startingFilterInput: location.pathname.includes('group=') ? undefined : group?.filterInput ? group.filterInput : currentFilters,
  });
  const [deleteGroupModalOpen, setDeleteGroupModalOpen] = useState<boolean>(false);
  const [showShadow, setShowShadow] = useState(false);

  GroupPageDataFetch({ teamId, groupId: Number(URLGroupId), filterInput: filterHook.filters });

  const redirectIfTeamIdSet = () => {
    const params = new URLSearchParams(window.location.search);
    if (params.get('teamId') != null) {
      navigate(`/${V3_DASHBOARD}${AppRoutes.denied}`);
    } else navigate(AppRoutes.v3FullPath.explore);
  };

  const [taxonomyOpen, setTaxonomyOpen] = useState<boolean>(openTaxonomyOnLoad ?? false);
  const taxonomyButtonRef = useRef<HTMLButtonElement>(null);
  const taxonomyRef = useRef<HTMLDivElement>(null);
  const [digestPanelOpen, setDigestPanelOpen] = useState<boolean>(false);
  useClickOutside([taxonomyButtonRef, taxonomyRef], () => setTaxonomyOpen(false));
  const [exportProgress, updateExportProgress] = useState<ProgressState>({ status: Status.idle, percent: 0 });
  const removeTaxonomyOpen = () => {
    let searchParams = new URLSearchParams(window.location.search);
    searchParams.delete('taxonomy');
    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };
  useEffect(() => {
    let isMounted = true;
    const params = new URLSearchParams(window.location.search);
    const teamId = params.get('teamId') != null ? Number(params.get('teamId')) : curTeamId ?? -1; // default to current teamId, but should check belonging via link for proper access denied redirect
    if (isMounted) {
      groupHook.getCurrentGroup(teamId, filterHook.filters, Number(URLGroupId)!, undefined, redirectIfTeamIdSet);
    }
    return () => {
      isMounted = false;
    };
  }, [URLGroupId]);

  useEffect(() => {
    if (currentFilters) filterHook.setFilters(currentFilters);
  }, [currentFilters]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setShowShadow(true);
      } else {
        setShowShadow(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    groupHook.updateCurrentGroupFilter?.(filterHook.filters);
    if (!isFirstRender) removeTaxonomyOpen();
  }, [filterHook.filters]);

  const isFirstRender = useIsMount();
  useEffect(() => {
    if (isFirstRender) window.scrollTo(0, 0); //To fix Explore page positioning
  }, []);

  useEffect(() => {
    if (!isFirstRender) navigate(AppRoutes.v3FullPath.explore, { replace: true });
  }, [curTeamId]);

  const taxonomy = useContext(TaxonomyContext);

  return (
    <PageWrapper
      title=""
      disableMx
      backButton={
        <div className="mx-8">
          <Button
            id="back-button"
            size={ButtonSize.Small}
            variant={ButtonVariant.Tertiary}
            text="Back"
            onClick={() => {
              location.pathname.includes('home') ? navigate(AppRoutes.v3FullPath.home) : navigate(AppRoutes.v3FullPath.explore);
            }}
            icon={<p>{'<'}</p>}
            iconPosition="left"
          />
        </div>
      }
    >
      <ScrollToTop />
      {deleteGroupModalOpen && group?.id ? (
        <GroupDeleter
          groupToDelete={group.id}
          closeCallback={() => {
            setDeleteGroupModalOpen(false);
          }}
          deleteGroup={groupHook.discardGroup}
          deleteCallback={() => {
            setDeleteGroupModalOpen(false);
            navigate('/dashboard/explore', { replace: true });
          }}
          loadingDelete={groupHook.loadingStatuses.discardingGroup}
        />
      ) : null}

      <div className="z-10 top-0 text-blueberry flex flex-col gap-y-8">
        {/* {!group ? (
          <div className="flex justify-center items-center ">
            <LoadingSpinner />
          </div>
        ) : ( */}
        <div className="w-full">
          {group ? (
            <CreateInsightFromCollectionModal
              isModalOpen={digestPanelOpen}
              setOpen={setDigestPanelOpen}
              teamId={teamId}
              orgId={curOrgId!}
              filterInput={filterHook.filters}
              collection={{ id: group.id, type: Insight_Collection_Type.Group }}
            />
          ) : (
            <></>
          )}
          <div className={`sticky top-0 z-20 pr-0 bg-milk transition-all duration-150 ${showShadow ? 'shadow-[0_6px_12px_-12px_rgba(0,0,0,0.6)]' : ''}`}>
            <div className="mx-8 flex flex-row py-2 bg-milk justify-between items-start gap-x-8">
              <GroupTitleBar
                mentions={
                  group
                    ? {
                        totalEntries: group.totalEntries,
                        relativeShare: group.relativeShare,
                        relativeShareFull: group.relativeShareFull,
                        filteredStatsMode: getFiltersStatsMode(filterHook.filters),
                      }
                    : undefined
                }
              />
              <GroupToolBar
                key={`groupSubscription`}
                group={group}
                filterHook={filterHook}
                groupHook={groupHook}
                exportProgress={exportProgress}
                showDigestButton={user?.isUnwrapper ?? false}
                updateExportProgress={updateExportProgress}
                taxonomy={taxonomy}
                toggleDigestModal={() => setDigestPanelOpen((curVal) => !curVal)}
                toggleDeleteGroupModal={() => setDeleteGroupModalOpen(true)}
              />
            </div>
          </div>

          <div className="flex flex-row justify-between items-start mx-8"></div>
          <div className="flex flex-col mt-4 mx-8">
            <GroupPageInfoBox
              chart={
                group
                  ? {
                      aggregateData: group.aggregateData!,
                      normalizedData: group.normalizedData!,
                      tooltipLabels: group.tooltipLabels!,
                      chartLabels: group.chartLabels!,
                    }
                  : undefined
              }
              filters={filterHook.filters}
            />
            <div className="grid grid-cols-10 justify-between gap-y-2 mt-4 -mx-1 px-1">
              <div className="flex col-span-10 xl:col-span-6">
                <FilterManager pageName={''} filterHook={filterHook} dataTypeToFilter={'groupPage'} displayMode={FilterManagerDisplayMode.GroupPage} />
              </div>

              <OwnerSelector group={group} groupHook={groupHook} />
              <GroupTagSelector group={group} groupHook={groupHook} />
            </div>
          </div>
          <GroupEntries groupHook={groupHook} filterHook={filterHook} />
        </div>
        {/* )} */}
      </div>
    </PageWrapper>
  );
};

export default GroupPage;
