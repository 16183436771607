import { FilterInput } from '../../generated/graphql';
import { classNames } from '../../v2/util';
import ClusterSparkChart from './ClusterSparkChart';
import moment from 'moment';
import { useSearchGroupPolling } from '../../v2/hooks/ExploreHook';
import { ITag, GroupFull } from '../../v2/hooks/GroupHook';
import GroupHeader from './GroupHeader';
import { GroupUIType } from '../pages/ExplorePage';
import { useState } from 'react';
import { useValidTeamAppContext } from '../../v2/contexts/AppContext';
interface GroupPreviewProps {
  group: GroupFull;
  filterInput?: FilterInput;
  artificialStartDate?: Date;
  showPreviewOverlay?: boolean;
  page: string;
  isCondensedView: boolean;
  refProp?: any;
  discardSearchGroup: (groupId: number) => void;
  togglePinGroup: (groupId: number) => void;
  replaceOrAddToSearchGroups: (searchGroup: any) => void;
  getAllGroupSentences: () => Promise<void>;
  loadingAllSentences: boolean;
  updateProgress: (searchGroupId: number, newProgress: number) => void;
  deleteSentence?: (groupId: number, sentenceId: number, cb: () => void) => void;
  addSentence?: (groupId: number, sentence: any, cb: () => void) => void;
  onSeeMoreClick?: () => void;
  tags?: ITag[];
  copyLink: (groupId: number) => void;
  editTitle: (groupId: number, title: string) => void;
  openAnnouncementModal: () => void;
}

const GroupPreview = ({
  group,
  filterInput,
  isCondensedView,
  artificialStartDate,
  showPreviewOverlay,
  discardSearchGroup,
  togglePinGroup,
  replaceOrAddToSearchGroups,
  updateProgress,
  onSeeMoreClick,
  copyLink,
  editTitle,
  openAnnouncementModal,
  refProp,
}: GroupPreviewProps): JSX.Element => {
  const { curTeamId: teamId } = useValidTeamAppContext();
  const [percentChange, setPercentChange] = useState<number | undefined>(undefined);
  const showHeaderIcon = group.isNew !== undefined && isCondensedView;

  useSearchGroupPolling({
    teamId,
    searchGroupId: group.id,
    replaceOrAddToSearchGroups,
    searchProcessing: group.processing ?? false,
    updateProgress,
    filterInput,
  });
  return (
    <>
      <div
        id="group-preview-card"
        className={classNames(`relative flex w-full flex-col bg-milk text-blueberry duration-300 ${!group.processing ? 'cursor-pointer' : ''}`)}
        onClick={() => (!group.processing ? onSeeMoreClick && onSeeMoreClick() : undefined)}
        ref={refProp}
      >
        <div className={classNames(showPreviewOverlay && 'select-none blur-sm')}>
          <div className="shadow-md shadow-gray-200 rounded-3xl bg-silver">
            <GroupHeader
              group={group}
              isCondensedView={isCondensedView}
              editable={false}
              showTags={!isCondensedView}
              type={GroupUIType.Card}
              togglePinGroup={togglePinGroup}
              discardSearchGroup={discardSearchGroup}
              margin={{ t: 0, b: 0, x: 0 }}
              padding={{ b: 2, t: 3, x: 6 }}
              rounded={true}
              percentChange={percentChange}
              copyLink={copyLink}
              editTitle={editTitle}
              openAnnouncementModal={openAnnouncementModal}
              filterInput={filterInput}
            />
            {group.aggregateData && !group.processing && (
              <div className="px-6 py-3">
                <ClusterSparkChart
                  artificialStartDate={artificialStartDate}
                  filterInput={filterInput ? filterInput : { startDate: moment().subtract(360, 'days').startOf('day').toDate(), endDate: moment().toDate() }}
                  disableAnnotations={isCondensedView}
                  setPercentChangeOfLastTwoDataPoints={showHeaderIcon ? setPercentChange : undefined}
                  aggregateData={group.aggregateData}
                  normalizedData={group.normalizedData}
                  tooltipLabels={group.tooltipLabels}
                  chartLabels={group.chartLabels}
                />
              </div>
            )}
            {group.processing && (
              <div className="flex items-center justify-center gap-x-4 py-6 text-blueberry">
                <div className="h-8 w-8 animate-spin rounded-full border-t-2 border-blueberry"></div>
                {group.progress === 0 ? (
                  <h1 className="text-xl">Estimating time remaining...</h1>
                ) : (
                  <h1 className="text-xl">Building group... {group.progress != null && group.progress}%</h1>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default GroupPreview;
