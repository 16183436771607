import { GroupFull } from '../../../../v2/hooks/GroupHook';
import { GroupSubscriptionPopover } from '../../Popovers/GroupSubscriptionPopover';
import Tippy from '@tippyjs/react';
import { BellAlertIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';

export function ConfigureGroupSubscriptionsButton(props: {
  group: GroupFull,
}) {
  const [popoverKey, setPopoverKey] = useState<number>(0);

  return <div
    id={`group-subscriptions`}
    onClick={(event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
    }}
  >
    <GroupSubscriptionPopover
      groupId={props.group?.id ?? -1}
      updateKey={setPopoverKey}
      key={popoverKey}
      customIconElem={
        <Tippy content="Configure Subscriptions" delay={200}>
          <div
            className="rounded-full  bg-silver  text-blueberry flex flex-row justify-center items-center cursor-pointer h-8 w-8 hover:scale-110 duration-150 hover:shadow-sm focus:outline-none">
            <div>
              <BellAlertIcon className="h-4 w-4 stroke-2" />
            </div>
          </div>
        </Tippy>
      }
    />
  </div>;
}