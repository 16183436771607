import { toast } from 'react-hot-toast';
import { useState } from 'react';
import { ExternalTicketData, JiraProject, useGetJiraProjectsLazyQuery, useGetJiraTicketsLazyQuery } from '../../../../generated/graphql';
import { useValidTeamAppContext } from '../../../../v2/contexts/AppContext';
import { BrowsingSection } from './BrowsingSection';
import { TicketLinker } from './TicketLinker';
import { toastTicketBrowsingError, toastTicketBrowsingPersistent, toastTicketBrowsingSuccess } from './helpers';

export const JiraBrowsing = ({
  linkTicket,
  linkingTicket,
}: {
  linkTicket: ({ ticketData }: { ticketData: ExternalTicketData }) => Promise<void>;
  linkingTicket?: boolean;
}) => {
  const { curOrgId: orgId } = useValidTeamAppContext();

  const [selectedProject, setSelectedProject] = useState<JiraProject | null>(null);
  const [selectedTicketData, setSelectedTicketData] = useState<ExternalTicketData | null>(null);

  const [getJiraProjects, jiraProjects] = useGetJiraProjectsLazyQuery({});
  const [getJiraTickets, jiraTickets] = useGetJiraTicketsLazyQuery({});

  const allProjects = jiraProjects.data?.getJiraProjects ?? [];
  const allTickets = jiraTickets.data?.getJiraTickets ?? [];

  const searchJiraProject = async (queryString: string | undefined) => {
    if (!queryString) {
      setSelectedProject(null);
      return;
    }
    const id = toastTicketBrowsingPersistent();
    await getJiraProjects({
      variables: { queryString, orgId },
      onCompleted: () => toastTicketBrowsingSuccess(),
      onError: () => toastTicketBrowsingError(),
    });
    toast.dismiss(id);
  };

  const searchJiraIssue = async (queryString: string | undefined) => {
    if (!queryString || !selectedProject) {
      setSelectedTicketData(null);
      return;
    }
    const id = toastTicketBrowsingPersistent();
    await getJiraTickets({
      variables: { queryString, orgId, projectId: selectedProject.providerUniqueId },
      onCompleted: () => toastTicketBrowsingSuccess(),
      onError: () => toastTicketBrowsingError(),
    });
    toast.dismiss(id);
  };

  return (
    <div className="flex flex-col gap-y-2 px-2">
      <BrowsingSection
        title="Project"
        searchPlaceholder="Search Jira projects"
        comboBoxPlaceholder="project"
        onSearch={searchJiraProject}
        data={allProjects}
        selectedItem={selectedProject}
        setSelectedItem={setSelectedProject}
        loading={jiraProjects.loading}
      />
      <BrowsingSection
        title="Issue"
        searchPlaceholder="Search Jira issues"
        comboBoxPlaceholder="issue"
        onSearch={searchJiraIssue}
        data={allTickets}
        selectedItem={selectedTicketData}
        setSelectedItem={setSelectedTicketData}
        loading={jiraTickets.loading}
        disabled={!selectedProject}
      />
      <TicketLinker
        selectedTicketData={selectedTicketData}
        linkTicket={linkTicket}
        integrationLogo={'https://unwrap-public-assets.s3.us-east-2.amazonaws.com/jira-logo.png'}
        integrationTitle="Jira"
        loading={linkingTicket}
      />
    </div>
  );
};
