import { ApolloError } from '@apollo/client';
import {
  Integration_Type,
  Integration_Type_Requirement,
  useFeedbackIntegrationMutation,
  useIntegrationConnectedLazyQuery,
} from '../../../../../../generated/graphql';
import { BaseIntegrationForm } from './BaseIntegrationForm';
import { useValidTeamAppContext } from '../../../../../../v2/contexts/AppContext';
import { getIntegrationRequirementsToSend } from './helpers';

interface QueryRes {
  success: boolean;
  error: string | null;
}

interface IntegrationFormComponentProps {
  modalOpen: boolean;
  setModalOpen: (open: boolean) => void;
  integration: Integration_Type;
  requirements: Integration_Type_Requirement[];
  setRequirements: (reqs: Integration_Type_Requirement[]) => void;
  setSuccessModalOpen?: (open: boolean) => void;
}
export const DataImportIntegrationForm = ({
  modalOpen,
  setModalOpen,
  integration,
  requirements,
  setRequirements,
  setSuccessModalOpen,
}: IntegrationFormComponentProps) => {
  const { curTeamId: teamId, curOrgId: orgId } = useValidTeamAppContext();

  const [testIntegration, testIntegrationMutation] = useIntegrationConnectedLazyQuery({
    fetchPolicy: 'network-only',
  });
  const [setFeedbackIntegration, setFeedbackIntegrationMutation] = useFeedbackIntegrationMutation();

  const handleTestIntegration = async (): Promise<QueryRes> => {
    let res: QueryRes = { success: false, error: null };

    await testIntegration({
      variables: {
        input: {
          integrationTypeId: integration.id,
          teamId,
          requirements: getIntegrationRequirementsToSend(requirements),
        },
      },
      onCompleted: (data) => {
        if (data.testIntegrationConnected?.success) res = { success: true, error: null };
        else res = { success: false, error: 'Error validating credentials - make sure they are correct and try again' };
      },
      onError: (error: ApolloError) => {
        console.error(error);
        res = { success: false, error: null }; //Unknown error, letting default form handle generic message.
      },
    });
    return res;
  };

  const handleUpdateIntegration = async (): Promise<QueryRes> => {
    let res: QueryRes = { success: false, error: null };

    await setFeedbackIntegration({
      variables: {
        input: {
          feedbackIntegrationId: integration.teamIntegration[0]?.id ?? undefined,
          integrationTypeId: integration.id,
          teamId: teamId,
          requirements: getIntegrationRequirementsToSend(requirements),
        },
      },
      refetchQueries: ['Integrations'],
      onCompleted: () => {
        res = { success: true, error: null };
      },
      onError: (err: ApolloError) => {
        console.error(err);
        res = { success: false, error: err.message };
      },
    });
    return res;
  };

  return (
    <BaseIntegrationForm
      existingIntegrations={integration.orgExternalTicketsIntegration ?? []}
      integration={integration}
      modalOpen={modalOpen}
      mutationLoading={setFeedbackIntegrationMutation.loading}
      requirements={requirements}
      setModalOpen={setModalOpen}
      setRequirements={setRequirements}
      setSuccessModalOpen={setSuccessModalOpen}
      testConnection={handleTestIntegration}
      testConnectionError={testIntegrationMutation.error}
      testConnectionLoading={testIntegrationMutation.loading}
      updateIntegration={handleUpdateIntegration}
      key={integration.id}
    />
  );
};
