import Tippy from '@tippyjs/react';
import AdjustableLoadingIcon from '../../../baseComponents/AdjustableLoadingIcon';
import { ComboBox } from '../../../baseComponents/ComboBox';
import { alphabeticalSort, capitalizeFirstLetter } from '../../../v2/util';
import { useClickOutside } from '../../../utilities';
import { GroupFull } from '../../../v2/hooks/GroupHook';
import { ExploreGroupHook } from '../../hooks/ExploreGroupHook';
import { HomeGroupHook } from '../../hooks/HomeGroupHook';
import { useRef, useState } from 'react';
import Badge from '../../../baseComponents/Badge';

const GroupTagSelector = (props: { group: GroupFull | undefined; groupHook: ExploreGroupHook | HomeGroupHook }) => {
  const { group, groupHook } = props;
  const ref = useRef<HTMLDivElement>(null);
  useClickOutside([ref], () => addTagOpen && setAddTagOpen(false));
  const [addTagOpen, setAddTagOpen] = useState(false);
  const [addLoading, setAddLoading] = useState<boolean>(false);
  const [removedTagId, setRemovedTagId] = useState<number>();
  if (!group) return <></>;
  return (
    <div id="tags-row" className="flex flex-row col-span-5 xl:col-span-2 justify-end gap-x-1 items-start gap-y-2 flex-wrap mt-0.5">
      <p className="font-semibold text-gray-500 mt-0.5">Tags:</p>
      <div id="group-tags" className="flex flex-row flex-wrap items-center justify-start gap-x-2 "></div>
      {group?.tags?.map((tag) => {
        return (
          <div className="flex-none">
            <Badge
              key={tag.id}
              capitalize={true}
              badge={{ id: tag.id.toString(), text: tag.name }}
              onRemove={(item) => {
                setRemovedTagId(tag.id);
                groupHook.handleRemoveTag(group.id, tag.id, () => setRemovedTagId(undefined));
              }}
              loading={removedTagId === tag.id}
            />
          </div>
        );
      })}{' '}
      {addTagOpen ? (
        !addLoading ? (
          <div className="flex-none">
            <ComboBox
              comboBoxData={
                groupHook.tags
                  ?.filter((tag) => !group?.tags?.some((t) => t.id === tag.id))
                  .sort((a, b) => alphabeticalSort(a.name, b.name))
                  .map((tag) => {
                    return { ...tag, name: capitalizeFirstLetter(tag.name) };
                  }) ?? []
              }
              defaultOption={true}
              boxRef={ref}
              setSelectedItem={(item) => {
                if (!group) return;

                if (item) {
                  if (!groupHook.tags?.some((tag) => tag.id === item.id)) {
                    setAddLoading(true);
                    groupHook.handleCreateTag(group.id, item.name, () => {
                      setAddLoading(false);
                    });
                  } else {
                    setAddLoading(true);
                    groupHook.handleTagGroup(group.id, item.id, () => {
                      setAddLoading(false);
                    });
                  }
                }
              }}
            />
          </div>
        ) : (
          <AdjustableLoadingIcon width={4} height={4} />
        )
      ) : (
        <Tippy theme="dark" content={<p>Add another tag</p>}>
          <div className="cursor-pointer" onClick={() => setAddTagOpen((prev) => !prev)}>
            <Badge badge={{ id: '0', text: '+' }} id="add-tag" />
          </div>
        </Tippy>
      )}
    </div>
  );
};

export default GroupTagSelector;
