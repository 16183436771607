export const V2_DASHBOARD = 'v2Dashboard';
export const V3_DASHBOARD = 'dashboard';

export const AppRoutes = {
  v2FullPath: {
    feedback: `/${V2_DASHBOARD}/feedback`,
    clusters: `/${V2_DASHBOARD}/clusters`,
    analysis: `/${V2_DASHBOARD}/analysis`,
    alerts: `/${V2_DASHBOARD}/alerts`,
    integrations: `/${V2_DASHBOARD}/integrations`,
    organizations: `/${V2_DASHBOARD}/organizations`,
    admin: `/${V2_DASHBOARD}/admin`,
    invalid: `/${V2_DASHBOARD}/invalid`,
  },
  v2Pages: {
    feedback: `/feedback`,
    clusters: `/clusters`,
    analysis: `/analysis`,
    alerts: `/alerts`,
    integrations: `/integrations`,
    organizations: `/organizations`,
    admin: `/admin`,
    invalid: '/invalid',
    sandbox: '/sandbox',
  },
  v3FullPath: {
    feedback: `/${V3_DASHBOARD}/feedback`,
    explore: `/${V3_DASHBOARD}/explore`,
    home: `/${V3_DASHBOARD}/home`,
    alerts: `/${V3_DASHBOARD}/alerts`,
    integrations: `/${V3_DASHBOARD}/integrations`,
    organizations: `/${V3_DASHBOARD}/organizations`,
    admin: `/${V3_DASHBOARD}/admin`,
    invalid: `/${V3_DASHBOARD}/invalid`,
    sandbox: `/${V3_DASHBOARD}/sandbox`,
    charts: `/${V3_DASHBOARD}/charts`,
    replying: `/${V3_DASHBOARD}/feedback/replying`,
    digest: `/${V3_DASHBOARD}/digest`,
    assistant: `/${V3_DASHBOARD}/assistant`,
  },
  v3Pages: {
    feedback: `/feedback`,
    explore: `/explore`,
    home: `/home`,
    alerts: `/alerts`,
    integrations: `/integrations`,
    organizations: `/organizations`,
    admin: `/admin`,
    invalid: '/invalid',
    charts: '/charts',
    replying: '/feedback/replying',
    digest: `/digest`,
    assistant: `/assistant`,
  },

  login: '/login',
  grantAccess: '/grantAccess',
  authGoogle: '/auth/google',
  authOktaResponse: '/auth/okta',
  loginOkta: '/login/okta',
  ssoResponse: '/auth/sso',
  ssoLogin: '/login/sso',
  ssoSession: '/auth/session/:tenantId',
  signup: '/signup',
  forgotPassword: '/forgotPassword',
  privacy: '/privacy',
  terms: '/terms',
  denied: '/denied',
  profile: '/profile',
  secret: '/secret',
};
