import { ArrowLeftIcon, CheckBadgeIcon, EllipsisVerticalIcon, PlusIcon } from '@heroicons/react/24/outline';
import { useState, useEffect, useContext } from 'react';
import UserContext from '../../v2/contexts/UserContext';
import { useParams, useNavigate } from 'react-router-dom';
import InlineEditableTextArea from '../../baseComponents/InlineEditableTextArea';
import {
  GetOrganizationDocument,
  Organization_Users_Role,
  OrganizationUser,
  useAddOrganizationUsersMutation,
  useGetOrganizationQuery,
  useConnectionsQuery,
  useDeleteOrgConnectionMutation,
  ConnectionsDocument,
  useCreateSlackConnectionMutation,
  useIntegrationsQuery,
  Feedback_Integration_Type,
} from '../../generated/graphql';
import { AddUserToOrg } from '../../generated/graphql';
import toast from 'react-hot-toast';
import { logEvent, Events } from '../../v2/AnalyticsUtil';
import { ViewRow } from './ViewRow';
import { MemberRow } from './MemberRow';
import LoadingSpinner from '../../v2/components/LoadingSpinner';
import ConnectionsList from '../components/ConnectionsList';
import InviteUsersModal from '../components/Modals/InviteUsersModal';
import NewGroupModal from '../components/Modals/NewGroupModal';
import Button, { ButtonSize, ButtonVariant } from '../baseComponents/Button';
import { PageWrapper } from '../pages/PageWrapper';
import { AppRoutes } from '../../Routes';
import AppContext, { useValidTeamAppContext } from '../../v2/contexts/AppContext';
import { useOrganizationHook } from '../../v2/hooks/OrganizationHook';
import YesCancelModal from './Modals/YesCancelModal';
import OrgDomainsSection from './OrgDomainsSection';
import IntegrationCard from './IntegrationCard';
import { IntegrationModalState, handleOpenIntegrationCard } from '../pages/IntegrationsPage';
import InformationModal from './Modals/InformationModal';
import { OrgIntegrationsList } from './orgInfo/OrgIntegrationsList';

/**
 * This allows someone to edit info in an organization. The data model here is a little weird right now as it's using data from the useOrganizationHook
 * and from separate some-what organization based queries like the org's users. This data is separate from what's in the useOrganizationHook as it's not
 * really related to the global data.
 */
export default function OrganizationInfoV3({ pageName }: { pageName: string }) {
  const { curTeamId: teamId, curOrgId, currentOrg, currentTeam, setCurOrgId, setCurTeamId } = useValidTeamAppContext();
  const { editOrganization, addTeam } = useOrganizationHook();
  type OrgParams = {
    orgId: string;
  };
  let { orgId } = useParams<OrgParams>();
  let navigate = useNavigate();
  const { user } = useContext(UserContext);

  const [addUsers, addingResult] = useAddOrganizationUsersMutation({
    refetchQueries: [{ query: GetOrganizationDocument, variables: { orgId: Number(orgId) } }],
  });
  const orgsResult = useGetOrganizationQuery({ variables: { orgId: Number(orgId) } });
  const [inviteUsersModalOpen, setInviteUsersModalOpen] = useState(false);
  const [newTeamModalOpen, setNewTeamModalOpen] = useState(false);
  const [connectionToRemoveId, setConnectionToRemoveId] = useState<number | null>(null);

  const connections = useConnectionsQuery({ variables: { orgId: Number(orgId) } });
  const [removeConnectionMutation, removeConQuery] = useDeleteOrgConnectionMutation();

  const addMembers = async (members: string[], role: Organization_Users_Role) => {
    const newMembers = members.filter(
      (member) => orgsResult.data?.getOrganization?.orgUser?.findIndex((currentMember) => currentMember.email === member) === -1
    );
    const usersToAdd = newMembers.map((member) => {
      const user: AddUserToOrg = { userEmail: member, orgRole: role };
      return user;
    });
    if (newMembers.length !== members.length) {
      toast.error('You can only add users not currently in the organization.');
    }
    if (usersToAdd.length > 0) {
      addUsers({
        variables: {
          orgId: Number(orgId),
          usersToAdd: usersToAdd,
        },
        onCompleted: () => {
          toast.success(`Invitation${usersToAdd.length > 1 ? 's have' : ' has'} been sent!`);
          setInviteUsersModalOpen(false);
          logEvent(Events.InvitedMembers, {
            View_ID: teamId ?? undefined,
            View_Name: currentTeam?.name ?? undefined,
            Org_ID: Number(orgId),
            Org_Name: currentOrg?.name,
            Page: 'Organizations',
          });
        },
      });
    } else {
      setInviteUsersModalOpen(false);
    }
  };

  const onEditNameComplete = (name: string) => {
    editOrganization(name);
  };

  useEffect(() => {
    if (orgId && curOrgId !== Number(orgId)) {
      try {
        setCurOrgId(Number(orgId));
      } catch (err) {
        navigate(AppRoutes.v3FullPath.organizations);
      }
    }
  }, []);

  const checkSuccessPropsFromQueryString = async () => {
    const connectionParams = new URLSearchParams(window.location.search).get('connectionParams');
    if (!connectionParams) return;

    window.history.replaceState({}, '', window.location.pathname);

    const parsedParams = JSON.parse(connectionParams);
    const { success, connectionTypeId, error_description } = parsedParams;

    if (!success) {
      toast.error(error_description);
      return;
    }

    toast.success('Connection successful!');
  };

  useEffect(() => {
    checkSuccessPropsFromQueryString();
  }, []);

  const removeConnection = (connectionId: number) => {
    if (curOrgId) {
      removeConnectionMutation({
        variables: {
          connectionId: connectionId,
          orgId: curOrgId,
        },
        refetchQueries: [{ query: ConnectionsDocument, variables: { orgId: Number(orgId) } }],
        onCompleted: () => {
          setConnectionToRemoveId(null);
          toast.success('Connection removed');
        },
      });
    }
  };

  const userIsAdmin =
    orgsResult.data?.getOrganization?.orgUser?.find((orgUser) => orgUser.email.toLowerCase() === user?.email.toLowerCase())?.role ===
    Organization_Users_Role.Admin;
  const numberOfAdmins = orgsResult.data?.getOrganization?.orgUser?.filter((orgUser) => orgUser.role === Organization_Users_Role.Admin)?.length;
  return (
    <PageWrapper
      title="Settings"
      backButton={
        <Button
          variant={ButtonVariant.Tertiary}
          text={'Back to Organizations'}
          icon={<ArrowLeftIcon className="mb-0.5 h-4 w-4 stroke-2" aria-hidden="true" />}
          iconPosition="left"
          onClick={() => navigate(AppRoutes.v3FullPath.organizations)}
        />
      }
    >
      {!orgsResult.data || !currentOrg ? (
        <LoadingSpinner />
      ) : (
        <>
          {connectionToRemoveId && (
            <YesCancelModal
              text="Are you sure you want to remove this connection?"
              subtext="This will remove all dependencies (eg. channels on Alerts)"
              confirmText="Confirm"
              closeOnConfirm={false}
              loadingConfirm={removeConQuery.loading}
              modalOpen={!!connectionToRemoveId}
              confirmButton={() => removeConnection(connectionToRemoveId)}
              callbackModal={() => setConnectionToRemoveId(null)}
            />
          )}
          {inviteUsersModalOpen && (
            <InviteUsersModal
              loadingConfirm={addingResult.loading}
              modalOpen={inviteUsersModalOpen}
              confirmButton={addMembers}
              callbackModal={() => setInviteUsersModalOpen(false)}
            />
          )}
          {newTeamModalOpen && (
            <NewGroupModal
              modalOpen={newTeamModalOpen}
              callbackModal={() => setNewTeamModalOpen(false)}
              confirmButton={(teamName: string, logoUrl: string, demoMode: string) => {
                addTeam(teamName, logoUrl, demoMode);
                setNewTeamModalOpen(false);
              }}
              type="View"
            />
          )}
          <div className="flex flex-col gap-y-4 font-sofiapro">
            <div className="flex w-full flex-col gap-y-8 rounded-md text-blueberry">
              <div className="flex flex-col gap-y-4 divide-y divide-gray-100">
                <div className="flex flex-row items-center justify-start">
                  <h1 className="mr-3 text-2xl font-semibold">Name:</h1>
                  <InlineEditableTextArea
                    value={currentOrg.name}
                    onEditComplete={onEditNameComplete}
                    additionalClassNames={`text-xl px-2 ${userIsAdmin ? 'border-b-2 border-blueberry hover:bg-gray-200' : ''} w-96`}
                    editable={userIsAdmin}
                  />
                </div>

                <div className="flex flex-col gap-y-4 py-4">
                  {!orgsResult.data?.getOrganization?.isManagedByIdentityPool ? (
                    <OrgDomainsSection userIsAdmin={userIsAdmin} orgData={orgsResult.data?.getOrganization} />
                  ) : null}
                </div>
                <div className="flex flex-col py-4">
                  <div className="flex flex-row items-center justify-between">
                    <h1 className="text-2xl font-semibold">Connections</h1>
                  </div>
                  <ConnectionsList
                    orgId={Number(orgId)}
                    connections={connections.data?.connections ?? []}
                    loading={connections.loading}
                    removeConnection={(idToRemove: number) => setConnectionToRemoveId(idToRemove)}
                    userIsAdmin={userIsAdmin}
                  />
                  {teamId ? <OrgIntegrationsList /> : null}
                </div>
                {orgsResult.data?.getOrganization?.isManagedByIdentityPool ? (
                  <div>
                    <div className="flex flex-row items-center justify-between">
                      <h1 className="text-2xl font-semibold">Members</h1>
                    </div>
                    <div className={`my-4 gap-4 rounded-xl bg-raspberry text-white p-4`}>
                      <div>Membership for this organization is managed through SSO. Please contact your system administrator to add or remove members.</div>
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-col gap-y-0 py-4">
                    <div className="flex flex-row items-center justify-between ">
                      <h1 className="text-2xl font-semibold">Members</h1>
                      {userIsAdmin && (
                        <Button
                          id="invite-user-button"
                          variant={ButtonVariant.Tertiary}
                          size={ButtonSize.Small}
                          onClick={() => setInviteUsersModalOpen(true)}
                          icon={<PlusIcon className="h-3 w-3" />}
                          iconPosition="right"
                          text="Invite Members"
                        ></Button>
                      )}
                    </div>
                    <div className="grid grid-cols-3 gap-x-4">
                      {orgsResult.data?.getOrganization?.orgUser?.map((member) => {
                        if (orgId === '254') return null; //Hide hundreds of members on Playwright Org
                        if (!user?.isUnwrapper && member.email.includes('@unwrap.ai')) {
                          return null;
                        }
                        return (
                          <MemberRow
                            user={member as OrganizationUser}
                            key={member.email}
                            selectedOrg={Number(orgId)}
                            userIsAdmin={userIsAdmin}
                            currentUser={user}
                            numberOfAdmins={numberOfAdmins}
                          />
                        );
                      })}
                    </div>
                  </div>
                )}
                <div className="flex flex-col py-4">
                  <div className="flex flex-row items-center justify-between">
                    <h1 className="text-2xl font-semibold">Views</h1>
                    <Button
                      variant={ButtonVariant.Tertiary}
                      size={ButtonSize.Small}
                      text="Create View"
                      onClick={() => setNewTeamModalOpen(true)}
                      icon={<PlusIcon className="h-3 w-3 stroke-2" />}
                    >
                      <h1>Create View</h1>
                    </Button>
                  </div>
                  <div className="grid grid-cols-2 gap-x-4">
                    {currentOrg &&
                      currentOrg.teams.map((team, index) => {
                        if (orgId)
                          return (
                            <ViewRow
                              key={index}
                              team={team}
                              userIsAdmin={userIsAdmin}
                              orgId={orgId}
                              orgName={currentOrg.name}
                              pageName={pageName}
                              onClick={() => {
                                setCurTeamId(team.id);
                                navigate(AppRoutes.v3FullPath.home);
                              }}
                            />
                          );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </PageWrapper>
  );
}
