import SearchInput from '../../baseComponents/SearchInput';
import { checkExactMatch } from '../../lib/searchPreview';
import EmployeeOnlyComponent from '../../baseComponents/EmployeeOnlyComponent';
import { useContext, useState } from 'react';
import NewGroupToggle from './NewGroupToggle';
import { SearchPreviewDispatchContext } from '../../context/SearchPreviewDispatchContext';
import { SearchPreviewContext } from '../../context/SearchPreviewContext';
import { ExplorePageView } from '../../hooks/ExploreGroupHook';
import toast from 'react-hot-toast';
import { FilterInput, GroupDataFragment, useAlertGroupFailedMutation, useCreateGroupMutation, useGetGroupLazyQuery } from '../../../generated/graphql';
import { useValidTeamAppContext } from '../../../v2/contexts/AppContext';
import { TaxonomyDispatchContext } from '../../context/TaxonomyDispatchContext';

const SearchSection = (props: { setView: (view: ExplorePageView) => void; filterInput: FilterInput }) => {
  const [createGroupMutation, createGroupResult] = useCreateGroupMutation({});
  const [alertGroupFailed] = useAlertGroupFailedMutation({});
  const [getGroupFull] = useGetGroupLazyQuery({});

  const { curTeamId: teamId } = useValidTeamAppContext();
  const dispatch = useContext(SearchPreviewDispatchContext);
  const taxonomyDispatch = useContext(TaxonomyDispatchContext);
  const { excludeFromNewFilter, searchInput, searchTerm } = useContext(SearchPreviewContext);

  const toggleExclude = (exclude: boolean) => {
    dispatch({ type: 'toggleExclude', payload: { exclude } });
  };

  const [isLoading, setIsLoading] = useState(false);

  const createGroup = async (searchInput: string) => {
    let breakCircuit = false;
    let errorCount = 0;
    while (!breakCircuit) {
      const result = await createGroupMutation({
        variables: {
          teamId,
          queryString: searchInput,
          excludeFromNewFilter,
        },
      });

      if (createGroupResult.error) {
        errorCount += 1;
        if (errorCount > 2) {
          breakCircuit = true;
          await alertGroupFailed({ variables: { teamId, queryString: searchInput, wasRetried: true } });
          toast.error("We're sorry, but we were unable to create a group for this search. Please try again later.");
        }
      } else {
        const groupId = result.data?.createGroup?.id;
        if (groupId) {
          breakCircuit = true;
          getGroupFull({
            variables: { teamId, groupId, belongs: true, filterInput: props.filterInput },
            onCompleted: (data) => {
              toast.success(`Successfully created search group for ${data.getGroup.title}`);
              const finalGroup = data.getGroup;
              taxonomyDispatch({
                type: 'addGroup',
                payload: { group: finalGroup },
              });
              dispatch({ type: 'setSearchInput', payload: { searchInput: '' } });
              dispatch({ type: 'setSearchTerm', payload: { searchTerm: '' } });
            },
          });
        } else {
          breakCircuit = true;
          await alertGroupFailed({ variables: { teamId, queryString: searchInput, wasRetried: false } });
          toast.error("We're sorry, but we were unable to create a group for this search. Please try again later.");
        }
      }
    }
    setIsLoading(false);
  };

  const finalizeSearch = async (query: string | undefined) => {
    if (!query || query === '') {
      dispatch({ type: 'setSearchString', payload: { searchTerm: '' } });
      props.setView(ExplorePageView.Taxonomy);
    } else if (checkExactMatch(query)) {
      try {
        setIsLoading(true);
        await createGroup(query);
      } catch (e) {
        toast.error('Failed to execute exact match search. Please try again.');
        setIsLoading(false);
      }

    } else {
      props.setView(ExplorePageView.Search);
      dispatch({ type: 'setSearchTerm', payload: { searchTerm: query } });
      setIsLoading(false);
    }
  };

  const isExactMatchQuery = checkExactMatch(searchInput);
  return (
    <div className="flex flex-col justify-center items-center gap-y-2 mb-4">
      <div className="flex flex-row items-center justify-between gap-x-2 w-full">
        <div className="w-full flex-grow">
          <SearchInput
            onSearch={(query) => finalizeSearch(query)}
            setQueryString={(query) => {
              dispatch({ type: 'setSearchInput', payload: { searchInput: query ?? '' } });
            }}
            queryString={searchInput}
            placeholder="Search for Groups of feedback about..."
            onClear={() => {
              dispatch({ type: 'setSearchTerm', payload: { searchTerm: '' } });
              dispatch({ type: 'setSearchInput', payload: { searchInput: '' } });
              props.setView(ExplorePageView.Taxonomy);
              setIsLoading(false);
            }}
          />
        </div>
        <button
          className="bg-blueberry flex w-1/5 flex-row items-center justify-center gap-x-2 rounded-full py-4 px-6 text-sm text-milk duration-200 hover:bg-blueberry-lighter"
          onClick={() => finalizeSearch(searchInput)}
        >
          {isExactMatchQuery ? 'Exact Search' : 'Search'}
        </button>
        <EmployeeOnlyComponent>
          <div className="flex flex-row text-sm justify-center items-center gap-x-2">
            <NewGroupToggle exclude={excludeFromNewFilter} toggleExclude={toggleExclude} />
          </div>
        </EmployeeOnlyComponent>
      </div>
      {isLoading && <SearchLoading isExactMatchQuery={isExactMatchQuery} />}
    </div>
  );
};

const SearchLoading = ({ isExactMatchQuery }: { isExactMatchQuery: boolean }) => {
  return (
    <div className="my-2 flex items-center justify-center gap-x-4 text-blueberry" data-testid="search-loading">
      <div className="h-10 w-10 animate-spin rounded-full border-t-2 border-blueberry"></div>
      <h1 className="text-xl">{isExactMatchQuery ? 'Building Group' : 'Generating search preview...'}</h1>
    </div>
  );
};
export default SearchSection;
