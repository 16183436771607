import { useContext, useEffect } from 'react';
import { GroupDispatchContext } from '../../../context/groupContext';
import { FilterInput, useGetGroupByIdQuery } from '../../../generated/graphql';
import { GroupActions } from '../../../reducers/group/GroupDispatch';

/**
 * This module should start to own fetching all the data for the group page
 * @param props
 */
export const GroupPageDataFetch = (props: { teamId: number; groupId: number; filterInput: FilterInput }) => {
  const dispatch = useContext(GroupDispatchContext);
  const { data, loading, error } = useGetGroupByIdQuery({ variables: { teamId: props.teamId, groupId: props.groupId } });

  useEffect(() => {
    if (loading) {
      dispatch({ type: GroupActions.SET_AUXILLARY_GROUP_DATA_LOADING, payload: {} });
    }
    if (error) {
      dispatch({ type: GroupActions.SET_AUXILLARY_GROUP_DATA_ERROR, payload: { error: error.message } });
      return;
    }
    if (data) {
      dispatch({
        type: GroupActions.UPDATE_AUXILLARY_GROUP_DATA,
        payload: { id: data.getGroupById.id, summaryText: data.getGroupById.summary, title: data.getGroupById.title },
      });
    }
  }, [data, loading, error]);
};
