import { useState } from 'react';
import { Feedback_Integration_Type, useIntegrationsQuery } from '../../../generated/graphql';
import { useValidTeamAppContext } from '../../../v2/contexts/AppContext';
import { IntegrationModalState, handleOpenIntegrationCard } from '../../pages/IntegrationsPage';
import InformationModal from '../Modals/InformationModal';
import IntegrationCard from '../IntegrationCard';

export const OrgIntegrationsList = () => {
  const { curTeamId: teamId, curOrgId: orgId } = useValidTeamAppContext();

  const { data, loading, error } = useIntegrationsQuery({
    variables: { teamId, orgId, feedbackIntegrationType: Feedback_Integration_Type.ExternalTickets },
  });

  const [modalState, setModalState] = useState<IntegrationModalState | undefined>(IntegrationModalState.NoModal);
  const [currentModalId, setCurrentModalId] = useState<number | undefined>(undefined);

  return (
    <div className="flex flex-col gap-y-2">
      <InformationModal
        modalOpen={modalState === IntegrationModalState.Success}
        callbackModal={() => {
          setModalState(undefined);
        }}
        text={'Connection created successfully!'}
        subtext={'The connection has been created on your organization.'}
      />

      {data?.integrations?.map((integration, index) => (
        <IntegrationCard
          //@ts-ignore
          integration={integration}
          skipValidation
          key={index}
          setSuccessModalOpen={() => {
            setModalState(IntegrationModalState.Success);
            setCurrentModalId(undefined);
          }}
          modalOpen={currentModalId === integration.id && modalState === IntegrationModalState.Card}
          setModalOpen={(bool) => {
            handleOpenIntegrationCard(
              //@ts-ignore
              integration,
              bool,
              (state) => setModalState(state),
              (val) => setCurrentModalId(val)
            );
          }}
        />
      ))}
    </div>
  );
};
