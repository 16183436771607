import { ExternalTicketInput, ListExternalTicketsDocument, useLinkAsanaTicketMutation, useLinkJiraTicketMutation } from '../../generated/graphql';
import { ExternalTicketsScraperKeys } from '../components/externalTickets/TicketBrowsing';

export const useLinkExternalTicketHook = ({ orgId, teamId }: { orgId: number; teamId: number }) => {
  const [linkJira] = useLinkJiraTicketMutation();
  const [linkAsana] = useLinkAsanaTicketMutation();

  const ScraperKeyToMutationMap: Record<string, typeof linkJira | typeof linkAsana> = {
    [ExternalTicketsScraperKeys.jira]: linkJira,
    [ExternalTicketsScraperKeys.asana]: linkAsana,
  };

  const linkTicketToGroup = async ({
    groupId,
    integrationId,
    scraperKey,
    input,
  }: {
    groupId: number;
    scraperKey: string;
    integrationId: number;
    input: ExternalTicketInput;
  }) => {
    const linkMutation = ScraperKeyToMutationMap[scraperKey];
    await linkMutation({
      variables: {
        orgId,
        teamId,
        groupId,
        externalTicketsIntegrationId: integrationId,
        input,
      },
      refetchQueries: [{ query: ListExternalTicketsDocument, variables: { groupId, teamId } }],
    });
  };

  return { linkTicketToGroup };
};
