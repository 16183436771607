import { defaultDataIdFromObject, InMemoryCache } from '@apollo/client';

export const cache: InMemoryCache = new InMemoryCache({
  dataIdFromObject: (object) => {
    switch (object.__typename) {
      case 'ThemeSentimentStat':
        return JSON.stringify(object);
      default:
        return defaultDataIdFromObject(object);
    }
  },
  typePolicies: {
    Query: {
      fields: {
        clusters: {
          // this came from docs here https://www.apollographql.com/docs/react/pagination/core-api/

          // keyArgs specifies each argument that causes a different set of data to be fetched. I.e. if we change 'teamId' the query result should not append clusters to existing clusters
          // basically each value in this list key's the cached value for clusters
          keyArgs: (args: any) => {
            //because of the date objects in the filterInput you need to explicity serialize that to a string instead of
            // letting whatever default serialization is going on.
            return JSON.stringify({
              teamId: args['teamId'],
              clusterId: args['clusterId'],
              status: args['status'],
              sortBySentenceId: args['sortBySentenceId'],
              filterBySentenceId: args['filterBySentenceId'],
              filterInput: args['filterInput'],
              sort: args['sort'],
            });
          },
          // for each query that results in clusters from the same "set" (i.e. same team, filterInput) and such merge those clusters into the same array.
          // this is what supports the 'fetchMore' query loading new clusters into the same array
          merge(existing = [], incoming, { readField }) {
            // be careful to only add clusters to this list that don't already exist in the cache.
            // this is happening because of an edge case with the cache policy
            const toMerge = incoming.filter((newCluster: any) => {
              if (existing.length > 0) {
                const reduced = existing.reduce((previous: boolean, oldCluster: any) => {
                  if (!previous) {
                    return previous;
                  }
                  if (readField('id', oldCluster) == readField('id', newCluster)) {
                    return false;
                  }
                  return true;
                });
                return reduced;
              }
              return true;
            });
            return [...existing, ...toMerge];
          },
        },
        entries: {
          keyArgs: (args: any) => {
            return JSON.stringify({
              teamId: args['teamId'],
              repliableOnly: args['repliableOnly'],
              filterInput: args['filterInput'],
            });
          },
          merge(existing = [], incoming) {
            return [...existing, ...incoming];
          },
        },
        sentences: {
          keyArgs: (args: any) => {
            return JSON.stringify({
              teamId: args['teamId'],
              clusterId: args['sortByClusterId'],
              status: args['unclusteredSentences'],
              filterInput: args['filterInput'],
            });
          },
          merge(existing = [], incoming) {
            return [...existing, ...incoming];
          },
        },
      },
    },
  },
});

/**
 * Set initial values when we create cache variables.
 */
