import { createContext, useContext, useState } from 'react';
import {
  CreateOrganizationMutation,
  CreateTeamMutation,
  Date_Window,
  EditOrganizationMutation,
  Organization,
  Organization_Users_Role,
  OrganizationsLightFragment,
  TeamDataFragment,
  TeamDefaults,
  Teams,
} from '../../generated/graphql';
import _ from 'lodash';
import { initial } from 'underscore';

export const SELECTED_ORG_KEY = 'selectedOrgV2';
export const SELECTED_TEAM_KEY = 'selectedTeamV2';
export const ORGANIZATIONS_KEY = 'organizations';

/*

This creates a context for the app to use. 
It stores data like:
- Organizations
- Current Team ID and Org ID
- Preview Mode, Current UUID

The Context DOESNT interact with the service. It only serves and modifies global (context) state.

Any file can access this by importing it and using the line:
  const appContext = useContext(AppContext);
You can destructure the functions and rename as needed:
  const { curTeamId: teamId, curOrgId: orgId} = useContext(AppContext);

For team/org data modifications, use the OrganizationHook.

*/
export enum AppMode {
  RegularMode = 'regular',
  PreviewMode = 'preview',
}

export interface IApp {
  mode: AppMode;
  currentUuid?: string;
  isPreviewMode: boolean;
  isRegularMode: boolean;
}

export const defaultValue = {
  app: {
    mode: AppMode.RegularMode,
    //Silly variables to make things easier to read on other components. To improve.
    isRegularMode: true,
    isPreviewMode: false,
  },
  organizations: [],
  curTeamId: null,
  curOrgId: null,
  currentTeam: null,
  currentOrg: null,
  currentUserIsAdmin: false,
  setMode: () => {},
  setCurrentUuid: () => {},
  setCurTeamId: () => {},
  setCurOrgId: () => {},
  addTeamToOrg: () => {},
  removeTeamFromOrg: () => {},
  setOrgList: () => {},
  setTeamAndOrgFromUrlOrLocalStorage: ({}) => {},
  updateOrg: () => {},
  removeOrg: () => {},
  addOrg: () => {},
  clearAppContext: () => {},
  orgsHaveLoaded: false,
  updateTeamDefaultValues: () => {},
};
const AppContext = createContext<{
  app: IApp | undefined;
  organizations: OrganizationWithTeamInfo[];
  curTeamId: number | null;
  curOrgId: number | null;
  currentTeam: OrgTeamWithInfo | null;
  currentOrg: OrganizationWithTeamInfo | null;
  currentUserIsAdmin: boolean;
  setCurTeamId: (newTeamId: number | null) => void;
  setCurOrgId: (newOrgId: number | null) => void;
  setCurrentUuid: (newCurrentUuid: string) => void;
  setMode: (newMode: AppMode) => void;
  addTeamToOrg: (team: CreateTeamMutation['createTeam']) => void;
  removeTeamFromOrg: (teamId: number) => void;
  setOrgList: (orgs: OrganizationsLightFragment[]) => void;
  setTeamAndOrgFromUrlOrLocalStorage: ({
    orgList,
    invalidOrgCallback,
    invalidTeamCallback,
  }: {
    orgList?: OrganizationsLightFragment[] | undefined;
    invalidOrgCallback?: () => void;
    invalidTeamCallback?: () => void;
  }) => void;
  updateOrg: (org: EditOrganizationMutation['editOrganization']) => void;
  removeOrg: (orgId: number) => void;
  addOrg(org: CreateOrganizationMutation['createOrganization']): void;
  clearAppContext: () => void;
  orgsHaveLoaded: boolean;
  updateTeamDefaultValues: (teamId: number, teamDefaults: TeamDefaults) => void;
}>(defaultValue);

interface IAppContextProvider {
  children: JSX.Element;
  appInitial: IApp;
}

type ExtendedTeamInfo = {
  defaultValues?: {
    startDate?: Date;
    endDate?: Date;
    oldestFeedbackDate?: any;
    exploreDefaultWindow?: Date_Window | null;
  };
};

//We want to store extra info that's fetched separately from the orgs query (adding this to the org query would increase load times).
//This extends the type with the extra info we want to store.
export type OrgTeamWithInfo = OrganizationsLightFragment['teams'][0] & ExtendedTeamInfo;
//Note: DefaultValues (or any other extension) must have all properties nullable/undefinable, otherwise this will not type. Suggestions accepted on improving this.

//This extends the 'teams' property of the org type with the type we created.
export type OrganizationWithTeamInfo = {
  [K in keyof OrganizationsLightFragment]: K extends 'teams' ? OrgTeamWithInfo[] : OrganizationsLightFragment[K];
};

export const AppContextProvider = ({ children, appInitial }: IAppContextProvider) => {
  const [app, setApp] = useState<IApp>(appInitial);

  const [orgsHaveLoaded, _setOrgsHaveLoaded] = useState(false);

  // DO NOT CALL EITHER  __setCurTeamId or __setCurOrgId DIRECTLY. USE _setCurTeamId and _setCurOrgId instead.
  const urlSearchParams = new URLSearchParams(window.location.search);

  const initialOrgId = Number(urlSearchParams.get('orgId')) || Number(localStorage.getItem(SELECTED_ORG_KEY));
  const initialTeamId = Number(urlSearchParams.get('teamId')) || Number(localStorage.getItem(SELECTED_TEAM_KEY));

  const [curTeamId, __setCurTeamId] = useState<number | null>(initialTeamId || null);
  const [curOrgId, __setCurOrgId] = useState<number | null>(initialOrgId || null);
  const [organizations, __setOrganizations] = useState<OrganizationWithTeamInfo[]>(getOrganizationsFromLocalStorage());
  /**
   * This overrides __setOrganizations to also set the payload in localstorage
   */
  const _setOrganizations = (organizations: OrganizationsLightFragment[]) => {
    __setOrganizations(organizations);
    localStorage.setItem(ORGANIZATIONS_KEY, JSON.stringify(organizations));
  };

  /**
   * this overrides __setCurOrgId to also set the org name in local storage and ensure that each time the org changes via the state variable the local storage is updated
   */
  const _setCurOrgId = (newOrgId: number | null) => {
    __setCurOrgId(newOrgId);
    if (newOrgId) {
      localStorage.setItem(SELECTED_ORG_KEY, newOrgId.toString());
    }
  };

  /**
   * this overrides __setCurTeamId to also set the team name in local storage and ensure that each time the team changes via the state variable the local storage is updated
   */
  const _setCurTeamId = (newTeamId: number | null) => {
    __setCurTeamId(newTeamId);
    if (newTeamId) {
      localStorage.setItem(SELECTED_TEAM_KEY, newTeamId.toString());
    }
  };

  const setCurTeamId = (newTeamId: number | null) => {
    if (newTeamId === null) return _setCurTeamId(null);
    const org = organizations.find((org) => org.id === curOrgId);
    if (!org?.teams.find((team) => team.id === newTeamId)) throw new Error('Team not found on the teams list');
    _setCurTeamId(newTeamId);
    localStorage.setItem(SELECTED_TEAM_KEY, newTeamId.toString());
    localStorage.setItem(SELECTED_ORG_KEY, org.id.toString());
  };

  const setCurOrgId = (newOrgId: number | null) => {
    if (newOrgId === null) return _setCurOrgId(null);
    const org = organizations.find((org) => org.id === newOrgId);
    if (!org) throw new Error('Organization not found on the organizations list');
    _setCurOrgId(newOrgId);
    const teamIdToSet = org.teams.length > 0 ? org.teams[0].id : null;
    _setCurTeamId(teamIdToSet ?? null);
    teamIdToSet ? localStorage.setItem(SELECTED_TEAM_KEY, teamIdToSet.toString()) : localStorage.removeItem(SELECTED_TEAM_KEY);
    localStorage.setItem(SELECTED_ORG_KEY, org.id.toString());
  };

  const addTeamToOrg = (teamToAdd: CreateTeamMutation['createTeam']) => {
    const newOrgList = organizations.map((org) => (org.id === curOrgId ? { ...org, teams: [...org.teams, teamToAdd] } : org));
    _setOrganizations(newOrgList);
    _setCurTeamId(teamToAdd.id);
    localStorage.setItem(SELECTED_TEAM_KEY, teamToAdd.id.toString());
  };

  const removeTeamFromOrg = (teamIdToRemove: number) => {
    const newOrgList = organizations.map((org) => {
      if (org.id === curOrgId) {
        const newTeams = org.teams.filter((team) => team.id !== teamIdToRemove);
        return { ...org, teams: newTeams };
      }
      return org;
    });
    _setOrganizations(newOrgList);
    if (curTeamId === teamIdToRemove) setCurTeamId(newOrgList.find((org) => org.id === curOrgId)?.teams[0].id ?? null);
  };

  const setOrgList = (newOrgsList: OrganizationsLightFragment[]) => {
    _setOrganizations(newOrgsList);
    _setOrgsHaveLoaded(true);
  };

  const updateOrg = (editedOrg: EditOrganizationMutation['editOrganization']) => {
    if (!editedOrg || !organizations.find((org) => org.id === editedOrg.id)) throw new Error('Organization not found on the organizations list');
    const newOrgList = organizations.map((org) => (org.id === editedOrg.id ? (editedOrg as OrganizationsLightFragment) : org));
    _setOrganizations(newOrgList);
  };

  const removeOrg = (orgIdToRemove: number) => {
    const newOrgList = organizations.filter((org) => org.id !== orgIdToRemove);
    _setOrganizations(newOrgList);
    if (curOrgId === orgIdToRemove) {
      _setCurOrgId(newOrgList.length > 0 ? newOrgList[0].id : null);
      _setCurTeamId(newOrgList.length > 0 ? newOrgList[0].teams?.[0]?.id : null);
      localStorage.removeItem(SELECTED_ORG_KEY);
      localStorage.removeItem(SELECTED_TEAM_KEY);
    }
  };

  const addOrg = (newOrg: CreateOrganizationMutation['createOrganization']) => {
    _setOrganizations([...organizations, newOrg]);
    _setCurOrgId(newOrg.id);
  };

  const setTeamAndOrgFromUrlOrLocalStorage = async ({
    orgList,
    invalidOrgCallback,
    invalidTeamCallback,
  }: {
    orgList?: OrganizationsLightFragment[] | undefined;
    invalidOrgCallback?: () => void;
    invalidTeamCallback?: () => void;
  }) => {
    const orgs = orgList ?? organizations;
    if (orgList) _setOrganizations(orgList);
    const urlSearchParams = new URLSearchParams(window.location.search);
    const orgIdFromUrl = Number(urlSearchParams.get('orgId'));
    const teamIdFromUrl = Number(urlSearchParams.get('teamId'));
    if (orgIdFromUrl && teamIdFromUrl) {
      const org = orgs.find((org) => org.id === orgIdFromUrl);
      if (!org) {
        _setOrgsHaveLoaded(true);
        return invalidOrgCallback?.();
      }
      _setCurOrgId(org.id);
      const team = org?.teams.find((team) => team.id === teamIdFromUrl);
      if (!team) {
        _setOrgsHaveLoaded(true);
        return invalidTeamCallback?.();
      }
      _setCurTeamId(team.id);
    } else {
      const localOrg = await localStorage.getItem(SELECTED_ORG_KEY);
      const localTeam = await localStorage.getItem(SELECTED_TEAM_KEY);
      const org = orgs.find((org) => org.id === Number(localOrg));
      const team = org?.teams.find((team) => team.id === Number(localTeam));
      if (org) {
        _setCurOrgId(Number(localOrg));
        if (team) _setCurTeamId(Number(localTeam));
        else _setCurTeamId(org.teams[0]?.id ?? null);
      } else {
        _setCurOrgId(orgs?.[0]?.id ?? null);
        _setCurTeamId(orgs?.[0]?.teams?.[0]?.id ?? null);
      }
    }
    _setOrgsHaveLoaded(true);
  };

  const setCurrentUuid = (newCurrentUuid: string) => setApp((app: IApp) => ({ ...app, currentUuid: newCurrentUuid }));

  const setMode = (newMode: AppMode) =>
    setApp((app: IApp) => ({ ...app, mode: newMode, isRegularMode: newMode === AppMode.RegularMode, isPreviewMode: newMode === AppMode.PreviewMode }));

  const clearAppContext = () => {
    _setCurOrgId(null);
    _setCurTeamId(null);
    localStorage.removeItem(SELECTED_ORG_KEY);
    localStorage.removeItem(SELECTED_TEAM_KEY);
    _setOrgsHaveLoaded(false);
    _setOrganizations([]);
  };

  const updateTeamDefaultValues = (teamId: number, teamDefaults: TeamDefaults) => {
    const team = organizations.find((org) => org.id === curOrgId)?.teams.find((team) => team.id === teamId);
    const newTeam = _.cloneDeep(team);
    if (!newTeam) throw new Error('Team not found on the teams list');
    if (!newTeam?.defaultValues) newTeam.defaultValues = {};

    newTeam.defaultValues = {
      ...teamDefaults,
      startDate: teamDefaults.startDate ? new Date(teamDefaults.startDate) : undefined,
      endDate: teamDefaults.endDate ? new Date(teamDefaults.endDate) : undefined,
    };

    //This is rewriting all arrays. We do a similar thing in other places, but maybe we could improve it.
    if (newTeam.defaultValues == team?.defaultValues) return;
    setOrgList(
      organizations.map((org) => {
        if (org.id === curOrgId) {
          return {
            ...org,
            teams: org.teams.map((team) => {
              if (team.id === teamId) {
                return newTeam;
              }
              return team;
            }),
          };
        }
        return org;
      })
    );
  };

  return (
    <AppContext.Provider
      value={{
        app,
        organizations,
        currentUserIsAdmin: organizations.find((org) => org.id === curOrgId)?.currentUser.role === Organization_Users_Role.Admin,
        curTeamId: organizations.find((org) => org.id === curOrgId)?.teams?.find((team) => team.id === curTeamId)?.id ? curTeamId : null,
        //I'm not sure it makes sense to keep both curTeamId and curTeam (same for org) as a returned value from the context.
        //Haven't found an easy way to destructure id from currentTeam from context yet, keeping for now so we don't have to do currentTeam?.id every time.
        currentTeam: curTeamId ? (organizations.find((org) => org.id === curOrgId)?.teams.find((team) => team.id === curTeamId) as Teams) : null,
        currentOrg: curOrgId ? (organizations.find((org) => org.id === curOrgId) as OrganizationsLightFragment) ?? null : null,
        curOrgId: organizations.find((org) => org.id === curOrgId)?.id ? curOrgId : null,
        setCurTeamId,
        setCurOrgId,
        setCurrentUuid,
        setMode,
        addTeamToOrg,
        removeTeamFromOrg,
        setOrgList,
        setTeamAndOrgFromUrlOrLocalStorage,
        updateOrg,
        removeOrg,
        addOrg,
        clearAppContext,
        orgsHaveLoaded,
        updateTeamDefaultValues,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const AppContextConsumer = AppContext.Consumer;

export default AppContext;

type NonNullableProperties<T, Key extends keyof T> = {
  [K in keyof T]: K extends Key ? NonNullable<T[K]> : T[K];
};

/* Reason for this:
 The context can have null values for curTeamId and curOrgId.
 Pages that need to use the context should use this hook instead of useContext(AppContext)
 Because we only render pages that require ids if the context has non null ids, we use this to tell TypeScript that the ids are not null

 It's almost identical to using "teamId!" "orgId!" etc, but a bit cleaner as we don't have to add "!" to every property.

 Eg: Home Page works with appContext. If there are no views, it shows a message prompting the user to create one.
 Organizations Page also works with appContext, as you need to access it even if you have no orgs and views.

 Pages like Feedback, Explore, Alerts, Integrations (and their components) require the context to have non null ids, so we use useValidTeamAppContextThere.
*/
export const useValidTeamAppContext = () => {
  const context = useContext(AppContext);
  return context as NonNullableProperties<typeof context, 'curTeamId' | 'curOrgId' | 'currentTeam' | 'currentOrg'>;
};

export const getOrganizationsFromLocalStorage = (): OrganizationsLightFragment[] => {
  const orgs = localStorage.getItem(ORGANIZATIONS_KEY);
  if (!orgs) return [];
  try {
    const parsedOrgs = JSON.parse(orgs);
    if (Array.isArray(parsedOrgs)) {
      return parsedOrgs;
    } else {
      throw new Error('Organizations in local storage is not an array.');
    }
  } catch (err: any) {
    return [];
  }
};
