import { SyntheticEvent } from 'react';
import Tippy from '@tippyjs/react';
import AdjustableLoadingIcon from '../../../baseComponents/AdjustableLoadingIcon';
import { BookmarkIcon as BookmarkIconSolid } from '@heroicons/react/24/solid';
import { BookmarkIcon } from '@heroicons/react/24/outline';

export function PinGroupLink(props: {
    pinnedByUser: boolean,
    pinLoading: boolean,
    onClick: (e: SyntheticEvent) => void
}) {
    return <div className="rounded-full p-2 hover:cursor-pointer hover:bg-slate-900/25 ">
        <Tippy theme="light" delay={200} content={<p
            className="text-center">{props.pinnedByUser ? 'Unpin Group' : 'Pin Group'}</p>}>
            {props.pinLoading ? (
                <AdjustableLoadingIcon width={5} height={5} color="white" />
            ) : props.pinnedByUser ? (
                <BookmarkIconSolid className="h-5 w-5 self-center" id="unpin-group"
                                   onClick={props.onClick} />
            ) : (
                <BookmarkIcon className="h-5 w-5 self-center" id="pin-group" onClick={props.onClick} />
            )}
        </Tippy>
    </div>;
}