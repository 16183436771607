import { Group_Trending } from '../../../generated/graphql';
import Tippy from '@tippyjs/react';
import { ArrowDownRightIcon, ArrowUpRightIcon } from '@heroicons/react/24/outline';

export function TrendArrow(props: {
    trending: Group_Trending.Decreasing | Group_Trending.Increasing | null | undefined
}) {
    if (!props.trending) return <></>;

    return <div className="rounded-full p-1 bg-blueberry border text-white w-5 h-5 flex justify-center items-center">
        <Tippy
            theme="dark"
            delay={200}
            content={<p className="text-center">This group is
                trending {props.trending === Group_Trending.Increasing ? 'upwards' : 'downwards'} </p>}
        >
            <div>
                {props.trending === Group_Trending.Increasing ? (
                    <ArrowUpRightIcon className="h-2.5 w-2.5 focus:outline-none stroke-[3px]" />
                ) : (
                    <ArrowDownRightIcon className="h-2.5 w-2.5 focus:outline-none stroke-[3px]" />
                )}
            </div>
        </Tippy>
    </div>;
}