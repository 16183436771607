import { GroupFull } from '../../../../v2/hooks/GroupHook';
import Tippy from '@tippyjs/react';
import AdjustableLoadingIcon from '../../../../baseComponents/AdjustableLoadingIcon';
import { BookmarkIcon as BookmarkIconSolid } from '@heroicons/react/24/solid';
import { BookmarkIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { ExploreGroupHook } from '../../../hooks/ExploreGroupHook';
import { HomeGroupHook } from '../../../hooks/HomeGroupHook';

export function PinGroupButton(props: { group: GroupFull, groupHook: ExploreGroupHook | HomeGroupHook}) {

  const [pinLoading, setPinLoading] = useState(false);

  const handlePinGroup = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation();
    setPinLoading(true);
    if (!props.group) return;
    props.groupHook.togglePinGroup(props.group.id, () => setPinLoading(false));
  };

  return <Tippy theme="dark" delay={200}
                content={<p className="text-center">{props.group?.pinnedByUser ? 'Unpin Group' : 'Pin Group'}</p>}>
    <div
      id="pin-group-icon"
      className=" bg-silver  text-blueberry flex flex-row justify-center items-center cursor-pointer rounded-full h-8 w-8 hover:scale-110 duration-150 hover:shadow-sm"
    >
      {pinLoading ? (
        <AdjustableLoadingIcon width={5} height={5} color="white" />
      ) : props.group?.pinnedByUser ? (
        <BookmarkIconSolid className="h-5 w-5 self-center" id="unpin-group" onClick={handlePinGroup} />
      ) : (
        <BookmarkIcon className="h-5 w-5 self-center" id="pin-group" onClick={handlePinGroup} />
      )}
    </div>
  </Tippy>;
}
