import {
  useGetChangelogDataQuery,
  useCreateChangelogDataMutation,
  useDeleteChangelogDataMutation,
  useEditChangelogDataMutation,
  GetChangelogDataDocument,
  ChangelogFragment,
  Changelog,
  useGetChangelogDataLazyQuery,
} from '../../generated/graphql';

import {
  Chart as ChartJS,
  ChartOptions,
  ChartData,
  BarElement,
  LegendOptions,
  TooltipOptions,
  Tick,
  Scale,
  CoreScaleOptions,
  ScaleOptionsByType,
  CartesianScaleTypeRegistry,
  TooltipItem,
  Tooltip,
  TooltipXAlignment,
  ActiveElement,
  ChartEvent,
} from 'chart.js';
import annotationPlugin, { AnnotationOptions } from 'chartjs-plugin-annotation';
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { IChartingSeries, IChartableSeries, getAggregateData, getLabelRange } from '../../baseComponents/VerticalStackedBarChart';
import moment from 'moment';
import AppContext from '../contexts/AppContext';
ChartJS.register(annotationPlugin);

/**
 * Custom tooltip positioner that places always places tooltip to the left/right of a point.
 * @param elements
 * @param eventPosition
 * @returns
 */
Tooltip.positioners.custom = function (elements, eventPosition) {
  const chart = this.chart;

  if (!elements.length) {
    return false;
  }
  let offset = 0;
  let align: TooltipXAlignment;
  // adjust the offset left or right depending on the event position
  if (chart.chartArea.right / 2 < eventPosition.x) {
    offset = -10;
    align = 'right';
  } else {
    offset = 10;
    align = 'left';
  }
  return {
    x: elements[0].element.x + offset,
    y: elements[0].element.y,
    xAlign: align,
    yAlign: 'center',
  };
};

export interface IAnnotation {
  id: number;
  date: Date;
  title: string;
  description?: string | null;
  value: number;
  userId?: number | null;
  feedbackIntegrationId?: number | null;
  feedbackIntegrationTitle?: string | null;
}

export type ChartAnnotationOptions = AnnotationOptions & IAnnotation & { annotationId: number };

export interface IChartCoordinates {
  x: number;
  y: number;
  value: number;
}
export interface IChartableData {
  data: IChartableSeries[];
  startDate: Date;
  endDate: Date;
  denominator?: IChartableSeries;
}

export enum ChartType {
  Mixed = 'mixed',
  Vertical = 'vertical',
  Horizontal = 'horizontal',
  CustomPercentage = 'customPercentage',
  CustomAbsolute = 'customAbsolute',
  Favorability = 'favorability',
}
/**
 *
 * @param feedbackIntegrationTitle
 * @returns
 */
export const getOSVersion = (feedbackIntegrationTitle: string) => {
  switch (feedbackIntegrationTitle) {
    case 'Apple App Store':
      return 'iOS';
    case 'Google Play':
      return 'Android';
    default:
      return feedbackIntegrationTitle;
  }
};

/**
 * This function finds the index value a given changelog date falls within.
 * @param range
 * @param changelogDate
 * @returns
 */
export const getAnnotationValue = (range: number[], changelogDate: number) => {
  return (
    range.findIndex((bin) => {
      return changelogDate <= bin;
    }) - 1
  );
};
/**
 * This function takes the changelog data currently inside this team and makes that data chartable.
 * To be chartable, a given annotation has to know what index value to appear at.
 */

export const processAnnotations = (changelogs: ChangelogFragment[] | Changelog[] | undefined | null, range: number[]) => {
  if (!changelogs) return undefined;
  return changelogs.map((changelog: ChangelogFragment) => {
    const value = getAnnotationValue(range, changelog.date);
    return {
      userId: changelog.userId,
      feedbackIntegrationId: changelog.feedbackIntegrationId,
      feedbackIntegrationTitle: changelog.integration?.type.title,
      id: changelog.id,
      date: changelog.date,
      title: changelog.title,
      description: changelog.description,
      value: value,
    };
  });
};

export const useChartHook = (
  teamId: number,
  chartData: IChartableData,
  chartType: ChartType,
  setSettingsMenuOpen?: Dispatch<SetStateAction<boolean>>,
  disableAnnotations?: boolean,
  aggregateData?: number[],
  normalizedData?: number[],
  tooltipLabels?: string[],
  chartLabels?: string[],
  isDataFiltered?: boolean
) => {
  const { app } = useContext(AppContext);
  const [annotations, setAnnotations] = useState<IAnnotation[] | undefined>();
  const [menuCoords, setMenuCoords] = useState<IChartCoordinates>({ x: 0, y: 0, value: -1 });
  const [currentAnnotation, setCurrentAnnotation] = useState<IAnnotation>();
  const [createChangelogMutation] = useCreateChangelogDataMutation();
  const [deleteChangelogMutation] = useDeleteChangelogDataMutation();
  const [editChangelogMutation] = useEditChangelogDataMutation();
  const [range, setRange] = useState(disableAnnotations ? [] : getLabelRange(chartData.startDate, chartData.endDate));
  const [getChangelogData, { loading, data }] = useGetChangelogDataLazyQuery({ variables: { teamId: teamId } });
  useEffect(() => {
    if (!app?.isPreviewMode) getChangelogData();
  }, []);

  useEffect(() => {
    if (!loading) {
      getAnnotations();
    }
  }, [loading, data]);
  useEffect(() => {
    if (!chartData.startDate || !chartData.endDate || disableAnnotations) return;
    setRange(getLabelRange(chartData.startDate, chartData.endDate));
  }, [chartData.startDate, chartData.endDate]);

  /**
   * This function creates a changelog event and adds the new annotation to each chart.
   * @param title
   * @param date
   * @param description
   */
  const createAnnotation = (title: string, date: Date, description: string) => {
    createChangelogMutation({
      variables: { teamId: teamId, date: date.valueOf(), title: title, description: description },
      refetchQueries: [
        {
          query: GetChangelogDataDocument,
          variables: { teamId: teamId },
        },
      ],
    });
  };
  /**
   * This function deletes the current changelog event and removes that annotation from each chart.
   */
  const deleteAnnotation = () => {
    if (currentAnnotation) {
      deleteChangelogMutation({
        variables: { teamId: teamId, changelogId: currentAnnotation.id },
        refetchQueries: [
          {
            query: GetChangelogDataDocument,
            variables: { teamId: teamId },
          },
        ],
      });
    }
  };
  /**
   * This function updates an existing changelog event across all charts
   * @param title
   * @param date
   * @param description
   */
  const editAnnotation = (title: string, date: Date, description: string) => {
    if (currentAnnotation) {
      editChangelogMutation({
        variables: { teamId: teamId, changelogId: currentAnnotation.id, date: date.valueOf(), title: title, description: description },
        refetchQueries: [
          {
            query: GetChangelogDataDocument,
            variables: { teamId: teamId },
          },
        ],
      });
    }
  };

  const getAnnotations = () => {
    const processedAnnotations = processAnnotations(data?.getChangelogs, range);
    setAnnotations(processedAnnotations);
  };

  const fullChartData = aggregateData
    ? getChartFromData(aggregateData, normalizedData!, tooltipLabels!, chartLabels!)
    : getChartData(chartType, chartData.data, chartData.startDate, chartData.endDate, chartData.denominator);

  return {
    loading: loading,
    chartData: {
      labels: fullChartData.labels,
      datasets: fullChartData.datasets,
    } as ChartData,
    chartOptions: getChartOptions(
      chartType,
      tooltipLabels ? tooltipLabels : fullChartData.fullLabels,
      disableAnnotations,
      annotations,
      setMenuCoords,
      setSettingsMenuOpen,
      false,
      isDataFiltered
    ),
    range: range,
    annotations,
    currentAnnotation,
    menuCoords,
    setCurrentAnnotation,
    createAnnotation,
    deleteAnnotation,
    editAnnotation,
  };
};

/**
 * Returns tooltip properties for a specific chart type
 * @param chartType
 * @returns
 */
const getTooltipOptions = (fullLabels: string[], disableAnnotations?: boolean, annotations?: IAnnotation[], isDataFiltered?: boolean) => {
  return {
    ...getToolTipFormatting(),
    callbacks: {
      title: (tooltipItem: TooltipItem<'bar' | 'line'>[]) => {
        if (tooltipItem.length > 0) {
          return fullLabels[tooltipItem[0].dataIndex];
        }
      },
      footer: (tooltipItem: TooltipItem<'bar' | 'line'>[]) => {
        if (disableAnnotations) return '';
        if (!annotations || annotations.findIndex((annotation) => annotation.value === tooltipItem[0].dataIndex) === -1) return '';
        const annotationsAtValue = annotations.filter((annotation) => annotation.value === tooltipItem[0].dataIndex);
        const numberOfEventsToShow = 2;
        return `Events: ${annotationsAtValue
          .filter((_, i) => i < numberOfEventsToShow)
          .map(
            (annotation) =>
              `${annotation.feedbackIntegrationTitle ? getOSVersion(annotation.feedbackIntegrationTitle) : ''} ${annotation.title} release (${moment(
                annotation.date
              ).format("MMM Do 'YY")})`
          )
          .join(', ')}${
          annotationsAtValue.length > numberOfEventsToShow
            ? ` and ${annotationsAtValue.length - numberOfEventsToShow} more event${annotationsAtValue.length - numberOfEventsToShow !== 1 ? 's' : ''}.`
            : ''
        }`;
      },
      label: function (data: TooltipItem<'bar' | 'line'>) {
        if (data.element instanceof BarElement) {
          return `${data.formattedValue} entr${data.raw === 1 ? 'y' : 'ies'}`;
        }
        let floor = Math.round(parseFloat(data.formattedValue.replace(/,/, '.')) * 10) / 10;
        if (floor === 0) {
          floor = Number(data.formattedValue);
        }
        return floor + `% of ${isDataFiltered ? 'filtered ' : ''}feedback`;
      },
    },
  };
};

/**
 * Returns legend properties for a specific chart type
 * @param chartType
 * @returns
 */
const getLegendOptions = (chartType: ChartType) => {
  switch (chartType) {
    case ChartType.Mixed:
      return { display: false } as LegendOptions<'bar' | 'line'>;
  }
};

/**
 * Returns title properties for a specific chart type
 * @param chartType
 * @returns
 */
const getTitleOptions = (chartType: ChartType) => {
  switch (chartType) {
    case ChartType.Mixed:
      return undefined;
  }
};

/**
 * Returns scale properties for a specific chart type
 * @param chartType
 * @returns
 */
const getScaleOptions = (
  chartType: ChartType,
  isScreenshot?: boolean
):
  | Partial<{
      [key: string]: ScaleOptionsByType<'linear'>;
    }>
  | undefined => {
  switch (chartType) {
    case ChartType.Mixed:
      return {
        x: {
          display: true,

          //@ts-ignore
          grid: {
            display: false,
          },
          //@ts-ignore

          ticks: {
            //@ts-ignore
            display: true,
            font: {
              size: isScreenshot ? 18 : 13,
              family: 'SofiaPro',
            },
          },
        },
        percentage: {
          position: 'left',
          //@ts-ignore
          grid: {
            display: false,
          },
          display: true,
          //@ts-ignore
          ticks: {
            font: {
              size: isScreenshot ? 18 : 13,
              family: 'SofiaPro',
            },
            autoSkip: !!isScreenshot,
            maxTicksLimit: 5,
            callback: isScreenshot
              ? function (value: string | number, index: number, values: Tick[]) {
                  return value + '%';
                }
              : function (value: string | number, index: number, values: Tick[]) {
                  if (index === values.length - 1) return value + '%';
                  else if (index === 0) return value + '%';
                  else return '';
                },
          },
          beginAtZero: true,
          afterDataLimits: (scale: Scale<CoreScaleOptions>) => {
            scale.min = 0;
          },
        },
        mentions: {
          position: 'right',
          //@ts-ignore
          grid: {
            display: false,
          },
          //@ts-ignore
          ticks: {
            autoSkip: !!isScreenshot,
            maxTicksLimit: 5,
            font: {
              size: isScreenshot ? 18 : 13,
              family: 'SofiaPro',
            },
            precision: 0,
            //@ts-ignore
            callback: isScreenshot
              ? function (value: string, index: number, values: Tick[]) {
                  return value;
                }
              : function (value: string, index: number, values: Tick[]) {
                  if (index === values.length - 1) return value;
                  else if (index === 0) return value;
                  else return '';
                },
          },
          beginAtZero: true,
        },
      };
  }
};

const isPointClickable = (event: ChartEvent, elements: ActiveElement[]) => {
  // ^ when a click is registered, the nearest annotation is returned
  // but we only want to view an annotation's menu if we click within a certain range
  // + or - 30 pixels seems to be a good range imo
  return Math.abs(elements[0].element.x - event.x!) <= 30;
};

/**
 * Returns chart layout options for a specific chart type.
 * @param chartType
 * @returns
 */
export const getChartOptions = (
  chartType: ChartType,
  fullLabels: string[],
  disableAnnotations?: boolean,
  annotations?: IAnnotation[],
  setMenuCoords?: Dispatch<SetStateAction<IChartCoordinates>>,
  setSettingsMenuOpen?: Dispatch<SetStateAction<boolean>>,
  isScreenshot?: boolean,
  isFeedbackFiltered?: boolean
): ChartOptions => {
  return {
    onClick(event, elements, chart) {
      if (event.x && event.y && isPointClickable(event, elements)) {
        // to avoid menu being cut off by overflowing columns
        // we set the x coordinate to always be less than the right side of the chart - settings menu width
        // (which is 256 pixels)
        setMenuCoords?.({
          x: event.x < chart.chartArea.right - 256 ? event.x : chart.chartArea.right - 256,
          y: event.y,
          value: elements[0].index,
        });
        setSettingsMenuOpen && setSettingsMenuOpen(true);
      }
    },
    onHover(event, elements, chart) {
      if (elements[0]) {
        if (isPointClickable(event, elements)) {
          chart.canvas.style.cursor = 'pointer';
        } else {
          chart.canvas.style.cursor = 'default';
        }
      }
    },
    animation: {
      duration: 750,
    },
    layout: {
      padding: {
        top: 0,
        bottom: 0,
      },
    },
    indexAxis: chartType === ChartType.Horizontal ? 'y' : 'x',
    //@ts-ignore
    lineTension: 0.3,
    interaction: {
      intersect: false,
    },
    hover: {
      mode: 'index',
      intersect: false,
    },
    plugins: {
      title: getTitleOptions(chartType),
      annotation: {
        interaction: {
          intersect: false,
        },
        annotations: annotations?.reduce((acc, annotation, i) => {
          const a = {
            ...annotation,
            id: annotation.id.toString(),
            annotationId: annotation.id.toString(),
            type: 'line',
            borderColor: 'black',
            borderWidth: chartType === ChartType.Mixed ? 0 : 5,
            borderDash: [3, 3],
            borderDashOffset: 0,
            scaleID: 'x',
          } as AnnotationOptions;
          return { ...acc, [i]: a };
        }, {}),
      },
      legend: {
        display: !!isScreenshot,
        position: 'top',
        fullSize: false,
        labels: {
          font: {
            size: 16,
            family: 'SofiaPro',
          },
          boxWidth: 10,
          boxHeight: 10,
        },
      },
      //@ts-ignore
      tooltip: getTooltipOptions(fullLabels, disableAnnotations, annotations, isFeedbackFiltered) as
        | Partial<TooltipOptions<'bar' | 'line'> | undefined>
        | undefined,
    },
    elements: {
      point: {
        radius: 3,
      },
      bar: {
        borderRadius: 14,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: getScaleOptions(chartType, isScreenshot) as
      | Partial<{
          [key: string]: ScaleOptionsByType<'radialLinear' | keyof CartesianScaleTypeRegistry>;
        }>
      | undefined,
  };
};

/**
 * Returns chart data usable w/ ChartJS for a specific chart type.
 * @param data
 * @param startDate
 * @param endDate
 * @param denominator
 * @returns
 */
export const getChartData = (
  chartType: ChartType,
  data: IChartingSeries[],
  startDate: Date,
  endDate: Date,
  denominator?: IChartingSeries,
  isScreenshot?: boolean
) => {
  const { aggregateData, normalizedData, bins } = getNormalizedAndAggregateData(data, startDate, endDate, denominator);
  return {
    // if we have more steps it means we're looking at a wider time window.
    fullLabels: getChartLabels(bins, startDate, endDate, false),
    labels: getChartLabels(bins, startDate, endDate, true).map((label, index) => {
      if (index % 2 === 0) return label;
      return '';
    }),
    datasets: [
      {
        type: 'line',
        backgroundColor: '#353B70',
        borderColor: '#353B70',
        //@ts-ignore
        pointBorderColor(context, options) {
          return '#353B70';
        },
        //@ts-ignore
        pointBackgroundColor(context, options) {
          return '#353B70';
        },
        data: normalizedData.length > 0 ? normalizedData : new Array(bins.length).fill(0),
        label: 'Share of Feedback (%)',
        yAxisID: 'percentage',
      },
      {
        type: 'bar',
        backgroundColor: `rgba(205, 90, 118, ${isScreenshot ? '0.6' : '0.3'})`,
        hoverBackgroundColor: 'rgba(205, 90, 118, 1)',
        barPercentage: 0.75,
        minBarLength: 2,
        data: aggregateData.length > 0 ? aggregateData[0].yaxis : new Array(bins.length).fill(0),
        label: 'Absolute Mentions',
        yAxisID: 'mentions',
      },
    ],
  };
};

/**
 *
 * @param bins - number[] each value in array is a timestamp in seconds past epoch
 * @param endDate
 * @returns
 */
export const getChartLabels = (bins: number[], startDate: Date, endDate: Date, condensed?: boolean): string[] => {
  return bins.map((bin, index) => {
    const start = moment(startDate);
    const end = moment(endDate);
    let next = bins[index + 1];
    if (!next) {
      next = moment(endDate)!.valueOf();
    }
    // if we're not in condensed view, we want to show the full date range
    if (!condensed)
      return end.diff(start, 'days') <= 7
        ? // bin sizes for date ranges <= 7 are exactly 24 hours apart
          moment.utc(bin).local().format('DD MMM')
        : bins.length <= 6
        ? // for time spans shorter than 6 months, show date range
          moment.utc(bin).local().format('DD MMM YYYY') + ' - ' + moment.utc(next).local().format('DD MMM YYYY')
        : // for time spans longer than 6 months, show month + year
          moment.utc(bin).format("MMM 'YY");
    return end.diff(start, 'days') < 60
      ? // show date + month for date ranges less than 60 days
        moment.utc(bin).local().format('MMM D')
      : // for date ranges greater than 60 days, show month only
        moment
          .utc(bin + (next - bin) / 2)
          .local()
          .format('MMM');
  });
};

export const getNormalizedAndAggregateData = (
  data: IChartingSeries[],
  startDate: Date,
  endDate: Date,
  denominator?: IChartingSeries
): { aggregateData: IChartableSeries[]; normalizedData: number[]; bins: number[] } => {
  const bins = getLabelRange(startDate, endDate);
  let denominatorAggregate: IChartableSeries | undefined = undefined;
  let aggregateData: IChartableSeries[];
  if (denominator) {
    aggregateData = getAggregateData([...data, denominator], bins);
    denominatorAggregate = aggregateData.pop();
  } else {
    aggregateData = getAggregateData(data, bins);
  }
  const normalizedData = getNormalizedData(denominatorAggregate, aggregateData);
  return { aggregateData, normalizedData, bins };
};

const getNormalizedData = (denominatorAggregate: IChartableSeries | undefined, aggregateData: IChartableSeries[]): number[] => {
  const normalizedData = aggregateData.flatMap((series) => {
    series.yaxis.pop();
    let yaxis: number[] = series.yaxis;
    if (denominatorAggregate) {
      yaxis = yaxis.map((value, index) => {
        if (value === 0) {
          return 0;
        }
        return (value / denominatorAggregate!.yaxis[index]) * 100;
      });
    }
    return yaxis;
  });
  return normalizedData;
};

export const getToolTipFormatting = () => {
  return {
    backgroundColor: '#FFF',
    bodyColor: '#292E5B',
    titleColor: '#292E5B',
    footerColor: '#292E5B',
    mode: 'index',
    position: 'custom',
    intersect: false,
    enabled: true,
    titleFont: {
      family: 'SofiaPro',
      size: 10,
    },
    titleMarginBottom: 1,
    bodyFont: {
      family: 'SofiaPro',
      size: 10,
    },
    padding: 10,
    footerFont: {
      size: 10,
      family: 'SofiaPro',
      style: 'italic',
      weight: 'normal',
    },
    borderWidth: 1,
    borderColor: '#292E5B',
    footerMarginTop: 1,
  };
};

export const getChartFromData = (aggregateData: number[], normalizedData: number[], labels: string[], chartLabels: string[], isScreenshot?: boolean) => {
  return {
    fullLabels: labels,
    labels: chartLabels,
    datasets: [
      {
        type: 'line',
        backgroundColor: '#353B70',
        borderColor: '#353B70',
        //@ts-ignore
        pointBorderColor(context, options) {
          return '#353B70';
        },
        //@ts-ignore
        pointBackgroundColor(context, options) {
          return '#353B70';
        },
        data: normalizedData,
        label: 'Share of Feedback (%)',
        yAxisID: 'percentage',
      },
      {
        type: 'bar',
        backgroundColor: `rgba(205, 90, 118, ${isScreenshot ? '0.6' : '0.3'})`,
        hoverBackgroundColor: 'rgba(205, 90, 118, 1)',
        barPercentage: 0.75,
        minBarLength: 2,
        data: aggregateData,
        label: 'Absolute Mentions',
        yAxisID: 'mentions',
      },
    ],
  };
};
