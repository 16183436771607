import { TrashIcon } from '@heroicons/react/24/solid';
import { FeedbackEntriesQuery, GroupMembershipFragment, Group_Membership_Action, Group_Status, useGetGroupsLightQuery } from '../../generated/graphql';
import { ArrayElement } from '../../utilities';
import { Fragment, useState } from 'react';
import { useValidTeamAppContext } from '../../v2/contexts/AppContext';
import Tippy from '@tippyjs/react';
import 'tippy.js/themes/light.css';
import { SentenceEntry } from '../../v2/hooks/GroupHook';
import { ExploreGroupHook } from '../hooks/ExploreGroupHook';
import AdjustableLoadingIcon from '../../baseComponents/AdjustableLoadingIcon';
import { HomeGroupHook } from '../hooks/HomeGroupHook';
import 'tippy.js/themes/light.css';
import { AppRoutes } from '../../Routes';
import { Popover, Transition } from '@headlessui/react';
import { classNames, truncateAndEllipsis } from '../../v2/util';
import toast from 'react-hot-toast';
import SearchInput from '../baseComponents/SearchInput';
import { SmallSpinner } from './SmallSpinner';
import { useEditGroupMembershipHook } from '../hooks/EditGroupMembershipHook';
import YesCancelModal from './Modals/YesCancelModal';
import { getGroupPageUrl } from '../lib/groups';

const moreGroupsInfoCss = {
  normal: 'bg-gray-200 text-blueberry',
  flashing: 'bg-raspberry text-white scale-125',
};

export const GroupMembershipSection = ({
  entry,
  groupSentences,
  groupHook,
  amountToShow = 4,
}: {
  entry: ArrayElement<FeedbackEntriesQuery['entries']>;
  groupSentences?: SentenceEntry[];
  groupHook?: ExploreGroupHook | HomeGroupHook;
  amountToShow?: number;
}) => {
  const [groupMemberships, setGroupMemberships] = useState<GroupMembershipFragment[]>(entry.groupMemberships ?? []);

  const [isShowing, setIsShowing] = useState(false);
  const [moreGroupsInfoColor, setMoreGroupsInfoColor] = useState<string>(moreGroupsInfoCss['normal']);
  const { curTeamId: teamId, curOrgId: orgId } = useValidTeamAppContext();

  const editGroupMembershipHook = useEditGroupMembershipHook();

  const flashMoreGroupsInfoBg = () => {
    setMoreGroupsInfoColor(moreGroupsInfoCss['flashing']);
    setTimeout(() => {
      setMoreGroupsInfoColor(moreGroupsInfoCss['normal']);
    }, 750);
  };

  const handleModifyMembership = async (groupId: number, action: Group_Membership_Action) => {
    try {
      const updatedMemberships = await editGroupMembershipHook.handleModifyMembership({ entryId: entry.id, teamId, groupId, action });
      setGroupMemberships(updatedMemberships);
      if (groupHook) groupHook.updateEntryGroupMemberships(entry.id, updatedMemberships);
      flashMoreGroupsInfoBg();
      if (action === 'add') toast.success('Successfully added entry to the group and any inherited parent groups');
      else toast.success('Removed entry from the group and any inherited parent groups');
    } catch (err) {
      console.error(err);
      if (action === 'add') toast.error('Failed to add entry to group');
      else toast.error('Failed to remove entry from the group');
    }
  };

  const amountToHide = groupMemberships.length - amountToShow;

  return (
    <div className="flex flex-row gap-x-1 items-center gap-y-1 flex-wrap">
      <h1 className="text-sm">Groups:</h1>
      <ChooseGroupPopover
        existingMemberships={groupMemberships}
        handleAddMembership={(groupId: number) => handleModifyMembership(groupId, Group_Membership_Action.Add)}
      />
      {groupMemberships.slice(0, amountToShow).map((groupLight, index) => {
        return (
          <GroupOnEntryBadge
            groupLight={groupLight}
            key={index}
            groupSentences={groupSentences}
            groupHook={groupHook}
            handleRemoveMembership={(groupId: number) => handleModifyMembership(groupId, Group_Membership_Action.Remove)}
            trimTitle={true}
          />
        );
      })}
      {amountToHide > 0 ? (
        <Popover>
          <Popover.Group>
            <Popover.Button
              onMouseEnter={() => setIsShowing(true)}
              onMouseLeave={() => setIsShowing(false)}
              className={classNames(
                'items-center group flex flex-row gap-x-1 duration-300 py-1 px-3 rounded-lg text-xs cursor-default flex-wrap whitespace-nowrap',
                moreGroupsInfoColor
              )}
            >
              <h1>+{amountToHide}</h1>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
              show={isShowing}
            >
              <Popover.Panel
                className={`absolute z-10 w-96 px-6 py-3 transform  -translate-x-[90%]  sm:px-0`}
                onMouseEnter={() => setIsShowing(true)}
                onMouseLeave={() => setIsShowing(false)}
                onPointerDown={(e) => e.stopPropagation()}
                onClick={(e) => e.stopPropagation()}
              >
                <div className="max-h-32 gap-x-1 gap-y-1 bg-white rounded-lg shadow-md ring-1 ring-black ring-opacity-5 px-3 py-2 flex flex-row overflow-x-hidden overflow-y-auto flex-wrap">
                  {groupMemberships.slice(amountToShow)!.map((groupLight, index) => (
                    <GroupOnEntryBadge
                      groupLight={groupLight}
                      key={index}
                      groupSentences={groupSentences}
                      groupHook={groupHook}
                      handleRemoveMembership={(groupId: number) => handleModifyMembership(groupId, Group_Membership_Action.Remove)}
                      trimTitle={true}
                    />
                  ))}
                </div>
              </Popover.Panel>
            </Transition>
          </Popover.Group>
        </Popover>
      ) : null}
    </div>
  );
};

const GroupOnEntryBadge = ({
  groupLight,
  groupSentences,
  groupHook,
  handleRemoveMembership,
  trimTitle,
}: {
  groupLight: GroupMembershipFragment;
  groupSentences?: SentenceEntry[];
  groupHook?: ExploreGroupHook | HomeGroupHook;
  handleRemoveMembership: (groupId: number) => Promise<void>;
  trimTitle?: boolean;
}) => {
  const { curTeamId: teamId, curOrgId: orgId } = useValidTeamAppContext();
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [removeModalOpen, setRemoveModalOpen] = useState(false);

  const handleRemoveFromGroup = async () => {
    setLoadingDelete(true);

    //LOGIC: If the group is the same we're looking at, use the existing deleteSentence logic. In all other cases, use the new one.
    //This is basically to let the current group handle removing the entry from its UI (not needed if it's a different group or Feedback Page).

    const isCurGroup = groupHook && groupHook.currentGroup && groupHook.currentGroup.id === groupLight.id;
    if (!isCurGroup) {
      await handleRemoveMembership(groupLight.id);
    } else {
      if (groupSentences) {
        setLoadingDelete(true);
        for (let i = 0; i < groupSentences.length; i++) {
          await groupHook.deleteSentence(groupSentences[i].id, groupHook.currentGroup?.id ?? -1, () => {});
        }
      }
    }
    setLoadingDelete(false);
    setRemoveModalOpen(false);
  };

  return (
    <a
      href={getGroupPageUrl(teamId, orgId, groupLight.id, AppRoutes.v3FullPath.explore)}
      target="_blank"
      className="group/grouponentry relative items-center group flex flex-row duration-150 py-1 px-3 bg-gray-200 text-blueberry rounded-lg text-xs cursor-pointer flex-wrap whitespace-nowrap hover:bg-blueberry hover:text-white "
      onClick={async (e) => e.stopPropagation()}
    >
      {removeModalOpen ? (
        <YesCancelModal
          text={`Are you sure you want to remove this entry from group "${groupLight.title}"?`}
          subtext="This will also remove it from all children and inherited parents"
          modalOpen={removeModalOpen}
          confirmText="Yes, remove"
          confirmButton={handleRemoveFromGroup}
          callbackModal={() => {
            setRemoveModalOpen(false);
          }}
          loadingConfirm={loadingDelete}
        />
      ) : null}
      <div>
        {loadingDelete ? (
          <div
            data-testid="sentence-remove-loading"
            className="z-10 absolute -right-1.5 -top-1.5 duration-100 h-3.5 w-3.5 p-1 bg-gray-500 rounded-full cursor-pointer text-milk opacity-80 ring-0 focus:ring-0"
          >
            <AdjustableLoadingIcon color={'text-milk'} width={1.5} height={1.5} />
          </div>
        ) : (
          <div className="z-10 absolute -right-1.5 -top-1.5 opacity-0 transition-opacity duration-100 h-4 w-4 p-0.5 bg-gray-500 rounded-full cursor-pointer text-milk hover:bg-gray-700 group-hover/grouponentry:opacity-100">
            <Tippy theme="dark" delay={200} placement="top" content={<p className="text-center">Remove this feedback entry from this group</p>}>
              <TrashIcon
                id="remove-entry-from-group"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setRemoveModalOpen(true);
                }}
              />
            </Tippy>
          </div>
        )}
      </div>
      <p className="text-xxs pt-0.5">{trimTitle ? truncateAndEllipsis(groupLight.title, 40) : groupLight.title}</p>
    </a>
  );
};

const ChooseGroupPopover = ({
  existingMemberships,
  handleAddMembership,
}: {
  existingMemberships: GroupMembershipFragment[];
  handleAddMembership: (groupId: number) => Promise<void>;
}) => {
  const { curTeamId: teamId, curOrgId: orgId } = useValidTeamAppContext();
  //This is cached so it's only fetched once.
  const { data: allGroupsData, loading: allGroupsLoading } = useGetGroupsLightQuery({ variables: { teamId, status: Group_Status.Monitored } });
  const groupsToShow = allGroupsData?.getAllGroups.filter((group) => !existingMemberships.some((membership) => membership.id === group.id));
  const [loadingAdd, setLoadingAdd] = useState(false);

  const [searchString, setSearchString] = useState<string>('');

  const filteredGroups = groupsToShow?.filter((group) => group?.title.toLowerCase().includes(searchString.toLowerCase()));
  return (
    <Popover>
      <Popover.Group>
        <Popover.Button
          className={classNames(
            'items-center group flex flex-row gap-x-1 duration-150 py-1 px-2 bg-gray-200 text-blueberry rounded-lg text-xs  flex-wrap whitespace-nowrap cursor-pointer hover:bg-blueberry hover:text-white'
          )}
        >
          <h1>+</h1>
        </Popover.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <Popover.Panel
            className={`absolute z-50 w-96 px-6 py-3 transform sm:px-0`}
            onPointerDown={(e) => e.stopPropagation()}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="max-h-64 gap-x-1 gap-y-1 bg-white rounded-lg shadow-md px-3 py-2 flex flex-row  overflow-y-auto flex-wrap">
              <div className="flex flex-row gap-x-3 items-center">
                <h1 className="font-bold">{loadingAdd ? 'Adding entry to group...' : 'Add entry to group'}</h1>
                {loadingAdd ? <SmallSpinner /> : null}
              </div>
              <SearchInput
                noPadding
                onSearch={() => {}}
                setQueryString={(string) => setSearchString(string ?? '')}
                queryString={searchString}
                placeholder="Search group..."
              />
              <div className={classNames(loadingAdd ? 'opacity-70' : '', 'w-full')}>
                {filteredGroups?.map((groupLight, index) => (
                  <div
                    className={classNames(
                      'text-blueberry w-full px-2 py-1 duration-150 rounded-md',
                      loadingAdd ? 'cursor-default' : 'hover:bg-blueberry hover:text-white w-full px-2 py-1 duration-150 cursor-pointer rounded-md'
                    )}
                    onClick={async (e) => {
                      e.stopPropagation();
                      if (!groupLight || loadingAdd) return;
                      setLoadingAdd(true);
                      await handleAddMembership(groupLight.id);
                      setSearchString('');
                      setLoadingAdd(false);
                    }}
                  >
                    <h1 className="text-sm">{groupLight?.title}</h1>
                  </div>
                ))}
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover.Group>
    </Popover>
  );
};
