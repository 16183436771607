import { ExclamationTriangleIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import { FeedbackEntriesQuery } from '../../generated/graphql';
import { ArrayElement } from '../../utilities';
import { useEffect, useState } from 'react';
import { useValidTeamAppContext } from '../../v2/contexts/AppContext';
import Tippy from '@tippyjs/react';
import 'tippy.js/themes/light.css';
import { Reply } from '../hooks/ReplyHook';
import { IDropDownItem } from '../baseComponents/DropDown';
import { selector } from '../pages/ReplyingPage';
import 'tippy.js/themes/light.css';
import TemplateDropDown from './replying/TemplateDropdown';

interface IReplyBox {
  entry: ArrayElement<FeedbackEntriesQuery['entries']>;
  handleGenerateReply: (text: string, entry: ArrayElement<FeedbackEntriesQuery['entries']>) => Promise<void>;
  handleIndividualReply: (entryId: number, text: string, cb?: () => void) => Promise<void>;
  reply?: Reply;
}

export const ReplyBox = ({ entry, handleGenerateReply, handleIndividualReply, reply }: IReplyBox) => {
  const { currentUserIsAdmin } = useValidTeamAppContext();
  const [loading, setLoading] = useState(false);
  const [replySending, setReplySending] = useState(false);
  const [currentReply, setReply] = useState<Reply>(reply ?? { text: '', error: '', success: false });

  const [selectedItem, setSelectedItem] = useState<IDropDownItem | undefined>(undefined);
  useEffect(() => {
    if (selectedItem?.value) {
      setReply((prev) => ({ ...prev, text: selectedItem.value as string }));
    }
  }, [selectedItem]);
  useEffect(() => {
    if (reply) {
      setReply(reply);
    }
  }, [reply]);
  const getGptResponse = async () => {
    setLoading(true);
    try {
      await handleGenerateReply(currentReply?.text, entry);
    } catch (e: any) {
      if (e instanceof Error) {
        setReply((prev) => ({ ...prev, error: e.message, success: false }));
      }
    }
    setLoading(false);
  };

  if (currentReply?.success || (currentReply?.error && currentReply?.sent) || !currentUserIsAdmin) {
    return (
      <div className="flex flex-col gap-y-1 col-span-5">
        {currentReply?.error && currentReply?.sent ? (
          <div className="flex flex-row justify-center items-center gap-x-1">
            <ExclamationTriangleIcon className="h-4 w-4 text-red-500 stroke-2" />
            <p className="text-red-500 mt-[2px]">{currentReply.error}</p>
          </div>
        ) : null}
        {currentReply?.success || currentReply?.sent ? (
          <div className="flex flex-col  gap-y-3 rounded-3xl bg-silver h-full w-full px-6 py-5">
            <p>{currentReply.submitter}</p>
            <p>{currentReply.text}</p>
          </div>
        ) : !currentUserIsAdmin ? (
          <div className="flex flex-col  gap-y-3 rounded-3xl bg-silver h-full w-full px-6 py-5" data-testid="not-admin">
            <p>This feedback entry has no replies. You must be an administrator to leave feedback replies.</p>
          </div>
        ) : null}
      </div>
    );
  }

  return (
    <div className="flex items-start space-x-4 mt-4 bg-white">
      <div className="min-w-0 flex-1">
        <div className="relative">
          {currentReply?.error ? (
            <div className="flex flex-row justify-center items-center gap-x-1">
              <ExclamationTriangleIcon className="h-4 w-4 text-red-500 stroke-2" />
              <p className="text-red-500 mt-[2px]">{currentReply.error}</p>
            </div>
          ) : null}

          <div className="overflow-hidden rounded-lg shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-blueberry">
            <label htmlFor="comment" className="sr-only">
              Add your comment
            </label>
            <textarea
              rows={6}
              name="comment"
              id="comment"
              className="block w-full resize-none border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
              placeholder="Add your reply"
              value={currentReply?.text}
              onChange={(e) => setReply((prev) => ({ ...prev, text: e.target.value }))}
              data-testid="reply-textarea"
            />
            {/* Spacer element to match the height of the toolbar */}
            <div className="py-2" aria-hidden="true">
              <div className="py-px">
                <div className="h-9" />
              </div>
            </div>
          </div>

          <div className="absolute inset-x-0 bottom-0 flex justify-between pl-3 pr-2">
            <div className="flex items-center mb-1.5 space-x-5">
              <TemplateDropDown dropDownData={selector} setSelectedItem={(item) => setSelectedItem(item)} selectedItem={selectedItem} />
              <div className="flex items-center">
                <Tippy content="Generate a reply based on your current message" theme="light">
                  <button
                    type="button"
                    className="flex h-10 text-sm items-center justify-center rounded-full text-gray-400 hover:text-gray-500"
                    onClick={() => getGptResponse()}
                  >
                    Generate Reply
                    <InformationCircleIcon className="ml-2 w-5 h-5" />
                  </button>
                </Tippy>
              </div>
              {loading && (
                <div className="flex items-center space-x-2">
                  <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-blueberry bg-white text-white"></div>
                </div>
              )}
            </div>
            <div className="flex-shrink-0 flex flex-row mb-1.5">
              <button
                className="inline-flex items-center rounded-md bg-blueberry px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blueberry focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blueberry"
                onClick={async () => {
                  setReplySending(true);
                  await handleIndividualReply(entry.id, currentReply.text, () => setReplySending(false));
                }}
              >
                <div>Post</div>
              </button>
              {replySending && (
                <div className="flex items-center space-x-2 ml-2">
                  <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-blueberry bg-white text-white"></div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
