import { Fragment, useEffect } from 'react';
import { useValidTeamAppContext } from '../../../v2/contexts/AppContext';
import { Feedback_Integration_Type, useIntegrationsQuery, useListExternalTicketsQuery } from '../../../generated/graphql';
import { Popover, Transition } from '@headlessui/react';
import { TicketIcon } from '@heroicons/react/24/outline';
import { useExternalTicketsDispatch, useExternalTicketsState } from '../../../context/externalTicketsContext';
import { ExternalTicketsActionTypes } from '../../../reducers/externalTickets/externalTicketsReducer';
import { ExternalTicketsBrowsingBody } from './ExternalTicketsBrowsingBody';
import { ExternalTicketsListBody } from './ExternalTicketsListBody';
import toast from 'react-hot-toast';

export const ExternalTicketsWidget = ({ groupId }: { groupId: number }) => {
  const { curTeamId: teamId, curOrgId: orgId } = useValidTeamAppContext();
  const { curView, externalTickets } = useExternalTicketsState();
  const dispatch = useExternalTicketsDispatch();

  //I want integrations to load even if the mode is list...is this ok? then the query inside list will use the cached data?
  useIntegrationsQuery({ variables: { orgId, teamId, feedbackIntegrationType: Feedback_Integration_Type.ExternalTickets } });

  const { data: externalTicketsRes } = useListExternalTicketsQuery({
    variables: {
      groupId,
      teamId,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (externalTicketsRes) {
      dispatch({ type: ExternalTicketsActionTypes.SET_EXTERNAL_TICKETS, payload: { tickets: externalTicketsRes.listExternalTickets } });
    }
  }, [externalTicketsRes]);

  return (
    <Popover className="relative items-center font-sofiapro">
      <Popover.Button
        data-testid="external-tickets-widget-button"
        className="relative rounded-full bg-silver  text-blueberry flex flex-row justify-center items-center cursor-pointer h-8 w-8 hover:scale-110 duration-150 hover:shadow-sm focus:outline-none"
      >
        {externalTickets.length > 0 ? (
          <span
            data-testid="external-tickets-count"
            className="absolute text-sm right-0 top-0 block h-5 w-5 -translate-y-1/2 translate-x-1/2 transform rounded-full bg-blueberry-lighter text-white"
          >
            <p>{externalTickets.length}</p>
          </span>
        ) : null}
        <TicketIcon className="h-5 w-5 self-center" id="open-external-tickets" />
      </Popover.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-150"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel
          data-testid="external-tickets-widget-panel"
          className="absolute flex-col gap-y-4 right-0 z-50 text-blueberry bg-milk rounded-lg border border-opacity-50 shadow-lg p-2 flex w-[32rem]"
        >
          {({ close }) =>
            curView === 'list' ? (
              <div className="flex flex-col">
                <ExternalTicketsListBody tickets={externalTickets} dispatch={dispatch} groupId={groupId} />
              </div>
            ) : (
              <div className="flex flex-col gap-y-2">
                <ExternalTicketsBrowsingBody groupId={groupId} />
                <div
                  className="flex flex-row items-center bg-silver px-2 py-1 rounded-md duration-100 w-full justify-center select-none cursor-pointer hover:bg-silver-darker"
                  onClick={() => dispatch({ type: ExternalTicketsActionTypes.SET_VIEW, payload: { newView: 'list' } })}
                >
                  <h1>Browse linked action items</h1>
                </div>
              </div>
            )
          }
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};
