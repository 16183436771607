import { EllipsisVerticalIcon } from '@heroicons/react/24/solid';
import { PresentationChartLineIcon } from '@heroicons/react/24/outline';
import { useContext, useState } from 'react';
import InlineEditableTextArea from '../../baseComponents/InlineEditableTextArea';
import { GetOrganizationDocument, OrganizationsLightFragment, useDeleteTeamMutation, useEditTeamMutation } from '../../generated/graphql';
import SettingsMenu, { MenuAlign } from '../../baseComponents/SettingsMenu';
import { logEvent, Events } from '../../v2/AnalyticsUtil';
import UserContext from '../../v2/contexts/UserContext';
import { ifUnwrapperShowId } from '../../v2/util';
import YesCancelModal from '../components/Modals/YesCancelModal';
import { useOrganizationHook } from '../../v2/hooks/OrganizationHook';

export const ViewRow = ({
  team,
  userIsAdmin,
  orgId,
  orgName,
  pageName,
  onClick,
}: {
  team: OrganizationsLightFragment['teams'][0];
  userIsAdmin: boolean;
  orgId: string;
  orgName: string;
  pageName: string;
  onClick: () => void;
}) => {
  const { deleteTeam } = useOrganizationHook();
  const { user } = useContext(UserContext);
  const [deleteTeamModalOpen, setDeleteTeamModalOpen] = useState(false);
  const [deleteTeamMutation, deleteResult] = useDeleteTeamMutation({
    refetchQueries: [{ query: GetOrganizationDocument, variables: { orgId: Number(orgId) } }],
  });
  const [editTeamMutation, _1] = useEditTeamMutation({ refetchQueries: [{ query: GetOrganizationDocument, variables: { orgId: Number(orgId) } }] });
  const editTeam = (name: string) => {
    const teamName = user?.isUnwrapper ? filterTeamIds(name) : name;
    editTeamMutation({
      variables: {
        teamId: team.id,
        orgId: Number(orgId),
        name: teamName,
      },
    });
  };
  const filterTeamIds = (name: string) => {
    return name.replaceAll(ifUnwrapperShowId(user, team.id), '');
  };
  const handleDeleteTeam = async () => {
    await deleteTeam(team.id);
    setDeleteTeamModalOpen(false);
    logEvent(Events.RemovedView, { View_ID: team.id, View_Name: team.name, Org_ID: Number(orgId), Org_Name: orgName, Page: pageName });
  };
  const settingsItems = [{ id: 0, group: 'remove', name: 'Remove View', onClick: () => setDeleteTeamModalOpen(true) }];
  return (
    <>
      {
        <YesCancelModal
          modalOpen={deleteTeamModalOpen}
          callbackModal={setDeleteTeamModalOpen}
          idToPass={team.id}
          text={'Are you sure you want to delete this view?'}
          subtext={'This action cannot be undone'}
          closeOnConfirm={false}
          understandCheck={true}
          confirmButton={() => handleDeleteTeam()}
          confirmText={'Yes, delete'}
          loadingConfirm={deleteResult.loading}
        />
      }
      <div className={`my-4 grid grid-cols-[0.5fr_2fr_0.5fr] gap-4 rounded-xl bg-silver p-4`} onClick={onClick}>
        <div className="flex flex-row items-center justify-start">
          {team.logoUrl ? <img src={team.logoUrl} className="h-6 w-6" /> : <PresentationChartLineIcon className="h-6 w-6" />}
          {ifUnwrapperShowId(user, team.id)}
        </div>
        <div className="flex flex-row items-center justify-start" onClick={(e) => userIsAdmin && e.stopPropagation()}>
          <InlineEditableTextArea
            value={team.name}
            onEditComplete={editTeam}
            additionalClassNames="text-xl px-0.5 w-full hover:bg-gray-200"
            editable={userIsAdmin}
          />
        </div>
        {userIsAdmin && (
          <div className="flex flex-row items-center justify-center" onClick={(e) => userIsAdmin && e.stopPropagation()}>
            <SettingsMenu settings={settingsItems} align={MenuAlign.RIGHT}>
              <EllipsisVerticalIcon className="h-4 w-4" />
            </SettingsMenu>
          </div>
        )}
      </div>
    </>
  );
};
