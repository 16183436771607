import { GroupDataFragment, GroupTaxonomyFragment, TeamGroupsTaxonomyQuery } from '../../generated/graphql';
import { TaxonomyGroup } from '../../v2/hooks/GroupHook';

type TaxonomyActionType =
  | 'loadTaxonomy'
  | 'setTaxonomy'
  | 'updateTaxonomy'
  | 'toggleExpand'
  | 'toggleExpandAll'
  | 'setAncestors'
  | 'addGroup'
  | 'updateGroupTitle'
  | 'buildGroup'
  | 'error';

export interface TaxonomyAction {
  type: TaxonomyActionType;
  payload:
    | AddOrphanPayload
    | SetTaxonomyPayload
    | ToggleExpandPayload
    | TeamGroupsTaxonomyQuery
    | ToggleExpandAllPayload
    | ErrorPayload
    | SearchGroupsPayload
    | CreateGroupPayload
    | BuildGroupPayload
    | AddGroupPayload
    | SetAncestorsPayload
    | UpdateGroupTitlePayload;
}

export interface SetAncestorsPayload {
  groupId: number;
  ancestors: TaxonomyGroup[];
}
export interface AddOrphanPayload {
  getGroups: GroupTaxonomyFragment[];
}
export interface SetTaxonomyPayload {
  taxonomy: Map<number, TaxonomyGroup>;
}
export interface UpdateGroupTitlePayload {
  groupId: number;
  title: string;
}
export interface ToggleExpandPayload {
  groupId: number;
  state: ExpandState;
}

export interface ToggleExpandAllPayload {
  state: ExpandState;
}

export interface ErrorPayload {
  error: Error;
}

export interface SearchGroupsPayload {
  searchInput: string;
}

export interface CreateGroupPayload {
  searchInput: string;
}

export interface BuildGroupPayload {
  group: GroupDataFragment;
}
export interface AddGroupPayload {
  group: GroupDataFragment;
}

export enum ExpandState {
  Expanded = 'expanded',
  Collapsed = 'collapsed',
}
