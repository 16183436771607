import { ChartBarIcon, ChartBarSquareIcon } from '@heroicons/react/24/solid';

export const CustomChartCardSkeleton = ({ title, className }: { title?: string, className?: string }) => {
  return (
    <div
      data-testid="custom-chart-card-skeleton"
      className={`${className} custom-chart-card-skeleton space-y-5 rounded-lg bg-gray-100 relative 
        before:absolute before:inset-0
        before:-translate-x-full
        before:animate-[shimmer_2s_infinite]
        before:bg-gradient-to-r before:from-transparent before:via-blueberry  before:opacity-[0.2]
        isolate
        overflow-hidden
        before:border-t before:border-gray-100 opacity-70`}
    >
      {title ? (
        <div className="h-[3.3rem] text-white flex flex-col justify-center rounded-t-lg px-4 py-2 bg-blueberry">
          <div className="flex flex-row gap-x-3 items-center">
            <ChartBarSquareIcon className="h-8 w-8" />
            <div className="flex flex-col justify-start">
              <h1 className="font-bold">{title}</h1>
            </div>
          </div>
        </div>
      ) : (
        <div className="h-16 rounded-t-lg py-5 px-4 bg-gray-200">
          <div className="flex flex-row gap-x-3 items-center">
            <ChartBarSquareIcon className="h-6 w-6 text-gray-300" />
            <div className="w-1/3 h-6 flex rounded-lg bg-gray-300"></div>
          </div>
        </div>
      )}
      <div className="space-y-3 justify-center items-center flex py-8 h-[275px]">
        <ChartBarIcon className="h-32 w-32 text-gray-300" />
      </div>
    </div>
  );
};
