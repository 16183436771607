import { Dispatch } from 'react';

export enum GroupActions {
  SET_AUXILLARY_GROUP_DATA_LOADING,
  SET_AUXILLARY_GROUP_DATA_ERROR,
  UPDATE_AUXILLARY_GROUP_DATA,
  UPDATE_GROUP_TITLE,
}

export interface GroupState {
  groupData: {
    // we can place more items in this object as needed
    summaryText: string | null;
    title: string;
    id: number;
  } | null;
  auxillaryGroupDataErrorMessage: string | null;
  auxillaryGroupDataLoading: boolean;
}

interface LoadAuxilaryGroupData {}
interface AuxillaryGroupDataError {
  error: string;
}
interface UpdateAuxillaryGroupData {
  id: number;
  summaryText: string | null;
  title: string;
}
interface UpdateGroupTitle {
  title: string;
}

type ActionPayload = UpdateAuxillaryGroupData | LoadAuxilaryGroupData | AuxillaryGroupDataError | UpdateGroupTitle;

export type GroupDispatch = Dispatch<{
  type: GroupActions;
  payload: ActionPayload;
}>;

export interface GroupAction {
  type: GroupActions;
  payload: ActionPayload;
}

export const GroupReducer = (state: GroupState, action: GroupAction): GroupState => {
  switch (action.type) {
    case GroupActions.SET_AUXILLARY_GROUP_DATA_LOADING: {
      return {
        ...state,
        auxillaryGroupDataLoading: true,
        auxillaryGroupDataErrorMessage: null,
      };
    }
    case GroupActions.UPDATE_AUXILLARY_GROUP_DATA: {
      // cast payload to UpdateAuxillaryGroupData
      const payload = action.payload as UpdateAuxillaryGroupData;
      return {
        ...state,
        groupData: {
          summaryText: payload.summaryText,
          title: payload.title,
          id: payload.id,
        },
        auxillaryGroupDataLoading: false,
        auxillaryGroupDataErrorMessage: null,
      };
    }
    case GroupActions.SET_AUXILLARY_GROUP_DATA_ERROR: {
      // cast payload to AuxillaryGroupDataError
      const payload = action.payload as AuxillaryGroupDataError;
      return {
        ...state,
        auxillaryGroupDataErrorMessage: payload.error,
        auxillaryGroupDataLoading: false,
      };
    }
    case GroupActions.UPDATE_GROUP_TITLE: {
      // cast payload to UpdateGroupTitle
      const payload = action.payload as UpdateGroupTitle;
      if (!state.groupData) {
        throw new Error('Cannot update group title when group is not loaded');
      }
      return {
        ...state,
        groupData: {
          ...state.groupData,
          title: payload.title,
        },
      };
    }
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
