import { Popover, Transition } from '@headlessui/react';
import { Icon } from '@iconify/react';
import GroupTaxonomyEdit from '../../GroupTaxonomyEdit';
import Tippy from '@tippyjs/react';
import { useRef, useState } from 'react';
import { useClickOutside } from '../../../../utilities';
import { GroupFull, TaxonomyGroup } from '../../../../v2/hooks/GroupHook';
import { ExploreGroupHook } from '../../../hooks/ExploreGroupHook';
import { HomeGroupHook } from '../../../hooks/HomeGroupHook';
import { FilterInput } from '../../../../generated/graphql';

export function EditTaxonomyButton (props: {group: GroupFull, groupHook: ExploreGroupHook | HomeGroupHook, filters: FilterInput, taxonomy: Map<number, TaxonomyGroup>}) {
  const openTaxonomyOnLoad = window.location.search.includes('taxonomy=true');
  const [taxonomyOpen, setTaxonomyOpen] = useState<boolean>(openTaxonomyOnLoad ?? false);

  const taxonomyButtonRef = useRef<HTMLButtonElement>(null);
  const taxonomyRef = useRef<HTMLDivElement>(null);

  useClickOutside([taxonomyButtonRef, taxonomyRef], () => setTaxonomyOpen(false));

  const {group, groupHook, filters, taxonomy} = props;


  return <Tippy content="Edit Taxonomy" theme="dark" delay={200}>
    <Popover>
      {({ open }) => (
        <>
          <Popover.Button
            ref={taxonomyButtonRef}
            className="rounded-full bg-silver  text-blueberry flex flex-row justify-center items-center cursor-pointer h-8 w-8 hover:scale-110 duration-150 hover:shadow-sm focus:outline-none"
          >
            <Icon icon="mdi:file-tree" color={'#292e5b'} width="20" height="20"
                  className="cursor-pointer" />
          </Popover.Button>

          <Transition
            as={'div'}
            show={open || taxonomyOpen}
            enter="transition ease-out duration-150"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            {(open || taxonomyOpen) && (
              <div ref={taxonomyRef}>
                <Popover.Panel static className="absolute right-0 px-8 z-[9999]">
                  {!!group && (
                    <GroupTaxonomyEdit
                      groupId={Number(group?.id)}
                      parentId={group.id && taxonomy ? taxonomy.get(group.id)?.parentId : undefined}
                      filterInput={filters}
                      loadingStatuses={groupHook.loadingStatuses}
                      assignChild={groupHook.assignChild}
                      assignChildren={groupHook.assignChildren}
                      deleteChild={groupHook.deleteChild}
                      childCandidates={groupHook.childCandidates}
                      getChildCandidates={groupHook.getChildCandidates}
                      children={groupHook.children}
                      getChildren={groupHook.getChildren}
                      removeChildFromParent={groupHook.removeChildFromParent}
                      childrenToAssign={groupHook.childrenToAssign}
                      getChildrenToAssign={groupHook.getPotentialChildren}
                      taxonomy={taxonomy}
                    />
                  )}
                </Popover.Panel>
              </div>
            )}
          </Transition>
        </>
      )}
    </Popover>
  </Tippy>
}