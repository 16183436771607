import { FilterInput, PartialEntryFragment } from "../../generated/graphql";
import { EntryFetcher } from "../data_providers/EntryFetcher";
import { GraphDataProvider } from "../data_providers/GraphDataProvider";
import { DataExporter } from "./DataExporter";
import { FileExporter } from "./FileExporter";
import { DataFormat } from "./DataFormat";
import { ProgressMonitor, ProgressState } from "../ProgressMonitor";
import { assign, has } from "lodash";

type CSVRecord = Record<string, string | number>;

/**
 * Handles exporting feedback entries to an external source and providing observability into the progress of the export job.
 * @param entryProvider - responsible for fetching feedback_entries for the underlying group IDs
 * @param dataExporter - responsible for formatting and writing feedback data to an external source
 * @param progressMonitor - responsible for determining the progress of the export
 */
export class EntryExporter implements ProgressMonitor, DataExporter {

    private entryProvider: GraphDataProvider<PartialEntryFragment[]>;
    private dataExporter: DataExporter;
    private progressMonitor: ProgressMonitor;

    static getInstance(params: {
        teamId: number, 
        groupIds: number[], 
        filterInput?: FilterInput
    }) : EntryExporter {

        const entryFetcher = new EntryFetcher({
            teamId: params.teamId,
            groupIds: params.groupIds,
            filterInput: params.filterInput ?? {},
            pageSize: 350,
            numThreads: 5
        });

        const exporter = new FileExporter()

        return new this(entryFetcher, exporter, entryFetcher);
    }

    constructor(entryProvider: GraphDataProvider<PartialEntryFragment[]>, dataExporter: DataExporter, progressMonitor: ProgressMonitor) {
        this.entryProvider = entryProvider;
        this.dataExporter = dataExporter;
        this.progressMonitor = progressMonitor;
    }

    /**
     * Returns state of export progress.
     * @returns - ProgressState representing whether the export is in process and the percentage of records downloaded.
     */
    getProgress() : ProgressState {
        return this.progressMonitor.getProgress();
    }

    /**
     * Export entries in a CSV format and then download it to the user's browser
     * @param fileName - name of the CSV file
     * @param format - CSV, JSON, etc.
     * @returns - whatever the underlying library returns, we only really care about the side-effect (downloading the report as a file)
     */
    async export(fileName: string, format: DataFormat) : Promise<any> {
        const entries = await this.entryProvider.getData();
        if(entries.length <= 0) throw new Error(`No entries were found for this group with the current filters.`);

        const formattedEntries = this.formatData.bind(this)(entries);
        
        return this.dataExporter.export(fileName, format, formattedEntries);
    }

    private formatData(rawData: PartialEntryFragment[]) : CSVRecord[] {

        const customFields: Set<string> = this.getCustomFields(rawData);

        return rawData.map((entry: PartialEntryFragment) => {
            const showEntryText = entry.text != null && entry.text.length > 0 && !entry.fullConversation;
            let csvRow: CSVRecord = {
                'Groups': entry.allGroupTitles?.join(', ') ?? '',
                'Entry Source': entry.source ?? '',
                'Entry Text': showEntryText ? entry.text! : '',
                'Entry Date': entry.date ? new Date(entry.date).toISOString() : '',
                'Entry Id': entry.id,
                'Entry Permalink': entry.source_permalink ?? '',
                'Entry Summary': entry.distillateText ?? '',
                'Conversation': entry.fullConversation ?? '',
                'Sentiment': this.formatSentiment(entry.sentiment),
                'User': entry.submitter ?? '',
            };

            if(customFields.size > 0) {
                let customFieldValues : Record<string, string> = {};
                customFields.forEach((field) => customFieldValues[field] = '');
                customFieldValues = assign(customFieldValues, this.getCustomFieldValues(entry));
                csvRow = assign(csvRow, customFieldValues);
            }

            return csvRow;
        });
    }

    private formatSentiment(sentiment: number | null | undefined) {
        if(!sentiment) return '';
        switch (sentiment) {
            case 1: return 'Positive';
            case -1: return 'Negative';
            case 0: return 'Neutral';
            default: throw new Error(`Unknown sentiment value: ${sentiment}`);

        }
    }

    /**
     * 
     * @param entry - entry data with (or without) custom field data
     * @returns object with keys of the 
     */
    private getCustomFieldValues(entry: PartialEntryFragment) : Record<any, any> {
        if(!entry.segments || entry.segments.length < 1) return {};
        return entry.segments.reduce((acc: Record<string, string>, segment: any) => {
            if(has(acc, segment.groupName)) {
                acc[segment.groupName] += `, ${segment.value}`;
            } else {
                acc[segment.groupName] = segment.value;
            }
            return acc;
        }, {});
    }

    /**
     * This function gets the field names for all custom fields that occur in the data set.
     * @param rawData - entry data from the feedbackEntries graph API call
     * @returns - Set of all segment groupNames that occur in this data set
     */
    private getCustomFields(rawData: PartialEntryFragment[]) : Set<string> {
        const customFields = new Set<string>();

        rawData.forEach((entry: any) => {            
            if(entry.segments && entry.segments.length) {
                entry.segments.forEach((segment: any) => {
                    customFields.add(segment.groupName);
                });
            }
        });

        return customFields;
    }
}