import { SyntheticEvent, useState } from 'react';
import { ArrowDownTrayIcon, BookmarkIcon, Cog6ToothIcon, PaperClipIcon, PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline';
import SettingsMenu, { ISettingsItem, MenuAlign } from '../baseComponents/SettingsMenu';
import Tippy from '@tippyjs/react';
import 'tippy.js/themes/light.css';
import { FilterInput, Group_Trending } from '../../generated/graphql';
import { TaxonomyGroup, writeToastMessage } from '../../v2/hooks/GroupHook';
import { getFiltersStatsMode, truncate } from '../../v2/util';
import { useSearchGroupPolling } from '../../v2/hooks/ExploreHook';
import { useValidTeamAppContext } from '../../v2/contexts/AppContext';
import { ProgressBar } from './ProgressBar';
import { ProgressState, Status } from '../../exports/ProgressMonitor';
import { exportGroupWithProgressUpdate } from '../../eventHandlers/exports/groupExport';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router';
import { AppRoutes } from '../../Routes';
import { getGroupPageUrl } from '../lib/groups';
import { GroupMentionData } from './taxonomy/GroupMentionData';
import { GroupBuildProgress } from './taxonomy/GroupBuildProgress';
import { CopyGroupLink } from './taxonomy/CopyGroupLink';
import { TrendArrow } from './taxonomy/TrendArrow';
import { ToggleExpandGroup } from './taxonomy/ToggleExpandGroup';
import { PinGroupLink } from './taxonomy/PinGroupLink';
import { GroupBreadcrumbs } from './taxonomy/GroupBreadcrumbs';
import { NewBadge } from './taxonomy/NewBadge';
interface TaxonomyFolderProps {
  isSearchResult: boolean;
  taxonomyGroup: TaxonomyGroup;
  trending: Group_Trending | null | undefined;
  children: any[];
  teamId: number;
  setActiveId: (id: number | undefined) => void;
  itemRef: any;
  copyLink: (groupId: number) => void;
  togglePinGroup: (groupId: number, cb?: () => void) => void;
  setCurrentGroupId: (groupId: number) => void;
  updateProgress: (searchGroupId: number, newProgress: number) => void;
  filterInput: FilterInput | undefined;
  replaceOrAddToSearchGroups: (searchGroup: any) => void;
  zIndex: string;
  depth?: number;
}

function GroupSettingsMenu(props: { onClick: (e: SyntheticEvent) => void; settings: ISettingsItem[] }) {
  return (
    <div className="p-2 rounded-full relative  hover:cursor-pointer hover:bg-slate-900/25 " onClick={props.onClick}>
      <SettingsMenu settings={props.settings} center={true} align={MenuAlign.RIGHT}>
        <Tippy theme="light" delay={200} content={<p className="text-center">Options</p>}>
          <Cog6ToothIcon className=" h-5 w-5 z-10" />
        </Tippy>
      </SettingsMenu>
    </div>
  );
}

export const TaxonomyFolder = ({
  isSearchResult,
  taxonomyGroup,
  trending,
  children,
  teamId,
  setActiveId,
  itemRef,
  copyLink,
  togglePinGroup,
  setCurrentGroupId,
  updateProgress,
  filterInput,
  replaceOrAddToSearchGroups,
  zIndex,
  depth,
}: TaxonomyFolderProps) => {
  const handlePinGroup = (e: SyntheticEvent) => {
    e.stopPropagation();
    setPinLoading(true);
    togglePinGroup(taxonomyGroup.id, () => setPinLoading(false));
  };

  const { curOrgId: orgId } = useValidTeamAppContext();
  const navigate = useNavigate();
  const [exportProgress, updateExportProgress] = useState<ProgressState>({ status: Status.idle, percent: 0 });

  const settings: ISettingsItem[] = [
    {
      name: 'Pin Group',
      id: 1,
      group: 'pin',
      htmlId: 'pin-group',
      icon: <BookmarkIcon className="h-6 w-6" />,
      onClick: () => {
        setPinLoading(true);
        togglePinGroup(taxonomyGroup.id, () => setPinLoading(false));
      },
    },
    {
      name: 'Export to CSV',
      id: 2,
      group: 'actions',
      htmlId: 'export-group-csv',
      icon: <ArrowDownTrayIcon className="h-6 w-6" />,
      onClick: () => {
        if (taxonomyGroup?.title) {
          exportGroupWithProgressUpdate(
            { groupTitle: taxonomyGroup.title, teamId, groupIds: [taxonomyGroup.id], filterInput: filterInput ?? {} },
            exportProgress,
            updateExportProgress
          );
        } else toast.error('Could not export data: Group is invalid or not defined.');
      },
    },
    {
      name: 'Link to Group',
      id: 3,
      group: 'actions',
      htmlId: 'link-group',
      icon: <PaperClipIcon className="h-6 w-6" />,
      onClick: () => {
        copyLink(taxonomyGroup.id);
        //copyGroupLink(taxonomyGroup.id, filterInput!, teamId, orgId, 'group');
        writeToastMessage('Copied group link to clipboard');
      },
    },
    {
      name: 'Edit Taxonomy',
      id: 4,
      group: 'edit',
      htmlId: 'edit-taxonomy',
      icon: <PencilSquareIcon className="h-6 w-6" />,
      onClick: () => {
        sessionStorage.setItem('savedScrollPosition', window.scrollY.toString());
        navigate(getGroupPageUrl(teamId, orgId, taxonomyGroup.id, AppRoutes.v3FullPath.explore, ['taxonomy=true']));
      },
    },
    {
      name: 'Delete Group',
      id: 5,
      group: 'delete',
      htmlId: 'delete-group',
      icon: <TrashIcon className="h-6 w-6" />,
      onClick: () => {
        setCurrentGroupId(taxonomyGroup.id);
      },
    },
  ];
  const [pinLoading, setPinLoading] = useState(false);
  useSearchGroupPolling({
    teamId,
    searchGroupId: taxonomyGroup.id ?? -1,
    replaceOrAddToSearchGroups,
    searchProcessing: taxonomyGroup.processing ?? false,
    updateProgress,
    filterInput,
  });
  const filteredStatsMode = getFiltersStatsMode(filterInput);
  return (
    <>
      <div
        className={`flex flex-row items-center gap-x-3 ${taxonomyGroup.processing ? '' : 'cursor-pointer'} relative group ${zIndex} w-full mt-1`}
        ref={itemRef}
      >
        {!isSearchResult && <ToggleExpandGroup taxonomyGroup={taxonomyGroup} depth={depth} />}
        <div
          id="group-preview-card"
          onClick={() => {
            if (taxonomyGroup.processing) return;

            sessionStorage.setItem('savedScrollPosition', window.scrollY.toString());
            navigate(getGroupPageUrl(teamId, orgId, taxonomyGroup.id, AppRoutes.v3FullPath.explore));
          }}
          className={`w-full bg-white text-blueberry ${
            !taxonomyGroup.processing ? 'hover:bg-blueberry hover:text-white hover:shadow-lg' : ''
          } duration-150 transition-colors shadow-[0_1px_2px_rgb(0,0,0,0.1)] border-2 rounded-lg p-3 flex flex-row justify-between items-center gap-x-1`}
        >
          <div className="flex flex-col gap-x-2">
            {isSearchResult && (
              <span className="flex flex-row items-center gap-x-2 w-full mb-1">
                <GroupBreadcrumbs group={taxonomyGroup} />
              </span>
            )}

            <div className="justify-start flex flex-row items-center gap-x-3 ">
              <div className="flex flex-col justify-start">
                <div className="flex flex-row gap-x-2 justify-start items-center">
                  <h3 className="text-lg font-semibold flex justify-start items-center gap-x-2">{truncate(taxonomyGroup.title ?? '', 75)}</h3>
                  <TrendArrow trending={trending} />
                  <NewBadge isNew={taxonomyGroup.isNew} />
                </div>
                {!taxonomyGroup.processing && (
                  <GroupMentionData
                    totalEntries={taxonomyGroup.totalEntries}
                    filteredStatsMode={filteredStatsMode}
                    relativeShare={taxonomyGroup.relativeShare}
                    relativeShareFull={taxonomyGroup.relativeShareFull}
                  />
                )}
              </div>
            </div>
          </div>
          {taxonomyGroup.processing ? (
            <GroupBuildProgress progress={taxonomyGroup.progress} />
          ) : (
            <div className="flex flex-col">
              <div className="flex flex-row justify-end gap-x-1">
                <PinGroupLink pinnedByUser={taxonomyGroup.pinnedByUser} pinLoading={pinLoading} onClick={(e) => handlePinGroup(e)} />
                <CopyGroupLink
                  onClick={(e) => {
                    e.stopPropagation();
                    copyLink(taxonomyGroup.id);
                    writeToastMessage('Copied group link to clipboard');
                  }}
                />
                <GroupSettingsMenu
                  onClick={(e) => {
                    e.stopPropagation();
                    setActiveId(taxonomyGroup.id);
                  }}
                  settings={settings}
                />
              </div>
              <ProgressBar exportProgress={exportProgress} />
            </div>
          )}
        </div>
      </div>

      <div className={`ml-24 mt-2  ${taxonomyGroup.showChildren ? 'opacity-100 h-auto overflow-visible' : 'opacity-0 h-0 overflow-hidden'}`}>{children}</div>
    </>
  );
};
