import { ClockIcon, EyeIcon, MinusCircleIcon, UserCircleIcon } from '@heroicons/react/24/outline';
import { ExternalTicketFragment } from '../../../generated/graphql';
import { capitalizeFirstLetter, localDateString, truncateAndEllipsis } from '../../../v2/util';
import Tippy from '@tippyjs/react';

//Display of a ticket that's assigned to a group inside the widget popover.
export const TicketRow = ({ ticket, unlinkTicketFromGroup }: { ticket: ExternalTicketFragment; unlinkTicketFromGroup: (groupId: number) => void }) => {
  return (
    <div className="flex flex-row">
      <a
        className="flex flex-row items-center gap-x-2 hover:bg-silver px-2 py-1 rounded-md duration-100 w-full"
        href={ticket.sourcePermalink ?? '#'}
        target="_blank"
      >
        <div className="flex flex-col gap-y-1">
          <div className="flex flex-row gap-x-2 items-center">
            <img src={ticket.integration.type.logoUrl} alt={ticket.integration.type.title} className="w-4 h-4" />
            <h1 className="font-semibold">{truncateAndEllipsis(ticket.name, 45)}</h1>
          </div>
          <div className="flex flex-row gap-x-3">
            <div className="flex flex-row items-center gap-x-0.5">
              <EyeIcon className="h-4 w-4" />
              <p className="text-xs">{capitalizeFirstLetter(ticket.status)}</p>
            </div>

            <div className="flex flex-row items-center gap-x-0.5">
              <UserCircleIcon className="h-4 w-4" />
              <p className="text-xs">{ticket.assignee}</p>
            </div>
            {ticket.dueDate ? (
              <div className="flex flex-row items-center gap-x-0.5 ">
                <ClockIcon className="h-4 w-4" />
                <p className="text-xs">{localDateString(new Date(ticket.dueDate))}</p>
              </div>
            ) : null}
          </div>
        </div>
      </a>
      <Tippy content="Unlink ticket">
        <div
          data-testid="unlink-external-ticket-button"
          className="flex px-2 py-2 rounded-md hover:bg-red-100 hover:text-red-700 duration-100 cursor-pointer items-center"
          onClick={() => unlinkTicketFromGroup(ticket.id)}
        >
          <MinusCircleIcon className="h-4 w-4" />
        </div>
      </Tippy>
    </div>
  );
};
