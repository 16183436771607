import { GroupFull, TaxonomyGroup } from '../../../v2/hooks/GroupHook';
import { FilterHook } from '../../hooks/FilterHook';
import { ExploreGroupHook } from '../../hooks/ExploreGroupHook';
import { HomeGroupHook } from '../../hooks/HomeGroupHook';
import { ProgressState } from '../../../exports/ProgressMonitor';
import { CopyGroupToClipBoardButton } from './buttons/CopyGroupToClipBoardButton';
import { PinGroupButton } from './buttons/PinGroupButton';
import { ReplyToUsersButton } from './buttons/ReplyToUsersButton';
import { ExportToCSVButton } from './buttons/ExportToCSVButton';
import { ConfigureGroupSubscriptionsButton } from './buttons/ConfigureGroupSubscriptionsButton';
import { EditTaxonomyButton } from './buttons/EditTaxonomyButton';
import { EditDigestButton } from './buttons/EditDigestButton';
import { DeleteGroupButton } from './buttons/DeleteGroupButton';
import { ProgressBar } from '../ProgressBar';
import { ExternalTicketsButton } from './buttons/ExternalTicketsButton';

export function GroupToolBar(props: {
  group?: GroupFull;
  filterHook: FilterHook;
  groupHook: ExploreGroupHook | HomeGroupHook;
  exportProgress: ProgressState;
  updateExportProgress: (value: ((prevState: ProgressState) => ProgressState) | ProgressState) => void;
  taxonomy: Map<number, TaxonomyGroup>;
  showDigestButton: boolean;
  toggleDigestModal: () => void;
  toggleDeleteGroupModal: () => void;
}) {
  return (
    <div className={'flex flex-col gap-y-0 w-full '}>
      {props.group ? (
        <div>
          <div className="flex flex-row gap-x-3 mt-1 mx-8 justify-end">
            <ExternalTicketsButton group={props.group} />
            <CopyGroupToClipBoardButton group={props.group} filters={props.filterHook.filters} />
            <PinGroupButton group={props.group} groupHook={props.groupHook} />
            <ReplyToUsersButton />
            <ExportToCSVButton
              group={props.group}
              filters={props.filterHook.filters}
              exportProgress={props.exportProgress}
              updateExportProgress={props.updateExportProgress}
            />
            <ConfigureGroupSubscriptionsButton group={props.group} />
            <EditTaxonomyButton filters={props.filterHook.filters} groupHook={props.groupHook} group={props.group} taxonomy={props.taxonomy} />
            {props.showDigestButton && <EditDigestButton onClick={props.toggleDigestModal} />}
            <DeleteGroupButton onClick={props.toggleDeleteGroupModal} />
          </div>
          <div className={'flex flex-row w-full justify-end'}>
            <div className={'flex flex-col w-1/2 mx-10 mt-2'}>
              <ProgressBar exportProgress={props.exportProgress} />
            </div>
          </div>
        </div>
      ) : (
        <ToolbarLoadingSkeleton />
      )}
    </div>
  );
}

const ToolbarLoadingSkeleton = () => {
  return (
    <div className="flex flex-row gap-x-3 mt-1 mx-8 justify-end">
      <div
        className="custom-chart-card-skeleton space-y-5 rounded-lg bg-gray-100 relative 
        before:absolute before:inset-0
        before:-translate-x-full
        before:animate-[shimmer_2s_infinite]
        before:bg-gradient-to-r before:from-transparent before:via-blueberry  before:opacity-[0.2]
        isolate
        overflow-hidden
        before:border-t before:border-gray-100 opacity-70 h-12 w-full"
      ></div>
    </div>
  );
};
