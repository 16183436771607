import {
  InsightLightFragment,
  Insight_Collection_Type,
  useGetInsightPreviewChartToDeleteLazyQuery,
  useGetInsightPreviewChartsLazyQuery,
} from '../../../../generated/graphql';
import InsightChart from './InsightChart';
import { getFiltersFromInsight } from '../insights/EditInsightModal';
import { useEffect } from 'react';

interface InsightChartSectionProps {
  insight: InsightLightFragment;
}

/**
 * This wraps the <InsightChart /> object with the appropriate pre-fetches to populate the chart from a given insight Id
 */
const InsightChartSection = ({ insight }: InsightChartSectionProps) => {
  // this is a little confusing here the filterInput isn't really used for anything other than display here. The actual filtering on the chart comes from the insight
  const filterInput = getFiltersFromInsight(insight);

  const [groupChartQuery, series] = useGetInsightPreviewChartToDeleteLazyQuery({
    variables: {
      teamId: insight.team.id,
      collectionId: insight.collection.id,
      collectionType: insight.collection.type,
      filterInput: filterInput,
      chartBin: insight.chartBin,
    },
  });
  const [customChartQuery, chart] = useGetInsightPreviewChartsLazyQuery({
    variables: {
      teamId: insight.team.id,
      collectionId: insight.collection.id,
      collectionType: insight.collection.type,
      filterInput: filterInput,
      chartBin: insight.chartBin,
    },
  });
  useEffect(() => {
    if (insight.collection.type === Insight_Collection_Type.Chart) {
      customChartQuery();
    } else {
      groupChartQuery();
    }
  }, []);

  return (
    <InsightChart
      chart={chart.data?.getInsightPreviewCharts}
      series={series.data?.getInsightPreviewChartToDelete}
      filterInput={filterInput}
      loading={series.loading || chart.loading}
    />
  );
};

export default InsightChartSection;
