import { IDropDownItem } from '../../baseComponents/DropDown';
import { EMPTY_DROPDOWN_ITEM, EMPTY_TITLE, NOT_FOUND } from '../utilities/consts';
import { OrganizationWithTeamInfo } from '../../v2/contexts/AppContext';

export interface CreateDigestSeriesState {
  organizations: OrganizationWithTeamInfo[];
  loading: boolean;
  title: string;
  selectedOrganization: IDropDownItem;
  teamsOptions: IDropDownItem[];
  selectedTeams: IDropDownItem[];
  selectedTeam: IDropDownItem;
}

export enum CreateDigestSeriesActions {
  AddTeam,
  RemoveTeam,
  SetTitle,
  SelectOrganization,
  ClearState,
}

type SelectOrganizationPayload = {
  selectedOrganization: IDropDownItem | undefined;
};

type AddRemoveTeamPayload = {
  team: IDropDownItem;
};

type SetTitlePayload = {
  title: string;
};

type ClearStatePayload = {};

export type CreateDigestSeriesActionPayloads = SelectOrganizationPayload | AddRemoveTeamPayload | SetTitlePayload | ClearStatePayload;

export const getInitialState = (organizations: OrganizationWithTeamInfo[]): CreateDigestSeriesState => {
  return {
    organizations,
    loading: false,
    title: EMPTY_TITLE,
    teamsOptions: [],
    selectedTeams: [],
    selectedOrganization: EMPTY_DROPDOWN_ITEM,
    selectedTeam: EMPTY_DROPDOWN_ITEM,
  };
};

/**
 * Does payload need to be only one type?
 *
 * could payload be typed based on action?
 * @param state
 * @param action
 * @returns
 */
export const CreateDigestSeriesReducer = (
  state: CreateDigestSeriesState,
  action: { type: CreateDigestSeriesActions; payload: CreateDigestSeriesActionPayloads }
): CreateDigestSeriesState => {
  switch (action.type) {
    case CreateDigestSeriesActions.SelectOrganization:
      // this is where I want to enforce runtime typechecking... action.payload is SelectOrganizationPayload
      return selectOrgnaization(state, action.payload as SelectOrganizationPayload);
    case CreateDigestSeriesActions.AddTeam:
      return addTeam(state, action.payload as AddRemoveTeamPayload);
    case CreateDigestSeriesActions.RemoveTeam:
      return removeTeam(state, action.payload as AddRemoveTeamPayload);
    case CreateDigestSeriesActions.SetTitle:
      return setTitle(state, action.payload as SetTitlePayload);
    case CreateDigestSeriesActions.ClearState:
      return clearState(state);
  }
  throw new Error(`Cannot perform action requested: ${action.type}`);
};

const clearState = (previousState: CreateDigestSeriesState): CreateDigestSeriesState => {
  return getInitialState(previousState.organizations);
};

const setTitle = (previousState: CreateDigestSeriesState, newState: SetTitlePayload): CreateDigestSeriesState => {
  return {
    ...previousState,
    title: newState.title,
  };
};

const removeTeam = (previousState: CreateDigestSeriesState, newState: AddRemoveTeamPayload): CreateDigestSeriesState => {
  const newSelectedTeams = [...previousState.selectedTeams];
  const indexToRemove = findIndexOrThrow(
    newSelectedTeams,
    (t) => t.id === newState.team.id,
    `Cannot remove team ${newState.team.name} from selected team, team does not exist`
  );
  newSelectedTeams.splice(indexToRemove, 1);

  return {
    ...previousState,
    selectedTeams: newSelectedTeams,
    teamsOptions: [...previousState.teamsOptions, newState.team],
  };
};

const addTeam = (previousState: CreateDigestSeriesState, newState: AddRemoveTeamPayload): CreateDigestSeriesState => {
  // remove added teams from options to add
  const indexToRemove = findIndexOrThrow(previousState.teamsOptions, (t) => t.id === newState.team.id, 'Cannot find index specified');
  const newOptions = [...previousState.teamsOptions];
  newOptions.splice(indexToRemove, 1);

  return {
    ...previousState,
    selectedTeams: [...previousState.selectedTeams, newState.team],
    selectedTeam: newState.team,
    teamsOptions: newOptions,
  };
};

const selectOrgnaization = (previousState: CreateDigestSeriesState, newState: SelectOrganizationPayload): CreateDigestSeriesState => {
  // Initialize new state to the cleared organization state
  const stateToReturn: CreateDigestSeriesState = {
    ...previousState,
    selectedOrganization: EMPTY_DROPDOWN_ITEM,
    selectedTeams: [],
    selectedTeam: EMPTY_DROPDOWN_ITEM,
    teamsOptions: [],
  };

  if (newState.selectedOrganization) {
    stateToReturn.selectedOrganization = newState.selectedOrganization;
    stateToReturn.teamsOptions = getTeamsBelongingToOrganization(previousState.organizations, newState.selectedOrganization);
  }

  return stateToReturn;
};

const getTeamsBelongingToOrganization = (organizations: OrganizationWithTeamInfo[], selectedOrganization: IDropDownItem): IDropDownItem[] => {
  const orgIndex = findIndexOrThrow(
    organizations,
    (org) => org.id === selectedOrganization.id,
    `Selected organization does not exist ${selectedOrganization.name}`
  );
  const org = organizations[orgIndex];
  return org.teams.map((team): IDropDownItem => {
    return { name: `(${team.id}) ${team.name}`, id: team.id };
  });
};

const findIndexOrThrow = <T>(array: T[], predicate: (item: T) => boolean, errorMessage: string): number => {
  const index = array.findIndex(predicate);
  if (index === NOT_FOUND) {
    throw new Error(errorMessage);
  }
  return index;
};

export const canSaveDigestSeries = (state: CreateDigestSeriesState): boolean => {
  if (state.title !== EMPTY_TITLE && state.selectedOrganization !== EMPTY_DROPDOWN_ITEM && state.selectedTeams.length !== 0) {
    return true;
  }
  return false;
};
