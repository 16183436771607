import { useEffect, useReducer } from 'react';
import MutateInsightModal from './MutateInsightModal';
import { EMPTY_DROPDOWN_ITEM, EMPTY_TITLE, NOT_FOUND } from '../../../../reducers/utilities/consts';
import moment from 'moment';
import { getDropDownItemFromEnumSelection, getEnumValue } from '../../../../reducers/utilities/enumHandling';
import {
  Chart_Bin_Type,
  Digest_Group_Status,
  FilterInput,
  GetInsightsDocument,
  InsightCollection,
  InsightLightFragment,
  Insight_Collection_Type,
  Insight_Status,
  useCreateInsightMutation,
  useGetDigestsForActiveTeamQuery,
  useGetInsightsLazyQuery,
  useUpdateInsightMutation,
} from '../../../../generated/graphql';
import { useFilterHook } from '../../../hooks/FilterHook';
import { VirtualizedComboBox } from '../../VirtualizedComboBox';
import { RowLabel, RowWrapper } from '../../../baseComponents/RowWrapper';
import toast from 'react-hot-toast';
import { InsightAction, InsightPayload, InsightReducer, InsightState } from '../../../../reducers/insights/insightReducer';

interface CreateInsightModalProps {
  teamId: number;
  orgId: number;
  isModalOpen: boolean;
  filterInput: FilterInput;
  setOpen: (state: boolean) => void;
  collection: InsightCollection;
}

const CreateInsightFromCollectionModal = (props: CreateInsightModalProps): JSX.Element => {
  const { filterInput, teamId, orgId } = props;
  const [createInsightMutation, _] = useCreateInsightMutation();
  const [updateInsight, _2] = useUpdateInsightMutation({});

  const [miState, dispatch] = useReducer(
    InsightReducer,
    { teamId: props.teamId, orgId: props.orgId, collection: props.collection },
    getInitialMutateInsightState
  );

  const filterHook = useFilterHook({
    teamId,
    orgId,
    startingFilterInput: filterInput,
  });

  /**
   * This is unfortunately going to have to choose if the insight exists or not...
   */
  const createInsight = async () => {
    dispatch({ type: InsightAction.SetLoading, payload: { loading: true } });
    const entries = miState.previewEntries.filter((entry) => entry.isSelected).map((entry) => entry.entry.id);
    createInsightMutation({
      variables: {
        collectionId: miState.collection.id,
        collectionType: miState.collection.type,
        digestId: miState.chosenDigest.id,
        chartBin: getEnumValue(Chart_Bin_Type, miState.chartBin),
        expirationDate: miState.expiration,
        entryIds: entries,
        feedbackStatus: getEnumValue(Digest_Group_Status, miState.feedbackStatus),
        filterInput: filterHook.filters,
        text: miState.text,
        title: miState.title,
        excludeFromDigest: miState.excludeFromDigest,
      },
      onCompleted: (_) => {
        toast.success(`Created Insight`);
        props.setOpen(false);
        dispatch({ type: InsightAction.SetLoading, payload: { loading: false } });
      },
      onError: (error) => {
        toast.error(`Error Saving Insight:\n ${error.message}`);
        console.error(error);
      },
      refetchQueries: [{ query: GetInsightsDocument, variables: { digestId: miState.chosenDigest.id } }],
    });
  };

  const saveInsight = () => {
    dispatch({ type: InsightAction.SetLoading, payload: { loading: true } });
    updateInsight({
      variables: {
        entryIds: miState.previewEntries.filter((e) => e.isSelected).map((e) => e.entry.id),
        expirationDate: miState.expiration,
        feedbackStatus: getEnumValue(Digest_Group_Status, miState.feedbackStatus),
        filterInput: filterHook.filters,
        insightId: miState.id,
        insightStatus: getEnumValue(Insight_Status, miState.insightStatus),
        text: miState.text,
        title: miState.title,
        chartBin: getEnumValue(Chart_Bin_Type, miState.chartBin),
        order: miState.order,
        excludeFromDigest: miState.excludeFromDigest,
      },
      refetchQueries: [{ query: GetInsightsDocument, variables: { digestId: miState.chosenDigest.id } }],
      awaitRefetchQueries: true,
      onCompleted: () => {
        props.setOpen(false);
        dispatch({ type: InsightAction.SetLoading, payload: { loading: false } });
      },
    });
  };

  /** We only need to call this when we're creating an insight from a Group or Chart  */
  useGetDigestsForActiveTeamQuery({
    variables: { teamId: teamId },
    onCompleted: (data) => {
      dispatch({ type: InsightAction.SetDigestOptions, payload: data.getActiveDigestsForTeam });
    },
  });

  return (
    <MutateInsightModal
      {...props}
      filterHook={filterHook}
      miState={miState}
      dispatch={dispatch}
      saveInsightCloseModal={miState.editingMode ? saveInsight : createInsight}
      digestDisplay={<DigestChooser miState={miState} dispatch={dispatch} />}
    />
  );
};

const DigestChooser = (props: {
  miState: InsightState;
  dispatch: React.Dispatch<{
    type: InsightAction;
    payload: InsightPayload;
  }>;
}): JSX.Element => {
  const { miState, dispatch } = props;
  const [getInsights, _] = useGetInsightsLazyQuery({});
  return (
    <RowWrapper>
      <RowLabel label="Digest" />
      <div className="w-full">
        <VirtualizedComboBox
          comboBoxData={miState.digestOptions}
          disableAlphabeticalSort={true}
          setSelectedItem={(item) => {
            if (!item) dispatch({ type: InsightAction.SetSelectedDigest, payload: { selection: EMPTY_DROPDOWN_ITEM } });
            else {
              //This dispatch is for display reasons, the data is still loading.
              dispatch({ type: InsightAction.SetSelectedDigest, payload: { selection: item ?? EMPTY_DROPDOWN_ITEM } });
              dispatch({ type: InsightAction.SetCheckLoading, payload: { loading: true } });
              getInsights({
                variables: {
                  digestId: item.id,
                  includeDenied: true,
                },
                onCompleted(data) {
                  const resInsights = data.getInsights;
                  const existingInsight = resInsights.find((insight) => insight.collection.id === miState.collection.id);
                  dispatch({ type: InsightAction.SetSelectedDigest, payload: { selection: item, insight: existingInsight } });
                },
              });
            }
          }}
          selectedItem={miState.chosenDigest}
          placeholder="Select a digest"
        />
      </div>
    </RowWrapper>
  );
};

export const getInitialMutateInsightState = (props: { teamId: number; orgId: number; collection: InsightCollection }): InsightState => {
  return {
    digestOptions: [],
    chosenDigest: EMPTY_DROPDOWN_ITEM,
    previewEntries: [],
    canSelectMoreEntries: true,
    feedbackStatus: EMPTY_DROPDOWN_ITEM,
    teamId: props.teamId,
    orgId: props.orgId,
    collection: props.collection,
    title: EMPTY_TITLE,
    text: EMPTY_TITLE,
    createSaveLoading: false,
    checkingExistingInsightLoading: false,
    expiration: moment.utc().add(14, 'days').toDate(),
    insightStatus: getDropDownItemFromEnumSelection(Insight_Status, Insight_Status.Approved),
    chartBin: getDropDownItemFromEnumSelection(Chart_Bin_Type, Chart_Bin_Type.Dynamic),
    editingMode: false,
    id: NOT_FOUND,
    order: -1,
    excludeFromDigest: false,
  };
};

export const getInitialMutateInsightStateForEditing = (props: { insight: InsightLightFragment; digestBasic: { id: number; name: string } }): InsightState => {
  const { insight, digestBasic } = props;
  return {
    digestOptions: [],
    chosenDigest: { name: digestBasic.name, id: digestBasic.id },
    collection: insight.collection,
    previewEntries: insight.entries.map((entry) => {
      return { isSelected: true, entry };
    }),
    canSelectMoreEntries: insight.entries.length < 3,
    expiration: new Date(insight.expirationDate),
    feedbackStatus: getDropDownItemFromEnumSelection(Digest_Group_Status, insight.feedbackStatus),
    insightStatus: getDropDownItemFromEnumSelection(Insight_Status, insight.insightStatus),
    chartBin: getDropDownItemFromEnumSelection(Chart_Bin_Type, insight.chartBin),
    title: insight.title,
    text: insight.text,
    teamId: insight.team.id,
    orgId: insight.team.orgId!,
    createSaveLoading: false,
    checkingExistingInsightLoading: false,
    editingMode: true,
    id: insight.id,
    order: insight.order,
    excludeFromDigest: insight.excludeFromDigest,
  };
};
export default CreateInsightFromCollectionModal;
