import { useEffect, useState } from 'react';
import {
  FilterInput,
  useDeleteChartMutation,
  Chart_Bin_Type,
  TeamChartsQuery,
  useTeamChartsLazyQuery,
} from '../../generated/graphql';

interface ChartsPageHookProps {
  teamId: number;
  orgId: number;
  userId?: number;
  chartId?: number;
  filterInput?: FilterInput;
  dateFilterInput?: FilterInput;
  mode?: 'editor' | 'creator';
  binType?: Chart_Bin_Type;
}

export const useChartsPageHook = ({ teamId, filterInput, binType }: ChartsPageHookProps) => {
  const [deleteChart, deletedChart] = useDeleteChartMutation();

  const [teamChartsList, setTeamChartsList] = useState<TeamChartsQuery['teamCharts']['charts']>([]);

  const [getTeamChartsQuery, teamChartsQuery] = useTeamChartsLazyQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    variables: { teamId, filterInput },
  });

  const deleteCustomChart = async (chartId: number) => {
    if (!chartId) return;
    await deleteChart({
      variables: {
        teamId,
        chartId,
      },
      onCompleted(data) {
        setTeamChartsList(teamChartsList.filter((chart) => chart.chartId !== chartId));
      },
    });
  };

  useEffect(() => {
    setTeamChartsList([]);
    getTeamChartsQuery({
      variables: { teamId, filterInput },
      onCompleted(data) {
        setTeamChartsList(data.teamCharts.charts);
      },
    });
  }, [teamId, filterInput, binType]);

  return {
    totalAmountOfCharts: teamChartsQuery.data?.teamCharts?.amountOfCharts,
    teamCharts: teamChartsList,
    deleteCustomChart,
    loadingStatues: {
      loadingTeamCharts: teamChartsQuery.loading,
      deletingChart: deletedChart.loading,
    },
  };
};
