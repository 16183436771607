import toast from 'react-hot-toast';
import { ExternalTicketFragment } from '../../../generated/graphql';
import { ExternalTicketsAction, ExternalTicketsActionTypes } from '../../../reducers/externalTickets/externalTicketsReducer';
import { TicketRow } from './TicketRow';
import { useValidTeamAppContext } from '../../../v2/contexts/AppContext';
import { useUnlinkExternalTicketHook } from '../../hooks/UnlinkLinkExternalTicketHook';

export const ExternalTicketsListBody = ({
  tickets,
  dispatch,
  groupId,
}: {
  tickets: ExternalTicketFragment[];
  dispatch: React.Dispatch<ExternalTicketsAction>;
  groupId: number;
}) => {
  const { curOrgId: orgId } = useValidTeamAppContext();

  const unlinkHook = useUnlinkExternalTicketHook({ orgId });

  const handleUnlink = async (ticket: ExternalTicketFragment) => {
    const toastId = toast('Unlinking ticket...', { duration: 30000 });
    try {
      await unlinkHook.unlinkTicketFromGroup({ groupId, ticketId: ticket.id, scraperKey: ticket.integration.type.scraperKey });
      dispatch({ type: ExternalTicketsActionTypes.REMOVE_EXTERNAL_TICKET, payload: { ticketId: ticket.id } });
      toast.success('Ticket unlinked');
    } catch (err) {
      toast.error('Error unlinking ticket');
    }
    toast.dismiss(toastId);
  };

  return (
    <div className="flex flex-col gap-y-2">
      {tickets.map((ticket) => (
        <TicketRow ticket={ticket} unlinkTicketFromGroup={() => handleUnlink(ticket)} />
      ))}
      <div
        data-testid="link-new-action-item-button"
        className="flex flex-row items-center bg-silver px-2 py-1 rounded-md duration-100 w-full justify-center select-none cursor-pointer hover:bg-silver-darker"
        onClick={() => dispatch({ type: ExternalTicketsActionTypes.SET_VIEW, payload: { newView: 'link' } })}
      >
        <h1>Link new action item +</h1>
      </div>
    </div>
  );
};
