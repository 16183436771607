import { SparklesIcon } from '@heroicons/react/24/outline';
import { Fragment, useEffect, useState } from 'react';
import { ComboBox } from '../../baseComponents/ComboBox';
import {
  FilterInput,
  Group_Status,
  useGetGroupsLightQuery,
  useGetGroupsLightTaxonomyQuery,
  useGetTaxonomyGroupLazyQuery,
  useTaxonomyQuery,
} from '../../generated/graphql';
import { useValidTeamAppContext } from '../../v2/contexts/AppContext';
import { TaxonomyGroup } from '../../v2/hooks/GroupHook';
import Button from '../baseComponents/Button';
import { IDropDownItem } from '../baseComponents/DropDown';
import SearchInput from '../baseComponents/SearchInput';
import GroupTaxonomyEntry from './GroupTaxonomyEntry';
import { TaxonomyChildToAdd } from './TaxonomyChildToAdd';
import { VirtualizedComboBox } from './VirtualizedComboBox';
import { classNames } from '../../v2/util';

export default function GroupTaxonomyEdit({
  groupId,
  filterInput,
  parentId,
  assignChild,
  assignChildren,
  deleteChild,
  getChildCandidates,
  childCandidates,
  children,
  getChildren,
  loadingStatuses,
  removeChildFromParent,
  getChildrenToAssign,
  childrenToAssign,
}: {
  taxonomy: Map<number, TaxonomyGroup>;
  groupId: number | undefined;
  parentId: number | null | undefined;
  filterInput: FilterInput | undefined;
  assignChildren: (teamId: number, filterInput: FilterInput, parentGroupId: number, childGroupIds: number[], cb?: () => void) => Promise<void>;
  assignChild: (
    teamId: number,
    filterInput: FilterInput,
    parentGroupId: number,
    childGroupId: number,
    type?: 'Parent' | 'Child',
    cb?: () => void,
    errorCb?: () => void
  ) => Promise<void>;
  deleteChild: (teamId: number, filterInput: FilterInput, parentGroupId: number, childGroupId: number, cb?: () => void) => Promise<void>;
  getChildCandidates: (teamId: number, filterInput: FilterInput, groupId: number, query: string, cb: () => void) => Promise<void>;
  childCandidates: any[];
  getChildren: (teamId: number, filterInput: FilterInput, groupId: number, cb: () => void) => Promise<void>;
  children: any[];
  loadingStatuses: any;
  removeChildFromParent: (teamId: number, filterInput: FilterInput, parentGroupId: number, childGroupId: number, cb?: () => void) => Promise<void>;
  getChildrenToAssign: (teamId: number, filterInput: FilterInput, groupId: number, cb?: () => void) => Promise<void>;
  childrenToAssign: any[];
}) {
  const { curTeamId: teamId } = useValidTeamAppContext();
  const [parentLoading, setParentLoading] = useState<boolean>(false);
  const [allGroups, setAllGroups] = useState<IDropDownItem[]>([]);
  const [query, setQuery] = useState<string>();

  const { data: groupsData, loading: groupsLoading } = useGetGroupsLightTaxonomyQuery({ variables: { teamId, status: Group_Status.Monitored } });
  useEffect(() => {
    if (groupsData?.getAllGroups) {
      const allGroups: IDropDownItem[] = groupsData.getAllGroups.map((group) => {
        return { id: group.id, name: `${group.title} ${group.totalDescendants ? `(${group.totalDescendants})` : ''}` };
      });
      setAllGroups(allGroups);
    }
  }, [groupsData]);

  const tabs = [
    {
      id: 0,
      name: 'Children',
    },
    { id: 1, name: 'Suggest Children', icon: <SparklesIcon className="h-5 w-5" /> },
    { id: 2, name: 'Add Children' },
  ];
  const [localParentId, setLocalParentId] = useState(parentId);
  const [getTaxonomyGroupQuery] = useGetTaxonomyGroupLazyQuery({ variables: { teamId, groupId: groupId ?? -1, filterInput: filterInput ?? {} } });
  const [checkedIds, setCheckedIds] = useState<number[]>(childrenToAssign.map((c) => c.id));
  const [currentTabId, setCurrentTabId] = useState(0);
  const [assigningChildren, setAssigningChildren] = useState(false);
  const [childrenHaveBeenAdded, setChildrenHaveBeenAdded] = useState(false);
  const fetchTabs = () => {
    if (groupId) {
      getChildren(teamId, filterInput ?? {}, groupId, () => {});
      getChildCandidates(teamId, filterInput ?? {}, groupId, '', () => {});
      getChildrenToAssign(teamId, filterInput ?? {}, groupId, () => {});
    }
  };
  useEffect(() => {
    setCheckedIds(childrenToAssign.map((c) => c.id));
  }, [childrenToAssign]);
  useEffect(() => {
    fetchTabs();
  }, [groupId, filterInput]);

  useEffect(() => {
    if (!parentId) {
      if (groupId) {
        getTaxonomyGroupQuery({
          variables: { teamId, groupId: groupId, filterInput: filterInput ?? {} },
          onCompleted(data) {
            if (data.getGroup) {
              setLocalParentId(data?.getGroup?.parent?.id);
            }
          },
        });
      } else {
        setLocalParentId(null);
      }
    } else if (parentId) {
      setLocalParentId(parentId);
    }
  }, [parentId, groupId]);
  return (
    <div className="flex flex-col w-96 bg-silver rounded-2xl h-96 overflow-y-auto shadow-lg ">
      <div>
        <div className="bg-blueberry text-white pb-3 rounded-t-2xl ">
          <div className="flex flex-col justify-center items-center text-center pt-5 ">
            <h1 className="text-2xl">Edit Taxonomy</h1>
          </div>
          <div className="flex flex-col items-center ">
            <div className="flex flex-row mt-2 gap-x-1 items-center p-1">
              <h2>Parent:</h2>
              <VirtualizedComboBox
                loading={parentLoading || !groupId || groupsLoading}
                comboBoxData={allGroups}
                setSelectedItem={function (selectedItem: IDropDownItem | undefined): void {
                  if (!groupId) return;
                  if (selectedItem) {
                    if (selectedItem && selectedItem.id === localParentId) return;
                    setParentLoading(true);
                    assignChild(teamId, filterInput ?? {}, selectedItem?.id, groupId, 'Parent', () => {
                      setParentLoading(false);
                    });
                  } else {
                    setParentLoading(true);
                    removeChildFromParent(teamId, filterInput ?? {}, localParentId ?? -1, groupId, () => setParentLoading(false));
                  }
                }}
                selectedItem={allGroups.find((item: IDropDownItem) => item.id === localParentId)}
              />
            </div>
          </div>
        </div>
        <div className="group relative flex flex-col gap-x-2 pt-2 pb-1 px-3 mb-2  text-blueberry w-full">
          <div className="tabs flex min-h-0 h-full flex-1 flex-col">
            <div className="flex flex-row gap-x-4 min-h-0 items-center">
              {tabs.map((tab) => {
                return (
                  <div
                    key={tab.id}
                    className={`flex flex-row gap-x-1.5 cursor-pointer ${
                      currentTabId === tab.id ? 'font-semibold text-blueberry underline decoration-2 underline-offset-4' : 'text-gray-500'
                    }`}
                  >
                    {tab.icon ? tab.icon : null}
                    <p key={tab.id} onClick={() => setCurrentTabId(tab.id)}>
                      {tab.name}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
          <div className={classNames(loadingStatuses.fetchingChildren ? 'overflow-y-hidden' : 'overflow-y-auto', 'min-h-0 pr-2')}>
            {currentTabId === 0 && (
              <div className="pt-2">
                {loadingStatuses.fetchingChildren || !groupId ? (
                  <div className="flex justify-center items-center mt-2 min-h-[1px]">
                    {<div className="h-8 w-8 animate-spin rounded-full border-t-2 border-b-2 border-blueberry"></div>}
                  </div>
                ) : children.length === 0 ? (
                  <p>No children belong to this group. </p>
                ) : (
                  children.map((child) => {
                    return (
                      <Fragment key={child.id}>
                        <GroupTaxonomyEntry
                          id={child.id}
                          title={child.title}
                          uniqueEntries={child.totalEntries}
                          key={child.id}
                          deleteChild={() => {
                            deleteChild?.(teamId, filterInput ?? {}, groupId, child.id);
                          }}
                          loading={loadingStatuses.assigningChild}
                          type={'child'}
                        />
                      </Fragment>
                    );
                  })
                )}
              </div>
            )}
            {currentTabId === 2 && (
              <div className="pt-2">
                <div className="px-0.5 pb-2">
                  <SearchInput
                    queryString={query}
                    placeholder={'Add more children'}
                    setQueryString={(string) => setQuery(string ?? '')}
                    onSearch={async (q) => {
                      if (!groupId) return;
                      await getChildCandidates(teamId, filterInput ?? {}, groupId, q ?? '', () => {});
                    }}
                  />
                </div>
                {loadingStatuses.fetchingChildCandidates || !groupId ? (
                  <div className="flex justify-center items-center mt-2 min-h-[1px]">
                    <div className="h-8 w-8 animate-spin rounded-full border-t-2 border-b-2 border-blueberry"></div>
                  </div>
                ) : childCandidates.length === 0 ? (
                  <p>No similar children were found. </p>
                ) : (
                  childCandidates.map((child) => {
                    return (
                      <Fragment key={child.id}>
                        <GroupTaxonomyEntry
                          id={child.id}
                          title={child.title}
                          uniqueEntries={child.totalEntries}
                          key={child.id}
                          addChild={() => {
                            assignChild?.(teamId, filterInput ?? {}, groupId, child.id, 'Child');
                          }}
                          loading={loadingStatuses.assigningChild} // assign child loading
                          type={'child'}
                        />
                      </Fragment>
                    );
                  })
                )}
              </div>
            )}
            {currentTabId === 1 && (
              <div className="pt-2 w-full">
                {loadingStatuses.gettingChildrenToAssign ? (
                  <p>Getting children to assign...</p>
                ) : childrenToAssign.length === 0 ? (
                  <p>Zero children can be added to this group automatically.</p>
                ) : childrenHaveBeenAdded ? (
                  <p>All children have been added to the group successfully</p>
                ) : (
                  <>
                    <p className="mb-4">Pick which children to add to this group:</p>
                    {childrenToAssign.map((child) => {
                      return (
                        <Fragment key={child.id}>
                          <TaxonomyChildToAdd
                            checked={checkedIds.includes(child.id)}
                            title={child.title}
                            numberOfEntries={child.uniqueEntries}
                            addCheck={() => setCheckedIds((prev) => [...prev, child.id])}
                            removeCheck={() => setCheckedIds((prev) => prev.filter((id) => id !== child.id))}
                          />
                        </Fragment>
                      );
                    })}{' '}
                  </>
                )}
                <div className="flex flex-row justify-center items-center">
                  {childrenHaveBeenAdded || childrenToAssign.length === 0 || loadingStatuses.gettingChildrenToAssign ? null : (
                    <Button
                      id="bulk-assign-children"
                      loadingConfirm={assigningChildren}
                      text="Confirm"
                      onClick={() => {
                        setAssigningChildren(true);
                        assignChildren(teamId, filterInput ?? {}, groupId ?? -1, checkedIds, () => {
                          setAssigningChildren(false);
                          setChildrenHaveBeenAdded(true);
                        });
                      }}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
