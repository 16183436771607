import { useContext, useEffect, useState } from 'react';
import LoadingSpinner from '../../v2/components/LoadingSpinner';
import { useValidTeamAppContext } from '../../v2/contexts/AppContext';
import Button, { ButtonSize, ButtonVariant } from '../baseComponents/Button';
import { FeedbackHook, useFeedbackHook } from '../hooks/FeedbackHook';
import { useFilterHook } from '../hooks/FilterHook';
import { FilterManager } from '../sections/Filters/FilterManager';
import { PageWrapper } from './PageWrapper';
import YesCancelModal from '../components/Modals/YesCancelModal';
import { IDropDownItem } from '../baseComponents/DropDown';
import { useReplyHook } from '../hooks/ReplyHook';
import { CraftGeneralReplyRow } from '../components/replying/CraftGeneralReplyRow';
import { FeedbackAndReplyRow } from '../components/replying/FeedbackAndReplyRowRow';
import { GptResponseType } from '../../generated/graphql';
import { Pagination } from '../baseComponents/Pagination';
import UserContext from '../../v2/contexts/UserContext';
import Tippy from '@tippyjs/react';
import TooltipIcon from '../components/Modals/TooltipIcon';

interface ReplyingPageProps {
  pageName: string;
}

export const selector: IDropDownItem[] = [
  {
    id: 1,
    name: 'We fixed it',
    title: 'We fixed it',
    value: "I'm sorry to hear this. We've fixed this issue and it should be working now.",
  },
  {
    id: 2,
    name: "We're working on it",
    value: "I'm sorry to hear this. We're working on fixing this issue and will let you know when it's fixed.",
  },
];

export const ReplyingPage = ({ pageName }: ReplyingPageProps) => {
  const [selectedItem, setSelectedItem] = useState<IDropDownItem | undefined>(undefined);
  const { curTeamId: teamId, curOrgId: orgId, currentUserIsAdmin } = useValidTeamAppContext();
  const { user } = useContext(UserContext);
  const filterHook = useFilterHook({ teamId, orgId });
  const feedbackHook = useFeedbackHook({ teamId, orgId, filterInput: filterHook.filters, repliableOnly: user?.isUnwrapper ? false : true });
  const { entries } = feedbackHook;
  const [replyToAllModalOpen, setReplyToAllModalOpen] = useState(false);
  const { replies, handleCopyReplies, handleGenerateReplies, handleGenerateReply, generatingGeneralReplies, sendAllReplies, sendIndividualReply } =
    useReplyHook({ feedbackHook, filterHook });
  const [replyLoading, setReplyLoading] = useState(false);
  const handleConfirm = () => {
    setReplyLoading(true);
    setReplyToAllModalOpen(false);
    sendAllReplies(() => setReplyLoading(false));
  };

  return (
    <PageWrapper title={'Replying'}>
      {replyToAllModalOpen && (
        <YesCancelModal
          text={'This will send each reply to each customer.'}
          subtext={'Please confirm this action.'}
          confirmText="Yes, send"
          callbackModal={setReplyToAllModalOpen}
          modalOpen={replyToAllModalOpen}
          confirmButton={() => handleConfirm()}
          closeOnConfirm={false}
          understandCheck={false}
        />
      )}
      <div className="flex flex-col items-center justify-center">
        <div className="flex w-full max-w-[105rem] flex-col ">
          <div className="border-b border-gray-200 pb-4">
            <FilterManager filterHook={filterHook} pageName={pageName} dataTypeToFilter={'group'} />
          </div>

          <div className="mt-2 flex flex-col items-center">
            <div className="flex flex-col gap-y-6 my-8 w-full text-blueberry">
              {currentUserIsAdmin ? (
                <>
                  <CraftGeneralReplyRow
                    handleCopyReplies={handleCopyReplies}
                    handleGenerateReplies={handleGenerateReplies}
                    generatingGeneralReplies={generatingGeneralReplies}
                    selectedItem={selectedItem}
                    setSelectedItem={setSelectedItem}
                  />
                  <div className="relative">
                    <div className="absolute inset-0 bg-red-300 justify-center flex items-center" aria-hidden="true">
                      <div className="w-1/2 border-t border-gray-300" />
                    </div>
                  </div>
                </>
              ) : null}

              <Pagination feedbackHook={feedbackHook} />
              {feedbackHook.loadingStatuses.fetchingEntries && entries.length === 0 ? (
                <div className="flex flex-col items-center justify-center">
                  <LoadingSpinner />
                </div>
              ) : (
                <div className="flex flex-col gap-y-3">
                  <div className="grid grid-cols-10 font-bold text-2xl w-full gap-x-6">
                    <div className="col-span-5 flex flex-row justify-between">
                      <div>
                        <h1>Feedback</h1>
                        <h1 className="text-sm text-blueberry font-normal">
                          {feedbackHook.totalEntriesCount === 0 ? (
                            'No feedback found on this view'
                          ) : feedbackHook.entriesCount === 0 ? (
                            `No entries match the selected filters`
                          ) : (
                            <span>
                              Showing <span id="entries-matching">{feedbackHook.entriesCount.toLocaleString('en-US')}</span> entries matching filters
                              {feedbackHook.entriesCount !== 0 ? (
                                <span id="total-entries"> ({feedbackHook.totalEntriesCount.toLocaleString('en-US')} total)</span>
                              ) : null}
                            </span>
                          )}
                        </h1>
                      </div>
                    </div>
                    <div className="col-span-5 flex flex-row justify-between">
                      <div className="flex flex-col">
                        <div className="flex flex-row gap-x-1 items-center">
                          <h1>Replies</h1>
                          <TooltipIcon tooltipContent="Mobile app replies are limited to 350 characters." />
                        </div>
                      </div>
                      <Button
                        onClick={() => setReplyToAllModalOpen(true)}
                        variant={ButtonVariant.Primary}
                        size={ButtonSize.Small}
                        loadingConfirm={replyLoading}
                        text={'Send All Replies'}
                        expandWidth
                      />
                    </div>
                  </div>
                  <ul role="list" className="flex flex-col gap-y-3">
                    {entries.length === 0 ? <p className="ml-auto mr-auto">No entries match the selected filters</p> : null}
                    {replies &&
                      entries?.map((entry) => (
                        <li key={entry.id}>
                          <FeedbackAndReplyRow
                            key={entry.id}
                            entry={entry}
                            generalReply={replies[entry.id]}
                            selectedItem={selectedItem}
                            handleGenerateReply={handleGenerateReply}
                            handleIndividualReply={sendIndividualReply}
                          />
                        </li>
                      ))}
                  </ul>
                </div>
              )}
              <Pagination feedbackHook={feedbackHook} />
            </div>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};
