import Tippy from '@tippyjs/react';
import { LinkIcon } from '@heroicons/react/24/outline';
import { SyntheticEvent } from 'react';

export function CopyGroupLink(props: { onClick: (e: SyntheticEvent) => void }) {
    return <div className="rounded-full p-2  hover:cursor-pointer hover:bg-slate-900/25 ">
        <Tippy theme="light" delay={200} content={<p className="text-center">Copy Group Link</p>}>
            <div id="link-group">
                <LinkIcon
                    className="h-5 w-5 focus:outline-none "
                    onClick={props.onClick}
                />
            </div>
        </Tippy>
    </div>;
}