import { toast } from 'react-hot-toast';
import { useState } from 'react';
import {
  ExternalTicketData,
  AsanaWorkspace,
  AsanaProject,
  useGetAsanaWorkspacesLazyQuery,
  useGetAsanaProjectsLazyQuery,
  useGetAsanaTicketsLazyQuery,
} from '../../../../generated/graphql';
import { useValidTeamAppContext } from '../../../../v2/contexts/AppContext';
import { BrowsingSection } from './BrowsingSection';
import { TicketLinker } from './TicketLinker';
import { toastTicketBrowsingError, toastTicketBrowsingPersistent, toastTicketBrowsingSuccess } from './helpers';

export const AsanaBrowsing = ({
  linkTicket,
  linkingTicket,
}: {
  linkTicket: ({ ticketData }: { ticketData: ExternalTicketData }) => Promise<void>;
  linkingTicket?: boolean;
}) => {
  const { curOrgId: orgId } = useValidTeamAppContext();

  const [selectedWorkspace, setSelectedWorkspace] = useState<AsanaWorkspace | null>(null);
  const [selectedProject, setSelectedProject] = useState<AsanaProject | null>(null);
  const [selectedTicketData, setSelectedTicketData] = useState<ExternalTicketData | null>(null);

  const [getAsanaWorkspaces, asanaWorkspaces] = useGetAsanaWorkspacesLazyQuery({});
  const [getAsanaProjects, asanaProjects] = useGetAsanaProjectsLazyQuery({});
  const [getAsanaTickets, asanaTickets] = useGetAsanaTicketsLazyQuery({});

  const allWorkspaces = asanaWorkspaces.data?.getAsanaWorkspaces ?? [];
  const allProjects = asanaProjects.data?.getAsanaProjects ?? [];
  const allTickets = asanaTickets.data?.getAsanaTickets ?? [];

  const searchWorkspace = async (queryString: string | undefined) => {
    if (!queryString) {
      setSelectedWorkspace(null);
      return;
    }
    const id = toastTicketBrowsingPersistent();
    await getAsanaWorkspaces({
      variables: { queryString, orgId },
      onCompleted: () => toastTicketBrowsingSuccess(),
      onError: () => toastTicketBrowsingError(),
    });
    toast.dismiss(id);
  };

  const searchProject = async (queryString: string | undefined) => {
    if (!queryString || !selectedWorkspace) {
      setSelectedProject(null);
      return;
    }
    const id = toastTicketBrowsingPersistent();
    await getAsanaProjects({
      variables: { queryString, workspaceId: selectedWorkspace?.providerUniqueId, orgId },
      onCompleted: () => toastTicketBrowsingSuccess(),
      onError: () => toastTicketBrowsingError(),
    });
    toast.dismiss(id);
  };

  const searchTicket = async (queryString: string | undefined) => {
    if (!queryString || !selectedWorkspace || !selectedProject) {
      setSelectedTicketData(null);
      return;
    }
    const id = toastTicketBrowsingPersistent();
    await getAsanaTickets({
      variables: { queryString, orgId, workspaceId: selectedWorkspace.providerUniqueId, projectId: selectedProject.providerUniqueId },
      onCompleted: () => toastTicketBrowsingSuccess(),
      onError: () => toastTicketBrowsingError(),
    });
    toast.dismiss(id);
  };

  return (
    <div className="flex flex-col gap-y-2 px-2">
      <BrowsingSection
        title="Workspace"
        searchPlaceholder="Search Asana workspaces"
        comboBoxPlaceholder="workspace"
        onSearch={searchWorkspace}
        data={allWorkspaces}
        selectedItem={selectedWorkspace ?? undefined}
        setSelectedItem={setSelectedWorkspace}
        loading={asanaWorkspaces.loading}
      />
      <BrowsingSection
        title="Project"
        searchPlaceholder="Search Asana projects"
        comboBoxPlaceholder="project"
        onSearch={searchProject}
        data={allProjects}
        selectedItem={selectedProject}
        setSelectedItem={setSelectedProject}
        loading={asanaProjects.loading}
        disabled={!selectedWorkspace}
      />
      <BrowsingSection
        title="Ticket"
        titleTooltipContent="Asana ticket search only matches full words. The query matches against both ticket titles and descriptions."
        searchPlaceholder="Search Asana tickets"
        comboBoxPlaceholder="ticket"
        onSearch={searchTicket}
        data={allTickets}
        selectedItem={selectedTicketData}
        setSelectedItem={setSelectedTicketData}
        loading={asanaTickets.loading}
        disabled={!selectedProject}
      />
      <TicketLinker
        selectedTicketData={selectedTicketData}
        linkTicket={linkTicket}
        integrationLogo={'https://unwrap-public-assets.s3.us-east-2.amazonaws.com/asana_logo.png'}
        integrationTitle="Asana"
        loading={linkingTicket}
      />
    </div>
  );
};
