import { useEffect, useState } from 'react';
import Button, { ButtonSize, ButtonVariant } from '../../baseComponents/Button';
import React from 'react';
import { FeedbackEntriesQuery } from '../../../generated/graphql';
import { ArrayElement } from '../../../utilities';
import { IDropDownItem } from '../../baseComponents/DropDown';
import { TextArea } from './ReplyTextArea';
import FeedbackEntryCard from '../FeedbackEntryCard';
import { SparklesIcon } from '@heroicons/react/24/solid';
import { Reply } from '../../hooks/ReplyHook';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { selector } from '../../pages/ReplyingPage';
import Tippy from '@tippyjs/react';
import 'tippy.js/themes/light.css';
import TemplateDropDown from './TemplateDropdown';
import { useValidTeamAppContext } from '../../../v2/contexts/AppContext';

export const FeedbackAndReplyRow = ({
  entry,
  generalReply,
  handleGenerateReply,
  handleIndividualReply,
}: {
  entry: ArrayElement<FeedbackEntriesQuery['entries']>;
  generalReply: Reply;
  selectedItem?: IDropDownItem;
  handleGenerateReply: (text: string, entry: ArrayElement<FeedbackEntriesQuery['entries']>) => Promise<void>;
  handleIndividualReply: (entryId: number, text: string, cb?: () => void) => Promise<void>;
}) => {
  const { currentUserIsAdmin } = useValidTeamAppContext();
  const [generateLoading, setGenerateLoading] = useState(false);
  const [replyLoading, setReplyLoading] = useState(false);
  const [reply, setReply] = useState<Reply>({
    text: generalReply?.text ?? '',
    error: generalReply?.error ?? '',
    success: generalReply?.success ?? false,
    submitter: generalReply?.submitter ?? '',
  });
  useEffect(() => {
    setReply(generalReply);
  }, [generalReply]);
  function handleTextChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
    const newValue = event.target.value;
    setReply((prev) => ({ ...prev, text: newValue }));
  }

  return (
    <div className="grid grid-cols-10 gap-x-6 items-stretch">
      <div className="col-span-5 w-full ">
        <FeedbackEntryCard entry={entry} showConversation={false} />
      </div>
      {reply?.success || reply?.sent || !currentUserIsAdmin ? (
        <div className="flex flex-col gap-y-1 col-span-5">
          {reply?.error && reply?.sent ? (
            <div className="flex flex-row justify-center items-center gap-x-1">
              <ExclamationTriangleIcon className="h-4 w-4 text-red-500 stroke-2" />
              <p className="text-red-500 mt-[2px]">{reply.error}</p>
            </div>
          ) : null}
          {reply?.success || (!reply?.success && reply?.sent) ? (
            <div className="flex flex-col gap-y-3 rounded-3xl bg-silver h-full w-full px-6 py-5">
              <p>{reply.submitter}</p>
              <p>{reply.text}</p>
            </div>
          ) : !currentUserIsAdmin ? (
            <div className="flex flex-col gap-y-3 rounded-3xl bg-silver h-full w-full px-6 py-5" data-testid="not-admin">
              <p>This feedback entry has no replies. You must be an administrator to leave feedback replies.</p>
            </div>
          ) : null}
        </div>
      ) : (
        <div className="col-span-5 w-full">
          <TextArea
            value={reply?.text}
            error={!reply?.sent ? reply?.error : undefined}
            handleTextChange={handleTextChange}
            text={reply?.text}
            maxLength={350}
            placeholder="Type your reply...or use our AI-powered reply crafting feature above."
            buttonRow={
              <ReplyButtonRow
                loadingGenerateReplies={generateLoading}
                generateReply={async () => {
                  setGenerateLoading(true);
                  await handleGenerateReply(reply?.text, entry);
                  setGenerateLoading(false);
                }}
                handleSetSelected={(item) => item && setReply((prev) => ({ ...prev, text: item?.value as string }))}
                enableSendReplyButton={reply?.text.length > 0}
                submitReply={(cb: () => void) => {
                  setReplyLoading(true);
                  handleIndividualReply(entry.id, reply.text, () => setReplyLoading(false));
                }}
              />
            }
          />
        </div>
      )}
    </div>
  );
};

const ReplyButtonRow = ({
  loadingGenerateReplies,
  submitReply,
  generateReply,
  handleSetSelected,
  enableSendReplyButton,
}: {
  loadingGenerateReplies: boolean;
  submitReply: (cb: () => void) => void;
  generateReply: () => void;
  handleSetSelected: (item: IDropDownItem | undefined) => void;
  enableSendReplyButton: boolean;
}) => {
  const [selectedItem, setSelectedItem] = useState<IDropDownItem | undefined>(undefined);
  const [replyLoading, setReplyLoading] = useState(false);
  return (
    <div className="flex flex-row justify-between w-full ">
      <TemplateDropDown
        placeholderText="Choose a template..."
        dropDownData={selector}
        setSelectedItem={(item) => {
          setSelectedItem(item);
          handleSetSelected(item);
        }}
        selectedItem={selectedItem}
      />
      <div className="flex flex-row items-center justify-end gap-x-3 w-full">
        <Tippy content="Generate a reply based on your current message" theme="light">
          <div>
            <Button
              loadingConfirm={loadingGenerateReplies}
              loadingText="Generating smart replies..."
              variant={ButtonVariant.Secondary}
              size={ButtonSize.Small}
              onClick={() => generateReply()}
              text="Create Smart Reply"
              expandWidth
              icon={<SparklesIcon className="w-4 h-4" />}
            />
          </div>
        </Tippy>

        <Button
          loadingText="Send Reply"
          variant={ButtonVariant.Secondary}
          size={ButtonSize.Small}
          loadingConfirm={replyLoading}
          onClick={() => {
            setReplyLoading(true);
            submitReply(() => setReplyLoading(false));
          }}
          text="Send Reply"
          expandWidth
          disabled={!enableSendReplyButton}
        />
      </div>
    </div>
  );
};
