import { useEffect, useState } from 'react';
import { ArrayElement } from '../../utilities';
import { Breakdown, Chart, ChartFragment, Chart2DFragment, Chart_Type, GetCustomChartsQuery, SeriesData, Y_Axis_Data } from '../../generated/graphql';
import ChartView from '../baseComponents/ChartView';
import { ChartType } from '../../v2/hooks/ChartHook';
import { ChartOptions, ChartTypeRegistry, CoreScaleOptions, Scale, Tick, TooltipItem } from 'chart.js';
import { truncateAndEllipsis } from '../../v2/util';

const isHorizontalChart = (chartType: Chart_Type) => {
  return chartType === Chart_Type.HorizontalBar;
};
const isPercentageChart = (yAxisData: Y_Axis_Data) => yAxisData === Y_Axis_Data.Favorability || yAxisData === Y_Axis_Data.RelativeShare;
const colors = [
  '#CD5A76',
  '#F7B731',
  '#6bb776',
  '#B66D0D',
  '#2C3E50',
  '#9B59B6',
  '#F4D03F',
  '#EC7063',
  '#5499C7',
  '#E74C3C',
  '#F5B7B1',
  '#34495E',
  '#7D3C98',
  '#F8C471',
  '#F0B27A',
  '#1ABC9C',
  '#E67E22',
  '#5D6D7E',
  '#AF7AC5',
];

const sentimentColorMap: Record<string, string> = {
  Negative: '#CD5A76',
  Neutral: '#F7B731',
  Positive: '#6bb776',
};

const npsBucketColorMap: Record<string, string> = {
  detractor: '#CD5A76',
  passive: '#F7B731',
  promoter: '#6bb776',
};

const isCategoricalChart = (chartType: Chart_Type) => chartType === Chart_Type.Pie || chartType === Chart_Type.HorizontalBar;
const isStackedChart = (chartType: Chart_Type) => chartType === Chart_Type.StackedBar || chartType === Chart_Type.StackedArea;

const generateDataset = (
  data: SeriesData,
  type: 'normalized' | 'aggregate',
  overallChartData: ArrayElement<GetCustomChartsQuery['getCharts']>,
  index: number,
  fill?: boolean,
  differentColorPerBucket?: boolean
) => {
  const dataToMap = type === 'normalized' ? data.normalizedData : data.aggregateData;
  return dataToMap?.slice(0, 10).map((curData, idx) => {
    const labelName: string | undefined = data.breakdownLabels?.[idx]?.name;
    const npsNames = ['detractor', 'passive', 'promoter'];
    const isNpsBucket =
      data.breakdownLabels?.length === 3 &&
      data.breakdownLabels
        ?.map((obj) => obj.name)
        .sort()
        .every((name, idx) => name.toLowerCase() === npsNames[idx]);
    let color: string = colors[idx + index];
    if (labelName) {
      if (data.breakdown === 'sentiment') color = sentimentColorMap[labelName];
      else if (data.breakdown === 'segment' && isNpsBucket) color = npsBucketColorMap[labelName.toLowerCase()];
    }
    let colorBucket;
    if (differentColorPerBucket) {
      if (data.breakdown === Breakdown.Sentiment) {
        colorBucket = data.chartLabels.map((label) => sentimentColorMap[label]) || [];
      } else if (data.breakdown === Breakdown.Segment && isNpsBucket) {
        colorBucket = data.chartLabels.map((label) => npsBucketColorMap[label.toLowerCase()]) || [];
      } else if (differentColorPerBucket) {
        colorBucket = colors;
      } else {
        colorBucket = [color];
      }
    }

    return {
      label: labelName ?? '',
      data: curData,
      backgroundColor: differentColorPerBucket ? colorBucket : color,
      borderColor: differentColorPerBucket ? colorBucket : color,
      fill,
    };
  });
};

const getChartTypeRegistry = (type: Chart_Type) => {
  if (type === Chart_Type.StackedBar) return 'bar' as keyof ChartTypeRegistry;
  if (type === Chart_Type.VerticalBar) return 'bar' as keyof ChartTypeRegistry;
  if (type === Chart_Type.Line) return 'line' as keyof ChartTypeRegistry;
  if (type === Chart_Type.HorizontalBar) return 'bar' as keyof ChartTypeRegistry;
  if (type === Chart_Type.Pie) return 'doughnut' as keyof ChartTypeRegistry;
  return 'line';
};

export const CustomChart = ({ customChartData, showLegend = true }: { customChartData: ChartFragment; showLegend?: boolean }) => {
  const [chartData, setChartData] = useState<any>();
  const [randKey, setRandKey] = useState<number>(Math.random());
  useEffect(() => {
    setRandKey(Math.random());
  }, [customChartData.type]);

  useEffect(() => {
    if (customChartData.seriesData && customChartData.seriesData.length > 0) {
      let labels: string[] = customChartData.seriesData[0].chartLabels; //Labels should be the same for all. Maybe we can add a check?
      let datasets: any[] = [];
      customChartData.seriesData?.map((data, i) => {
        //Absolute Chart -> Aggregate Data ||| Percentage Chart -> Normalized Data
        if (isPercentageChart(customChartData.y_axis_data!))
          datasets.push(
            ...generateDataset(
              data as SeriesData,
              'normalized',
              customChartData as Chart,
              i,
              customChartData.type === Chart_Type.StackedArea,
              customChartData.type === Chart_Type.Pie
            )
          );
        else
          datasets.push(
            ...generateDataset(
              data as SeriesData,
              'aggregate',
              customChartData as Chart,
              i,
              customChartData.type === Chart_Type.StackedArea,
              customChartData.type === Chart_Type.Pie
            )
          );
      });
      const newChartData = { labels, datasets };
      setChartData(newChartData);
    }
  }, [customChartData]);

  return (
    <div className="h-full">
      {chartData ? (
        <ChartView
          key={randKey}
          type={getChartTypeRegistry(customChartData.type)}
          data={chartData}
          options={getChartOptions(
            customChartData.y_axis_data === Y_Axis_Data.RelativeShare
              ? ChartType.CustomPercentage
              : customChartData.y_axis_data === Y_Axis_Data.Favorability
              ? ChartType.Favorability
              : ChartType.CustomAbsolute,
            customChartData.type,
            customChartData.seriesData?.[0]?.tooltipLabels ?? [],
            customChartData.seriesData?.[0]?.chartLabels ?? [],
            false,
            showLegend
          )}
        />
      ) : null}
    </div>
  );
};

export const getChartOptions = (
  chartType: ChartType,
  chart_type: Chart_Type,
  fullLabels: string[],
  chartLabels: string[],
  disableAnnotations?: boolean,
  showLegend: boolean = true
): ChartOptions => {
  //Uhh..we got a naming issue, ChartType vs Chart_Type. One represents if it's absolute, relative, etc. The other represents the kind of chart.
  //We need to think about better names.

  return {
    animations: {
      numbers: { duration: 500 },
    },
    layout: {
      padding: {
        top: 0,
        bottom: 0,
      },
    },
    indexAxis: chart_type === Chart_Type.HorizontalBar ? 'y' : 'x',

    //@ts-ignore
    lineTension: 0.3,
    interaction: {
      mode: 'nearest',
      axis: 'xy',

      intersect: false,
    },
    hover: {
      mode: 'index',
      intersect: false,
    },
    plugins: {
      annotation: {
        interaction: {
          intersect: false,
        },
      },
      legend: {
        onClick: (e, legendItem, legend) => {
          if (isCategoricalChart(chart_type)) return;
          const chart = legend.chart;
          const datasetIndex = legendItem.datasetIndex!;
          chart.getDatasetMeta(datasetIndex).hidden = !chart.getDatasetMeta(datasetIndex).hidden;
          chart.update();
        },
        display: showLegend,
        labels: {
          color: '#292E5B',
          font: {
            size: 13,
            family: 'SofiaPro',
          },
          boxWidth: 10,
          boxHeight: 10,
          generateLabels: function (chart: any) {
            if (chart_type === Chart_Type.Pie) {
              return chart.data.labels.map((label: string, index: number) => {
                return {
                  text: label,
                  fillStyle: chart.data.datasets[0].backgroundColor[index],
                  strokeStyle: chart.data.datasets[0].backgroundColor[index],
                  lineWidth: 2,
                  datasetIndex: index,
                  hidden: chart.getDatasetMeta(0).data[index].hidden,
                };
              });
            } else {
              return chart.data.datasets.map(function (dataset: any, index: number) {
                const meta = chart.getDatasetMeta(index);
                let label = dataset.label;
                if (label.length > 25) {
                  label = label.substring(0, 25) + '...';
                }
                return {
                  text: label,
                  fillStyle: dataset.backgroundColor,
                  strokeStyle: dataset.backgroundColor,
                  lineWidth: 2,
                  datasetIndex: index,
                  hidden: !meta.visible,
                };
              });
            }
          },
        },
      },
      tooltip: {
        // control tooltip visibility if all values are zero
        filter: function (tooltipItem) {
          const dataset = tooltipItem.chart.data.datasets[tooltipItem.datasetIndex];
          const data = dataset.data[tooltipItem.dataIndex];
          if (!data) {
            return false;
          }
          // If all values are zero, return false to hide tooltip.
          for (let i = 0; i < Number(data.valueOf()); i++) {
            if (data !== 0) {
              return true;
            }
          }
          return false;
        },
        backgroundColor: '#FFF',
        bodyColor: '#292E5B',
        titleColor: '#292E5B',
        footerColor: '#292E5B',
        mode: 'index',
        position: 'custom',
        intersect: false,
        enabled: true,
        titleFont: {
          family: 'SofiaPro',
          size: 10,
        },
        titleMarginBottom: 1,
        bodyFont: {
          family: 'SofiaPro',
          size: 12,
        },
        padding: 10,
        footerFont: {
          size: 10,
          family: 'SofiaPro',
          style: 'italic',
          weight: 'normal',
        },
        borderWidth: 1,
        borderColor: '#292E5B',
        footerMarginTop: 1,
        // sort visible tooltip items in descending order
        itemSort(a, b, data) {
          return Number(b.formattedValue) - Number(a.formattedValue);
        },
        callbacks:
          chartType === ChartType.CustomAbsolute || chartType === ChartType.CustomPercentage || ChartType.Favorability
            ? {
                title: (tooltipItem: TooltipItem<'bar' | 'line'>[]) => {
                  if (tooltipItem.length > 0) {
                    return fullLabels[tooltipItem[0].dataIndex];
                  }
                  return '';
                },
                label: function (data: TooltipItem<'bar' | 'line'>) {
                  // do not show tooltip row if value is zero
                  if (Number(data.formattedValue) === 0) {
                    return '';
                  }
                  if (chartType === ChartType.CustomPercentage) {
                    let floor = Math.round(parseFloat(data.formattedValue.replace(/,/, '.')) * 10) / 10;
                    if (floor === 0) {
                      floor = Number(data.formattedValue);
                    }
                    return floor + '% of feedback' + ' - ' + data.dataset.label;
                  }
                  if (chartType === ChartType.Favorability) {
                    let floor = Math.round(parseFloat(data.formattedValue.replace(/,/, '.')) * 10) / 10;
                    if (floor === 0) {
                      floor = Number(data.formattedValue);
                    }
                    return floor + '% positive sentiment rate' + ' - ' + data.dataset.label;
                  }

                  if (chartType === ChartType.CustomAbsolute) {
                    let finalVal = data.formattedValue + ' mentions';
                    if (data.dataset.label !== 'All') finalVal += ' - ' + truncateAndEllipsis(data.dataset.label, 60);
                    return finalVal;
                  }
                  return data.formattedValue;
                },
              }
            : {},
      },
    },
    elements: {
      point: {
        radius: 3,
      },
      bar: {
        borderRadius: 4,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: chart_type === Chart_Type.StackedBar,
        display: chart_type === Chart_Type.Pie ? false : true,
        grid: {
          display: false,
        },
        ticks: {
          display: true,
          color: '#292E5B',
          font: {
            size: 14,
            family: 'SofiaPro',
          },
          callback: function (value: string | number, index: number, values: Tick[]) {
            // we do this because the 'x' axis for horizontal bar charts is actually the 'y' axis in the data
            // so we need to display the value from the data array instead of the labels array
            // for other chart types we just pass the chart labels
            if (isHorizontalChart(chart_type)) {
              if (chartType === ChartType.CustomPercentage || chartType === ChartType.Favorability) {
                return `${value}%`;
              } else {
                return value.toLocaleString();
              }
            } else {
              return chartLabels[index];
            }
          },
        },
      },
      ...(chart_type === Chart_Type.HorizontalBar && {
        y: {
          display: true,
          grid: {
            display: false,
          },
          ticks: {
            display: true,
            color: '#292E5B',
            font: {
              size: 12,
              family: 'SofiaPro',
            },
          },
        },
      }),
      ...(chartType === ChartType.CustomAbsolute &&
        !isCategoricalChart(chart_type) && {
          mentions: {
            stacked: isStackedChart(chart_type),
            position: 'left',
            grid: {
              display: true,
              color: '#f1f1f1',
              drawBorder: false,
            },
            ticks: {
              padding: 15,
              precision: 0,
              autoSkip: false,
              color: '#292E5B',
              font: {
                size: 14,
                family: 'SofiaPro',
              },
            },
            beginAtZero: true,
          },
        }),
      ...((chartType === ChartType.CustomPercentage || chartType === ChartType.Favorability) &&
        !isCategoricalChart(chart_type) && {
          percentage: {
            stacked: isStackedChart(chart_type),
            position: 'left',
            grid: {
              display: true,
              color: '#f1f1f1',
              drawBorder: false,
            },
            display: true,
            ticks: {
              padding: 15,
              autoSkip: false,
              color: '#292E5B',
              font: {
                size: 14,
                family: 'SofiaPro',
              },
              callback: function (value: string | number, index: number, values: Tick[]) {
                return value + '%';
              },
            },
            beginAtZero: true,
            afterDataLimits: (scale: Scale<CoreScaleOptions>) => {
              const datasets = scale.chart.data.datasets;

              //If there's a breakdown, get the max but with each point's values added together
              if (datasets && datasets.length >= 1 && isStackedChart(chart_type)) {
                const dataValues = datasets[0].data.map((_: any, index: number) =>
                  datasets.reduce((acc: number, cur: any) => {
                    return acc + cur.data[index];
                  }, 0)
                );
                const maxDataPoint = +Math.max(...dataValues).toFixed(1);

                scale.max = Math.max(maxDataPoint, 100);
                scale.min = 0;
              } else {
                //If no breakdown, get the max of all datasets
                const dataValues = datasets.flatMap((dataset) => dataset.data) as number[];
                const maxDataPoint = Math.max(...dataValues);
                const minDataPoint = Math.min(...dataValues);

                let padding;
                if (maxDataPoint < 10 && maxDataPoint - minDataPoint < 3) {
                  padding = 0.1;
                } else {
                  padding = 5;
                }

                scale.max = Math.min(maxDataPoint + padding, 100);
                scale.min = 0;
              }
            },
          },
        }),
    },
  };
};

export const getCustomChartData = (customChartData: Chart2DFragment) => {
  if (customChartData.series && customChartData.series.length > 0) {
    let labels: string[] = customChartData.xAxisLabels;
    let datasets: any[] = [];
    customChartData.series?.map((data, i) => {
      datasets.push(
        ...generateChartDataset(customChartData, i, customChartData.config.type === Chart_Type.StackedArea, customChartData.config.type === Chart_Type.Pie)
      );
    });
    return { labels, datasets };
  }
};

export const generateChartDataset = (data: Chart2DFragment, index: number, fill?: boolean, differentColorPerBucket?: boolean) => {
  return data.series?.slice(0, 10).map((curData, idx) => {
    const labelName: string | undefined = data.xAxisLabels?.[idx];
    const npsNames = ['detractor', 'passive', 'promoter'];
    const isNpsBucket = data.xAxisLabels?.length === 3 && data.xAxisLabels.sort().every((name, idx) => name.toLowerCase() === npsNames[idx]);
    let color: string = colors[idx + index];
    if (labelName) {
      if (data.config.breakdown === 'sentiment') color = sentimentColorMap[labelName];
      else if (data.config.breakdown === 'segment' && isNpsBucket) color = npsBucketColorMap[labelName.toLowerCase()];
    }
    let colorBucket;
    if (differentColorPerBucket) {
      if (data.config.breakdown === Breakdown.Sentiment) {
        colorBucket = data.xAxisLabels.map((label) => sentimentColorMap[label]) || [];
      } else if (data.config.breakdown === Breakdown.Segment && isNpsBucket) {
        colorBucket = data.xAxisLabels.map((label) => npsBucketColorMap[label.toLowerCase()]) || [];
      } else if (differentColorPerBucket) {
        colorBucket = colors;
      } else {
        colorBucket = [color];
      }
    }

    return {
      label: labelName ?? '',
      data: curData,
      backgroundColor: differentColorPerBucket ? colorBucket : color,
      borderColor: differentColorPerBucket ? colorBucket : color,
      fill,
    };
  });
};
