import Tippy from '@tippyjs/react';
import { TrashIcon } from '@heroicons/react/24/outline';

export function DeleteGroupButton(props: { onClick: () => void }) {
  return <Tippy content="Delete Group" delay={200}>
    <div
      className="rounded-full  bg-silver  text-blueberry flex flex-row justify-center items-center cursor-pointer h-8 w-8 hover:scale-110 duration-150 hover:shadow-sm"
      onClick={props.onClick}
    >
      <div>
        <TrashIcon className="h-4 w-4 stroke-2" />
      </div>
    </div>
  </Tippy>;
}